import { Component, OnInit } from '@angular/core';
import * as myGlobal from '../globals'; 
import { ExcelService } from '../excel.service';
import {UserProfileService} from '../user-profile.service';
import {TableUtil} from '../tableUtil';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {checkTimeOut} from '../utils';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})



export class ReportComponent implements OnInit {
  DAYS_OF_WEEKS = ['CN','T2','T3','T4','T5','T6','T7'];
  

  getOrgId() {
    let data = JSON.parse(localStorage.getItem('currentUser'));
    return data.org_id;
  }

  constructor(
    private userProfileService: UserProfileService,
    private excelService: ExcelService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,  
    private router: Router
    ) { }
  
  userForm: FormGroup;
  submitted = false;

  reportData: any;
  dataArray :any;
  daysOfWeeks : String[] = [];
  
  reportingCycleList :[];

  reportingCycle = '202002';
  year = "2020";
  month = "02";
  rootOrganData = {'org_name':'','address':''};

  dateOfMonth = new DateOfMonth;

  ngOnInit() {

    //=============================
    var dateObj = new Date();
    this.month = "" + (dateObj.getUTCMonth() + 1); //months from 1-12
    if (this.month.length==1) {
      this.month = "0" +  this.month;
    } 
    this.year = ""+dateObj.getUTCFullYear();

    this.reportingCycle = this.year + this.month;

    this.dateOfMonth.numDateOfMonth = daysInMonth(this.reportingCycle);
    this.dateOfMonth.firstDayOfWeek = getFirstDayOfWeek(this.reportingCycle);
    
    for (let day = 0; day < 31; day++) {
        var s = this.DAYS_OF_WEEKS[(day + this.dateOfMonth.firstDayOfWeek) %7];
        if (day>=this.dateOfMonth.numDateOfMonth) {
            s='';
        }
        this.daysOfWeeks.push(s);
    }
    //==============================

    this.userForm = this.formBuilder.group({
      //org_id: [this.orgId, Validators.required],
      //org_type: [this.orgTypeName, Validators.required],
      reportingCycleListCmb: [this.reportingCycle, Validators.required],      
    });
    //==============================

    this.getOrgData(this.getOrgId(),0);
    this.getReportingCycleList();
    this.getReportByCycle();
    //this.rootOrganData = this.userProfileService.getRootOrganData();
    ///console.log("DEBUG:" + JSON.stringify(this.rootOrganData));
    
  }

  getReportByCycle() {
    this.dataArray = [];
    this.reportData = [];
    //==================================
    

    this.dateOfMonth.numDateOfMonth = daysInMonth(this.reportingCycle);
    this.dateOfMonth.firstDayOfWeek = getFirstDayOfWeek(this.reportingCycle);
    this.year = this.reportingCycle.substr(0,4);
    this.month = this.reportingCycle.substr(4,2);


    // console.log("DEBUG: numDateOfMonth:" + this.dateOfMonth.numDateOfMonth);
    // console.log("DEBUG: firstDayOfWeek:" + this.dateOfMonth.firstDayOfWeek);
    
    this.daysOfWeeks = [];
    for (let day = 0; day < 31; day++) {
        var s = this.DAYS_OF_WEEKS[(day + this.dateOfMonth.firstDayOfWeek) %7];
        if (day>=this.dateOfMonth.numDateOfMonth) {
            s='';
        }
        this.daysOfWeeks.push(s);
    }

    //==================================
    this.userProfileService.getReportByCycle(this.reportingCycle).subscribe((data: []) => {

      if (!checkTimeOut(data,this.router)) {return;}
      //console.log('Report Data:' + JSON.stringify(data));
      //this.lastaccess = data;
      this.dataArray = data;

      data.forEach(e => {
        var obj= new person();
        
        //obj['position'] = e['row_number'];
        
        obj['org_id'] = e['org_id'];
        obj['usr_id'] = e['usr_id'];
        obj['ext_id'] = e['ext_id'];
        obj['first_name'] = e['first_name'];
        obj['last_name'] = e['last_name'];
        obj['telephone'] = e['telephone'];
        obj['email'] = e['email'];

        // Chuan bi so lieu
        var content_data = JSON.parse( e['content_data']);
        if (!(content_data["checkin_data"]==null || content_data["checkin_data"]=='')) {
          for (let i = 1; i <= 31; i++) {
            obj['I' + i] = content_data.checkin_data[i-1].I; 
            obj['O'+i] = content_data.checkin_data[i-1].O;
            if (!(obj['I' + i]==null || obj['I' + i]=='')) {
              if (obj['I' + i].length==8) {
                obj['I' + i] = obj['I' + i].substring(0, 5);
              }
            }
            if (!(obj['O' + i]==null || obj['O' + i]=='')) {
              if (obj['O' + i].length==8) {
                obj['O' + i] = obj['O' + i].substring(0, 5);
              }
            }
          }

          if ((content_data["sum_data"]==null || content_data["sum_data"]=='')) {
            obj["sum_data"] = {
              // Luu y : Can map voi checkin_server.js
              "nc_nt":"0",  // Ngay cong - ngay thuong
              "nc_nl":"0",  // Ngay cong - ngay le
              "gc_nt":"0",  // Gio cong - ngay thuong
              "gc_nl":"0",  // Gio cong - ngay le
              "vang_kp":"0", // Vang khong phep
              "nn_cp":"0",  // Ngay nghi co phep
              "nn_ts":"0",  // Ngay nghi thai san
              "tong":"0",   // Tong cong
              "vaotre_lan":"0",
              "vaotre_phut":"0",
              "rasom_lan":"0",
              "rasom_phut":"0",
              "phat_tre":"0",
              "vaosom_lan":"0",
              "vaosom_phut":"0",
              "ratre_lan":"0",
              "ratre_phut":"0",
              "nangsuat":"0",
            }
          } else {
            obj["sum_data"] = content_data["sum_data"];
          }
        } else { // Khong co so lieu

        }
        //obj['created_on'] = e['created_on'];
        this.reportData.push(obj);
       
      });
    });
  }

  getOrgData(orgId,rootOrgId): void {
    this.userProfileService.getOrgData(orgId,rootOrgId)
        .subscribe(data => {
          if (!checkTimeOut(data,this.router)) {return;}

          this.rootOrganData = data[0];
          console.log("Call getOrgData!");
          console.log(data);

          
        });
  }

  

  getReportingCycleList(): void {
    this.userProfileService.getReportingCycle(this.getOrgId())
    .subscribe(data => {
      if (!checkTimeOut(data,this.router)) {return;}
      
      this.reportingCycleList = data;
      //console.log("DEBUG:"  + JSON.stringify(this.orgTypeList ));
    });
  }

 
  generateExcel() {
    
    this.excelService.generateExcel_monthly_report(this.getOrgId(),0,this.reportingCycle,
            this.rootOrganData,
            this.reportData);
  }

  exportTable(){
    TableUtil.exportToExcel("tblReport");
  }

  onSubmit() {
    if(this.userForm.invalid == true )
  	{
      //console.log("Invalid?!");
      //this.debug_data = 'Invalid data!'
  		return;
    }
    // this.usrId = this.userForm.controls.userListCmb.value;
    // this.getUserReportById(this.reportingCycle,this.usrId);
  }
  
  invalidReportingCycle() {
     return (this.submitted && this.userForm.controls.reportingCycleListCmb.errors != null);
  }

  changeReportingCycle(event) {
    this.reportingCycle = this.userForm.controls.reportingCycleListCmb.value;
    this.getReportByCycle();
  }

}

function daysInMonth (reportingCycle:String) {
  var year = parseInt(reportingCycle.substr(0,4));
  var month = parseInt(reportingCycle.substr(4,2));
  return new Date(year, month, 0).getDate(); 
} 

function getFirstDayOfWeek(reportingCycle:String) {
  var year = parseInt(reportingCycle.substr(0,4));
  var month = parseInt(reportingCycle.substr(4,2)) -1;
  // console.log("DEBUG: date=" + new Date(year, month, 1));
  // console.log("DEBUG: month=" + month);
  return new Date(year, month, 1).getDay(); 
}

export class person {
  position: number;
  org_id : string;
  ext_id : string;
  first_name: string;
  last_name: string;
  telephone: string;
  email: string;  
  created_on : string;
    
}

export class DateOfMonth {  
  public numDateOfMonth:number;
  public firstDayOfWeek:number;
}