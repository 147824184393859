import { Component, OnInit,ViewChild } from '@angular/core';
//import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {UserProfileService} from '../user-profile.service';
import { Router } from '@angular/router';
import {checkTimeOut} from '../utils';

@Component({
  selector: 'app-device-monitor',
  templateUrl: './device-monitor.component.html',
  styleUrls: ['./device-monitor.component.scss']
})

export class DeviceMonitorComponent implements OnInit {
  displayedColumns: string[] = ['connect_status','idx', 'dev_id', 'dev_type','dev_name',  'dev_loc','remark','last_updated'];
  //dataSource = new MatTableDataSource<PeriodicElement>(this.lastaccess);
  lstData: any;
  dataSource = new MatTableDataSource<PeriodicElement>(this.lstData);
 
  enable_delete_btn = false;
 
  //checked = false;
  indeterminate = false;
  labelPosition = 'after';

  

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private userProfileService: UserProfileService,private router: Router) {};

  getOrgId() {
    let data = JSON.parse(localStorage.getItem('currentUser'));
    return data.org_id;
  }
  
  ngOnInit() {
    this.getRegisterLog();
     
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
   }

  getRegisterLog() {
    this.lstData = [];
    this.userProfileService.getAllDevStatus().subscribe((data: []) => {
      //console.log('DAT:' + JSON.stringify(data));
      //this.lastaccess = data;
      if (!checkTimeOut(data,this.router)) {return;}
      
      data.forEach(e => {
        var obj= new PeriodicElement(e);

        this.lstData.push(obj);
       
      }); 

      this.dataSource.data = this.lstData; 
     // this.dataSource.paginator = this.paginator;  
      //setTimeout(() => this.dataSource.paginator = this.paginator); 
    });
  }


}

export class PeriodicElement {
  idx: number;
  dev_id : string;
  dev_type: string;
  dev_name: string;
  dev_loc: string;
  remark: string;  
  created_on : string;
  updated_time : string;
  status : string;
  org_id : string;
  last_updated : string;
  connect_status : string;

  constructor(data) {
      this.idx = data['idx'];
      this.dev_id = data['dev_id'];
      this.dev_type = data['dev_type'];
      this.dev_name = data['dev_name'];
      this.dev_loc = data['dev_loc'];
      this.remark = data['remark'];  
      this.created_on = data['created_on'];
      this.updated_time  = data['updated_time'];
      this.status  = data['status'];
      this.org_id  = data['org_id'];
      this.last_updated  = data['last_updated'];
      this.connect_status  = data['connect_status'];
  }
}


// "dev_id": "10000000e61d1100",
//     "dev_type": "facedev",
//     "dev_name": "Develop device",
//     "dev_loc": "develop",
//     "remark": "Develop device",
//     "created_on": "2020-03-31T11:41:59Z",
//     "updated_time": null,
//     "status": 1,
//     "org_id": "30",
//     "last_send": null,
//     "last_send_status": null,
//     "last_updated": "2020-04-08T16:24:27Z",
//     "idx": "1",
//     "connect_status": "OK"