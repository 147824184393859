import { Component, OnInit,ViewChild } from '@angular/core';
//import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {UserProfileService} from '../user-profile.service';
import { Router } from '@angular/router';
import {checkTimeOut} from '../utils';

@Component({
  selector: 'app-register-log',
  templateUrl: './register-log.component.html',
  styleUrls: ['./register-log.component.scss']
})

export class RegisterLogComponent implements OnInit {
  displayedColumns: string[] = ['position','status', 'ext_id', 'last_name','first_name',  'telephone','email','created_on','usr_id','edit','delete'];
  //dataSource = new MatTableDataSource<PeriodicElement>(this.lastaccess);
  lastaccess: any;
  dataSource = new MatTableDataSource<PeriodicElement>(this.lastaccess);
 
  enable_delete_btn = false;
 
  //checked = false;
  indeterminate = false;
  labelPosition = 'after';

  

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private userProfileService: UserProfileService,private router: Router) {};

  getOrgId() {
    let data = JSON.parse(localStorage.getItem('currentUser'));
    return data.org_id;
  }
  
  ngOnInit() {
    this.getRegisterLog();
     
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
   }

  getRegisterLog() {
    this.lastaccess = [];
    this.userProfileService.getRegisterLog().subscribe((data: []) => {
      //console.log('DAT:' + JSON.stringify(data));
      //this.lastaccess = data;
      if (!checkTimeOut(data,this.router)) {return;}
      
      data.forEach(e => {
        var obj= new PeriodicElement();

        obj['position'] = e['row_number'];    
        obj['usr_id'] = e['usr_id'];
        obj['ext_id'] = e['ext_id'];
        obj['first_name'] = e['first_name'];
        obj['last_name'] = e['last_name'];
        obj['telephone'] = e['telephone'];
        obj['email'] = e['email'];
        obj['created_on'] = e['created_on'];
        obj['has_vector'] = e['has_vector'];
        this.lastaccess.push(obj);
       
      }); 

      this.dataSource.data = this.lastaccess; 
     // this.dataSource.paginator = this.paginator;  
      //setTimeout(() => this.dataSource.paginator = this.paginator); 
    });
  }

  clickEditMethod(userId: string) {
    this.router.navigate(["user-profile/" , userId]);
  }
  
  clickDeleteMethod(userId: string) {
    if(confirm("Are you sure to delete user with usrid="+userId)) {
      console.log("Delete userID="+userId);
      this.userProfileService.deleteUserProfile(userId).subscribe((data: []) => {
        if (!checkTimeOut(data,this.router)) {return;}
        
        console.log('Delete result:' + JSON.stringify(data));

        if(confirm("Do you wanna DELETE ALL checklog and report data of user "+userId + " ?")) {
          this.userProfileService.deleteUserData(userId).subscribe((data: []) => {
            console.log('Delete other data:' + JSON.stringify(data));
          });
        }

        //location.reload();
        this.ngOnInit();
      });
      
    }
  }

}

export class PeriodicElement {
  position: number;
  ext_id : string;
  first_name: string;
  last_name: string;
  telephone: string;
  email: string;  
  created_on : string;
    
}


// -> https://material.angular.io/components/table/overview
//https://blog.angular-university.io/angular-material-data-table/

//https://appdividend.com/2018/11/07/angular-material-table-tutorial-example/