<div class="container-md">
    <h4 class="text-primary">User Register for Smart Check-in</h4>
    
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
        
    <div class="row">       
        <div class="col-md-6">
          <h4 class="bold"><span>Step 1:</span> Enter new user information</h4>
          
            <div *ngIf="userId==0">
                <p class="text-info">Please enter bellow information and click the avatar to upload a picture!</p>
            </div>
            <div class="form-group" [ngClass]="{ 'has-danger': invalidOrgId() }"> <!-- Org ID -->
                <label for="org_id" class="control-label">Organization Id *</label>
                <input type="text" formControlName="org_id" class="form-control" [ngClass]="{ 'is-invalid': invalidOrgId() }" id="org_id" name="org_id" placeholder="Company Name Id" readonly >
                <p *ngIf="invalidOrgId()" id="org_id_error" class="invalid-feedback">Invalid Organization ID.</p>
            </div>

            <div class="form-group" [ngClass]="{ 'has-danger': invalidOrgId() }"> <!-- Ext ID -->
                <label for="ext_id" class="control-label">External Id *</label>
                <input type="text" formControlName="ext_id" class="form-control" [ngClass]="{ 'is-invalid': invalidExtId() }" id="ext_id" name="ext_id" placeholder="Mã quét vân tay cũ"  >
                <p *ngIf="invalidExtId()" id="ext_id_error" class="invalid-feedback">Invalid External ID.</p>
            </div>
  
              <div class="form-group" [ngClass]="{ 'has-danger': invalidFirstName() }"> <!-- First Name -->
                  <label for="first_name" class="form-control-label">First Name *</label>
                  <input type="text" formControlName="first_name" class="form-control" [ngClass]="{ 'is-invalid': invalidFirstName() }" id="first_name" name="first_name" placeholder="Tên">
                  <p *ngIf="invalidFirstName()" id="first_name_error" class="invalid-feedback">Invalid first name.</p>
              </div>
          
              <div class="form-group" [ngClass]="{ 'has-danger': invalidLastName() }"> <!-- Last Name -->
                  <label for="last_name" class="form-control-label">Last Name *</label>
                  <input type="text" formControlName="last_name" class="form-control" [ngClass]="{ 'is-invalid': invalidLastName() }" id="last_name" name="last_name" placeholder="Họ và tên đệm">
                  <p *ngIf="invalidLastName()" id="last_name_error" class="invalid-feedback">Invalid last name.</p>
              </div>

              <div class="form-group" [ngClass]="{ 'has-danger': invalidTelephone() }"> <!-- Telephone -->
                <label for="telephone" class="form-control-label">Telephone *</label>
                <input type="text" formControlName="telephone" class="form-control" [ngClass]="{ 'is-invalid': invalidTelephone() }" id="telephone" name="telephone" placeholder="0987654321">
                <p *ngIf="invalidTelephone()" id="telephone_error" class="invalid-feedback">Invalid telephone.</p>
            </div>
          
              <div class="form-group" [ngClass]="{ 'has-danger': invalidEmail() }"> <!-- Email -->
                  <label for="email" class="form-control-label">E-mail</label>
                  <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': invalidEmail() }" id="email" name="email" placeholder="your@email.com">
                  <p *ngIf="invalidEmail()" id="email_error" class="invalid-feedback">Invalid email address.</p>
              </div>
          
              <div class="form-group" [ngClass]="{ 'has-danger': invalidAvatar() }">
                  <label for="avatar" class="form-control-label">Avatar *</label>
                  <p *ngIf="invalidAvatar()"  id="avatar_error" class="invalid-feedback">Upload avatar please.</p>
                  <app-profile-picture [(url)]=picurl [editmode]=editEnabled 
                  [smallsize]=smallsize
                  [up4RegisterMode]=up4RegisterEnabled id="avatar" (avatarIdChanged)="onAvatarIdChanged($event)" ></app-profile-picture>
                </div> 
  
            
          </div>
        
        
        <div class="col-md-6">
        <h4 class="bold"><span>Step 2:</span> Upload face images</h4>
       
            <div class="form-group">
                <label for="multiple">Select JPG files to upload</label>
                <div *ngIf="(userId!=0) && (!uploadCompleted)">
                    <p class="text-info">Please click on Browse button and select or take some pictures!</p>
                </div>
                <input type="file" class="form-control" name="multiple" ng2FileSelect [uploader]="uploader" multiple  [disabled]="userId==0 ? 'disabled': null" />
            </div>
            <!-- div class="form-group">
                <label for="single">single</label>
                <input type="file" class="form-control" name="single" ng2FileSelect [uploader]="uploader" />                                  
            </div -->      
            <div *ngIf="lastError">
                <p class="text-danger">Last Error: {{lastError}}</p>
            </div>      
            

            Queue length: {{ uploader?.queue?.length }}
            <table class="table">
                <thead>
                <tr>
                    <th width="50%">Name</th>
                    <th>Size</th>
                    <th>Progress</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of uploader.queue">
                    <td><strong>{{ item.file.name }}</strong></td>
                    <td nowrap>{{ item.file.size/1024/1024 | number:'.2' }} MB</td>
                    <td>
                        <div class="progress" style="margin-bottom: 0;">
                            <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
                        </div>
                    </td>
                    <td class="text-center">
                        <span *ngIf="item.isSuccess"><i class="fa fa-check"></i></span>
                        <span *ngIf="item.isCancel"><i class="fa fa-close"></i></span>
                        <span *ngIf="item.isError"><i class="fa fa-warning"></i></span>
                    </td>
                    <td nowrap>
                        <button type="button" class="btn btn-success btn-xs"
                                (click)="item.upload()" [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                <i class="fa fa-upload"></i> Upload
                        </button>
                        <button type="button" class="btn btn-warning btn-xs"
                                (click)="item.cancel()" [disabled]="!item.isUploading">
                                <i class="fa fa-close"></i> Cancel
                        </button>
                        <button type="button" class="btn btn-danger btn-xs"
                                (click)="item.remove()">
                                <i class="fa fa-trash"></i> Remove
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
  
            <div>
                <div>
                    Queue progress:
                    <div class="progress" style="">
                        <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                    </div>
                </div>
                <div *ngIf="uploader.getNotUploadedItems().length">
                    <p class="text-info">Press Upload Item or Upload all button!</p>                    
                </div>
                <button type="button" class="btn btn-primary btn-s"
                        (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length" >
                        <i class="fa fa-upload"></i> Upload all
                </button>
                <button type="button" class="btn btn-warning btn-s"
                        (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
                    <i class="fa fa-close"></i> Cancel all
                </button>
                <button type="button" class="btn btn-danger btn-s"
                        (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
                    <i class="fa fa-trash"></i> Remove all
                </button>
            </div>
            
                <br><br>
                <p>User ID: {{userId}}</p> 
                <div class="form-group has-error"> 
                    <button type="submit" class="btn btn-primary" [disabled]="userId==0 || (!uploadCompleted) ? 'disabled': null">
                        <i class="fa fa-address-card"></i> Register</button>                    
                </div>         
                
                <div *ngIf="isRegSuccess">
                    Registration successful!!
                </div>

            
        </div>

    </div>
</form>
  </div>
