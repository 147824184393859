import { Component, OnInit } from '@angular/core';
import * as myGlobal from '../globals'; 

@Component({
  selector: 'app-dash-board',
  templateUrl: './dash-board.component.html',
  styleUrls: ['./dash-board.component.scss']
})


export class DashBoardComponent implements OnInit {

  public big_logo =  'biglogo.jpg';
  constructor() { }

  getOrgId() {
    let data = JSON.parse(localStorage.getItem('currentUser'));
    return data.org_id;
  }

  ngOnInit() {
   
    this.big_logo= 'igh.png';
   
  }

}
