<div class="container-lg">
    <h4 class="text-primary">Thông tin bước thực hiện phác đồ</h4>
    <form [formGroup]="userForm" >
        <div class="row">       
            <div class="col">

                <div class="form-group" [ngClass]="{ 'has-danger': invalidName() }"> 
                    <label for="name" class="control-label">Tên bước phác đồ *</label>
                    <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': invalidName() }" id="name" name="name" placeholder="Tên phác đồ"  >
                    <p *ngIf="invalidName()" id="name_error" class="invalid-feedback">Tên bước phác đồ không hợp lệ</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidDesc() }"> 
                    <label for="desc" class="control-label">Mô tả *</label>
                    <input type="text" formControlName="desc" class="form-control" [ngClass]="{ 'is-invalid': invalidDesc() }" id="desc" name="desc" placeholder="Mo ta"  >
                    <p *ngIf="invalidDesc()" id="desc_error" class="invalid-feedback">Mô tả không hợp lệ</p>
                </div>
    
                <div class="form-group" [ngClass]="{ 'has-danger': invalidIndex() }">
                    <label for="index" class="form-control-label">Index *</label>
                    <input type="text" formControlName="index" class="form-control" [ngClass]="{ 'is-invalid': invalidIndex() }" id="index" name="index" placeholder="index">
                    <p *ngIf="invalidIndex()" id="index_error" class="invalid-feedback">Index không hợp lêk</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidTime() }">
                    <label for="time" class="form-control-label">Time *</label>
                    <input type="text" formControlName="time" class="form-control" [ngClass]="{ 'is-invalid': invalidTime() }" id="time" name="time" placeholder="index">
                    <p *ngIf="invalidTime()" id="time_error" class="invalid-feedback">Time không hợp lệ.</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidMaxTime() }">
                    <label for="max_time" class="form-control-label">Max time *</label>
                    <input type="text" formControlName="max_time" class="form-control" [ngClass]="{ 'is-invalid': invalidMaxTime() }" id="max_time" name="max_time" placeholder="index">
                    <p *ngIf="invalidMaxTime()" id="maxtime_error" class="invalid-feedback">Max time không hợp lệ.</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidMintime() }">
                    <label for="min_time" class="form-control-label">Min time *</label>
                    <input type="text" formControlName="min_time" class="form-control" [ngClass]="{ 'is-invalid': invalidMintime() }" id="min_time" name="min_time" placeholder="index">
                    <p *ngIf="invalidMintime()" id="mintime_error" class="invalid-feedback">Min time không hợp lệ.</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidregimen_step_when() }">
                    <label for="regimen_step_when" class="form-control-label">Thực hiện khi *</label>
                    <input type="text" formControlName="regimen_step_when" class="form-control" [ngClass]="{ 'is-invalid': invalidregimen_step_when() }" id="regimen_step_when" name="regimen_step_when" placeholder="index">
                    <p *ngIf="invalidregimen_step_when()" id="regimen_step_when_error" class="invalid-feedback">Nhập thông tin thực hiện khi.</p>
                </div>
            
                <div class="form-group" [ngClass]="{ 'has-danger': invalidNote() }">
                    <label for="note" class="form-control-label">Note</label>
                    <textarea type="text" formControlName="note" class="form-control" [ngClass]="{ 'is-invalid': invalidNote() }" id="note" name="note" placeholder="Note">
                    </textarea>
                    <p *ngIf="invalidNote()" id="note1_error" class="invalid-feedback">Nhập thông tin .</p>
                </div>
                Image:
                <img src = "{{regimenStepData['image'].url}}" width="150" height="80">
            </div>
        </div>

        <div class="row">  
            <div class="form-group has-error"> 
                <button type="submit" class="btn btn-primary" (click)="onSubmit()">
                    <i class="fa fa-check"></i> {{buttonSubmitTitle}} </button>   &nbsp;                 
            
                <button mat-raised-button class="btn btn-success" (click)="onGoBack()"><i class="fa fa-check"></i> Quay lại</button>&nbsp;         
            </div>   
        </div>
    </form>
</div>

{{regimenStepData | json }}
    