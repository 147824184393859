<div class="container-md">
    <h4 class="text-primary">Danh sách thông báo</h4>
  <div class="row">    
   
    <mat-form-field>
      <mat-label>Lọc và tìm kiếm</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Nhập thông tin tìm kiếm">
    </mat-form-field>

    <table mat-table [dataSource]="dataSource" class="table table-bordred table-striped" matSort multiTemplateDataRows>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Stt</th>
        <td mat-cell *matCellDef="let element"> {{element.position}} </td>
      </ng-container>

       <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tiêu đề </th>
        <td mat-cell *matCellDef="let element"> <a href="message-edit/{{element.id}}"> {{element.title}} </a></td>
      </ng-container>

      <ng-container matColumnDef="tag">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tag </th>
        <td mat-cell *matCellDef="let element"> {{element.tag}} </td>
      </ng-container>
  
      <ng-container matColumnDef="desc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Mô tả </th>
        <td mat-cell *matCellDef="let element"> <a href="message-edit/{{element.id}}">{{element.desc}} </a></td>
      </ng-container>
  
      <ng-container matColumnDef="content">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nội dung </th>
        <td mat-cell *matCellDef="let element"> {{element.content}} </td>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ngày tạo </th>
        <td mat-cell *matCellDef="let element"> {{element.created_at}} </td>
      </ng-container>

      <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ngày cập nhật </th>
        <td mat-cell *matCellDef="let element"> {{element.updated_at}} </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> Sửa </th>
        <td  mat-cell *matCellDef="let element">
          <p data-placement="top" data-toggle="tooltip" title="Edit"><button class="btn btn-primary btn-xs" 
              data-title="Edit" (click)="clickEditMethod(element.id)"  data-toggle="modal" data-target="#edit" ><i class="fa fa-pencil-square-o"></i></button></p>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef> Xoá </th>
        <td  mat-cell *matCellDef="let element">
          <p data-placement="top" data-toggle="tooltip" title="Delete">
            <button class="btn btn-danger btn-xs" 
              data-title="Delete" (click)="clickDeleteMethod(element.id)"   data-toggle="modal" data-target="#delete">
              <i class="fa fa-trash"></i></button></p>
        </td>
      </ng-container>

  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <!-- tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr -->

      <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="example-element-row">
      </tr>
      
    </table>
    <mat-paginator [pageSizeOptions]="[10,20,50]" showFirstLastButtons></mat-paginator>
  
</div>
</div>
