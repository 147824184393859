<div class="container-md">
    <h4 class="text-primary">Organization Information</h4>
    
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
        
    <div class="row">       
        <div class="col-md-12">
         
           
            <div class="form-group" [ngClass]="{ 'has-danger': invalidOrgId() }"> <!-- Org ID -->
                <label for="org_id" class="control-label">Organization Id *</label>
                <input type="text" formControlName="org_id" class="form-control" [ngClass]="{ 'is-invalid': invalidOrgId() }" id="org_id" name="org_id" placeholder="Company Name Id" readonly >
                <p *ngIf="invalidOrgId()" id="org_id_error" class="invalid-feedback">Invalid Organization ID.</p>
            </div>
            
            <div class="form-group" [ngClass]="{ 'has-danger':   invalidOrgType() }"> <!-- Org Type -->
                <label for="orgTypeCmb" class="control-label">Type</label>
                <select class="custom-select" (change)="changeOrgType($event)" formControlName="orgTypeCmb" id="orgTypeCmb" name="orgTypeCmb" >
                    <option value="" disabled>Choose Org Type</option>
                    <option *ngFor="let org of orgTypeList" [ngValue]="org.org_type">{{org.org_type_name}}</option>
                 </select>
                <p *ngIf="invalidOrgType()" id="orgTypeCmb_error" class="invalid-feedback">Invalid Organization Type.</p>
            </div>

            

            <div class="form-group" [ngClass]="{ 'has-danger': invalidOrgName() }"> <!-- Ext ID -->
                <label for="org_name" class="control-label">Name *</label>
                <input type="text" formControlName="org_name" class="form-control" 
                        [ngClass]="{ 'is-invalid': invalidOrgName() }" id="org_name" name="org_name" placeholder="Tên đơn vị"  >
                <p *ngIf="invalidOrgName()" id="org_name_error" class="invalid-feedback">Invalid Organization Name.</p>
            </div>
  
              <div class="form-group" [ngClass]="{ 'has-danger': invalidShortName() }"> <!-- First Name -->
                  <label for="short_name" class="form-control-label">Short Name (For system internal use only)*</label>
                  <input type="text" formControlName="short_name" class="form-control" 
                                [ngClass]="{ 'is-invalid': invalidShortName() }" id="short_name" name="short_name" placeholder="Tên ngắn">
                  <p *ngIf="invalidShortName()" id="short_name_error" class="invalid-feedback">Invalid short name.</p>
              </div>
          
              <div class="form-group" [ngClass]="{ 'has-danger': invalidDesc() }"> <!-- Last Name -->
                  <label for="desc" class="form-control-label">Description </label>
                  <input type="text" formControlName="desc" class="form-control" [ngClass]="{ 'is-invalid': invalidDesc() }" id="desc" name="desc" placeholder="Mô tả công ty/đơn vị">
                  <p *ngIf="invalidDesc()" id="desc_error" class="invalid-feedback">Invalid Description.</p>
              </div>

              <div class="form-group" [ngClass]="{ 'has-danger': invalidAddress() }"> <!-- Last Name -->
                <label for="address" class="form-control-label">Address </label>
                <input type="text" formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': invalidAddress() }" id="address" name="address" placeholder="Địa chỉ">
                <p *ngIf="invalidAddress()" id="address_error" class="invalid-feedback">Invalid Address.</p>
            </div>
            <div class="form-group" [ngClass]="{ 'has-danger': invalidContact() }"> <!-- Last Name -->
                <label for="contact" class="form-control-label">Contact Point </label>
                <input type="text" formControlName="contact" class="form-control" [ngClass]="{ 'is-invalid': invalidContact() }" id="contact" name="contact" placeholder="Đầu mối liên hệ">
                <p *ngIf="invalidContact()" id="contact_error" class="invalid-feedback">Invalid Contact.</p>
            </div>
            

              <div class="form-group" [ngClass]="{ 'has-danger': invalidTelephone() }"> <!-- Telephone -->
                <label for="telephone" class="form-control-label">Telephone *</label>
                <input type="text" formControlName="telephone" class="form-control" [ngClass]="{ 'is-invalid': invalidTelephone() }" id="telephone" name="telephone" placeholder="0987654321">
                <p *ngIf="invalidTelephone()" id="telephone_error" class="invalid-feedback">Invalid telephone.</p>
            </div>
          
              <div class="form-group" [ngClass]="{ 'has-danger': invalidEmail() }"> <!-- Email -->
                  <label for="email" class="form-control-label">E-mail</label>
                  <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': invalidEmail() }" id="email" name="email" placeholder="your@email.com">
                  <p *ngIf="invalidEmail()" id="email_error" class="invalid-feedback">Invalid email address.</p>
              </div>          
              

          </div>
    </div>
    <div class="form-group has-error"> 
        <button type="submit" class="btn btn-primary" >
            <i class="fa fa-check"></i> Submit</button>                    
    </div>         
    </form>
</div>
