import {Component, OnInit,ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import {UserProfileService} from '../user-profile.service';
import {MatSort} from '@angular/material/sort';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {checkTimeOut} from '../utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-checkin-review',
  templateUrl: './checkin-review.component.html',
  styleUrls: ['./checkin-review.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})


export class CheckinReviewComponent implements OnInit {
  displayedColumns: string[] = ['position','org_id','review','frauddet','face','usr_id', 'ext_id','last_name', 'first_name',  'created_on']; // Removed 'telephone','email',
  
  lastaccess: any;
  dataSource = new MatTableDataSource<PeriodicElement>(this.lastaccess);

  userForm: FormGroup;

  defaultImage = '/assets/avatar.png';
  imageToShowOnError  = '/assets/avatar.png';
  expandedElement: PeriodicElement | null;
  selOrgId = 0;
  orgList = [];

  mac_chk_all = true;
  mac_chk_notcheck = true;
  mac_chk_ok = true;
  mac_chk_phone = true;
  mac_chk_other = true;
  

  man_chk_all = true;
  man_chk_notcheck = true;
  man_chk_ok = true;
  man_chk_phone = true;
  man_chk_other = true;
  


  timer = 0;

  //image = 'https://images.unsplash.com/photo-1443890923422-7819ed4101c0?fm=jpg';
  //imgurl = 'http://localhost:3001/checkin_img/40/53d8c3e42bb000f002411ab86bb00052d75321d0228300680b0b9bd463a7c3133d814149229808553037f599188fc3c2'
  // screen_lg = '400px';
  //   screen_md = '202px';
  //  // defaultImage = 'https://www.placecage.com/1000/1000';
  //   image1 = 'https://images.unsplash.com/photo-1422004707501-e8dad229e17a?fm=jpg';
  //   image2 = 'https://images.unsplash.com/photo-1439931444800-9bcc83f804a6?fm=jpg';
  //   image3 = 'https://images.unsplash.com/photo-1417128281290-30a42da46277?fm=jpg';


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
        private userProfileService: UserProfileService,
        private formBuilder: FormBuilder,
        private router: Router
          ) { }

  ngOnInit() {
    this.getCheckinLogReview();
    this.dataSource.sort = this.sort;

    this.userForm = this.formBuilder.group({
      //org_id: [this.orgId, Validators.required],
      //org_type: [this.orgTypeName, Validators.required],
      orgListCmb: [this.selOrgId, Validators.required],      
      
    });
    this.getOrgList();
  }

  getOrgList(): void {
    this.userProfileService.getOrgList(this.selOrgId)
    .subscribe(data => {
      if (!checkTimeOut(data,this.router)) {return;}
      this.orgList = data;
     
    });
  }

  changeOrgId(e) {
    this.selOrgId = this.userForm.controls.orgListCmb.value;
    this.getCheckinLogReview();
  }

  invalidOrgId()
  {
    return true;
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
   }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  mouseEnter(data : string){
    //console.log("mouse enter : " + data);

    

  }

  public doSubmit (event, item) {
   

    if (item['manual_check_other']) {
      item['review_result'] = item.review_result.substring(0, 1) + '3';
    } else if (item['manual_check_phone']) {
      item['review_result'] = item.review_result.substring(0, 1) + '2';
    } else {
      item['review_result'] = item.review_result.substring(0, 1) + '1';
    }
  
    console.log(item);

    let data = {review_result: item['review_result']};
    this.userProfileService.modifyCheckinLog(
      item['checkin_log_id'],
      data
      ).subscribe((data: []) => {
      if (!checkTimeOut(data,this.router)) {return;}
      
     // alert(JSON.stringify(data));
     // console.log(data);
     item['ret_msg'] ='Done!'; 

    });
  }

  public chkChangeValue(item,val) {    
    if (val=='OK') {
      //item['manual_check_ok'] = true;
      item['manual_check_phone'] = false;
      item['manual_check_other'] = false;
    } else if (val=='PHONE') {
      item['manual_check_ok'] = false;
      //item['manual_check_phone'] = true;
      item['manual_check_other'] = false;
    } else {
      item['manual_check_ok'] = false;
      item['manual_check_phone'] = false;
      //item['manual_check_other'] = false;    
    }
  }

  buildFilterType() {
    var ret = '';
    if (this.mac_chk_notcheck) {ret+='1'} else {ret+='0'};
    if (this.mac_chk_ok) {ret+='1'} else {ret+='0'};
    if (this.mac_chk_phone) {ret+='1'} else {ret+='0'};
    if (this.mac_chk_other) {ret+='1'} else {ret+='0'};

    if (this.man_chk_notcheck) {ret+='1'} else {ret+='0'};
    if (this.man_chk_ok) {ret+='1'} else {ret+='0'};
    if (this.man_chk_phone) {ret+='1'} else {ret+='0'};
    if (this.man_chk_other) {ret+='1'} else {ret+='0'};
    console.log('buildFilterType:' + ret);
    return ret;
  }

  chkChangeAll(type) {
    if (type=='mac') {       
        this.mac_chk_notcheck =  this.mac_chk_all;
        this.mac_chk_ok = this.mac_chk_all;
        this.mac_chk_phone = this.mac_chk_all;
        this.mac_chk_other = this.mac_chk_all;
    } else {        
        this.man_chk_notcheck = this.man_chk_all;
        this.man_chk_ok = this.man_chk_all;
        this.man_chk_phone = this.man_chk_all;
        this.man_chk_other = this.man_chk_all;
    
    }
    this.getCheckinLogReview ();
  }

  chkChangeSingle(type) {
    if (type=='mac') {    
        this.mac_chk_all = this.mac_chk_notcheck && this.mac_chk_ok  && this.mac_chk_phone && this.mac_chk_other;        
    } else {        
        this.man_chk_all = this.man_chk_notcheck && this.man_chk_ok  && this.man_chk_phone && this.man_chk_other;
    }
    this.getCheckinLogReview ();
  }

  getCheckinLogReview() {
    this.lastaccess = [];
    console.log('DEBUG:' +  this.buildFilterType());
    this.userProfileService.getCheckinLogReview(this.selOrgId,this.buildFilterType()).subscribe((data: []) => {

      if (!checkTimeOut(data,this.router)) {
          return;
      }
      //console.log('DAT:' + JSON.stringify(data));
      //this.lastaccess = data;
      
      data.forEach(e => {
        var obj= new PeriodicElement();

        obj['position'] = e['row_number'];
        
        obj['checkin_log_id']= e['checkin_log_id'];
        obj['usr_id'] = e['usr_id'];
        obj['ext_id'] = e['ext_id'];
        obj['org_id'] = e['org_id'];
        obj['first_name'] = e['first_name'];
        obj['last_name'] = e['last_name'];
        obj['telephone'] = e['telephone'];
        obj['email'] = e['email'];
        obj['created_on'] = e['created_on'];
        obj['review_result'] = e['review_result'];
        obj['content_data'] = JSON.parse(e['content_data']);

        obj['fraud'] = e['fraud'];
        obj['fraud_type'] = e['fraud_type'];

        obj['machine_check'] = obj['fraud']=='1';
        
        obj['manual_check_ok'] = false;
        obj['manual_check_phone'] = false;
        obj['manual_check_other'] = false;

        obj['machine_check_ok'] = false;
        obj['machine_check_phone'] = false;
        obj['machine_check_other'] = false;

        if (obj['fraud']=='1') {
          obj['machine_check_ok'] = false;
          if (obj['fraud_type']=='phone') {
            obj['machine_check_phone'] = true;
          } else {
            obj['machine_check_other'] = true;
          }
        } else if (obj['fraud']=='0') {
          obj['machine_check_ok'] = true;
        }
        

        obj['ret_msg'] =''; // Message khi update

        if (obj.review_result!=null) {
          //obj['machine_check'] = obj['fraud']=='1';// (obj.review_result.substring(0, 1)=='1');
          if (obj.review_result.length>=2) {
            obj['manual_check_ok'] = (obj.review_result.substring(1, 2)=='1');
            obj['manual_check_phone'] = (obj.review_result.substring(1, 2)=='2');
            obj['manual_check_other'] = (obj.review_result.substring(1, 2)=='3');
          } 
        } else {
          obj.review_result = '0'; // Clear data for submit process
        }

        if (obj.content_data!=null) {
          obj['img_url'] = obj['org_id'] + '/' + obj.content_data.imgId;
        }
        
        if (obj.content_data === null || obj.content_data === undefined) {     
          obj.content_data = {'imgId':'/assets/avatar.png'};
        }
        this.lastaccess.push(obj);
       
      });

      this.dataSource.data = this.lastaccess ;//= new MatTableDataSource<PeriodicElement>(this.lastaccess); 
      this.dataSource.sort = this.sort;
      //this.dataSource.paginator = this.paginator;   
    });
  }
}

export class PeriodicElement {
  position: number;
  ext_id : string;
  first_name: string;
  last_name: string;
  telephone: string;
  email: string;  
  created_on : string;
  content_data: any;
  review_result : string;
}



