<div class="container-md">
    <h4 class="text-primary">User Info</h4>
    <form [formGroup]="userForm" >
        <div class="row" *ngIf="isAddNew">  
            <strong>Tạo mới người dùng</strong>
        </div>
        <div class="row" *ngIf="!isAddNew">  
            <strong>Cập nhật thông tin cho người dùng: {{userName}} (ID :{{userId}})</strong>
        </div>
        <div class="row"> 
            <div class="col">


                <div class="form-group" [ngClass]="{ 'has-danger': invalidUsername() }"> 
                    <label for="username" class="form-control-label">Username </label>
                    <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': invalidUsername() }" id="username" name="username" placeholder="Nhập user name" >   
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidTelephone() }"> 
                    <label for="msisdn" class="form-control-label">Telephone </label>
                    <input type="text" formControlName="msisdn" class="form-control" [ngClass]="{ 'is-invalid': invalidTelephone() }" id="msisdn" name="msisdn" placeholder="Nhập telephone">   
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidEmail() }"> 
                    <label for="email" class="form-control-label">Email </label>
                    <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': invalidEmail() }" id="email" name="email" placeholder="Nhập user email">   
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger':   invalidRoles() }"> 
                    <label for="webAppRoles" class="control-label">Select user role:</label>
                    <select class="custom-select" (change)="changeOrgId($event)" formControlName="webAppRoles" id="webAppRoles" name="webAppRoles" placeholder="Chọn quyền của người dùng">
                        <option value="sysadmin">sysadmin</option>
                        <option value="supporter">supporter</option>
                        <option value="editor">editor</option>  
                     </select>
                    <p *ngIf="invalidRoles()" id="orgListCmb_error" class="invalid-feedback">Invalid User Roles.</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger':   invalidLoc() }"> 
                    <label for="location" class="control-label">Select location:</label>
                    <select class="custom-select" (change)="changeLoc($event)" formControlName="location" id="location" name="location" placeholder="Chọn nơi làm việc">
                        <option value="all">All</option>
                        <option value="pk1">Phong kham 1</option>
                        <option value="pk2">Phong kham 2</option>  
                     </select>
                    <p *ngIf="invalidLoc()" id="orgListCmb_error" class="invalid-feedback">Invalid User Location.</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidPassword() }" *ngIf="isAddNew"> 
                    <label for="passwd" class="form-control-label">Password </label>
                    <input type="password" formControlName="passwd" class="form-control" [ngClass]="{ 'is-invalid': invalidPassword() }" id="passwd" name="passwd" placeholder="Nhập mật khẩu">
                </div>
        
                <div class="form-group" [ngClass]="{ 'has-danger': invalidRePassword() }" *ngIf="isAddNew"> 
                    <label for="reenter_passwd" class="form-control-label">Re-enter Password</label>
                    <input type="password" formControlName="reenter_passwd" class="form-control" [ngClass]="{ 'is-invalid': invalidRePassword() }" id="reenter_passwd" name="reenter_passwd" placeholder="Nhập lại mật khẩu">
                </div>
                
                <div class="form-group" >                     
                    <div>
                    Khóa user: <input type="checkbox" formControlName="blocked" class="form-control" id="blocked" name="blocked" >
                    </div>
                </div>
               
            </div>
        </div>
           
        <div class="row"> 
            <button type="submit" class="btn btn-primary" (click)="onSubmit()"><i class="fa fa-check"></i> {{buttonSubmitTitle}}</button>   &nbsp; 
            <a routerLink="/change-pass/{{userId}}" *ngIf="!isAddNew"><button mat-raised-button class="btn btn-warning"><i class="fa fa-key"></i> Change Password</button></a>&nbsp; 
            <button class="btn btn-success" (click)="onGoBack()"><i class="fa fa-mail-reply"></i> Quay lại</button>   &nbsp; 

        </div>
    </form>
</div>

