<form [formGroup]="userForm" (ngSubmit)="onSubmit()">

  <div class="form-group" [ngClass]="{ 'has-danger':   invalidReportingCycle() }"> <!-- Org Type -->
     <label for="reportingCycleListCmb" class="control-label">Select Reporting Cycle:</label>
     <select class="custom-select" (change)="changeReportingCycle($event)" formControlName="reportingCycleListCmb" id="reportingCycleListCmb" name="reportingCycleListCmb" >
         <option value="" disabled>Select reporting cycle to view</option>
         <option *ngFor="let rp of reportingCycleList" [ngValue]="rp.reporting_cycle">{{rp.reporting_cycle}}</option>
      </select>
     <p *ngIf="invalidReportingCycle()" id="reportingCycleListCmb_error" class="invalid-feedback">Invalid Reporting Cycle.</p>
 </div>

 <!-- div class="form-group has-error"> 
  <button type="submit" class="btn btn-primary" >
      <i class="fa fa-check"></i> Query</button>                    
</div -->        
<button class="btn btn-success"  (click)="exportTable()"> Save to Excel file</button>
</form>
<hr>

<table border=0 cellpadding=0 cellspacing=0 width=2205 style='border-collapse:
collapse;table-layout:fixed;width:1678pt'  id="tblReport" >
<col width=32 style='mso-width-source:userset;mso-width-alt:1170;width:24pt'>
<col width=76 style='mso-width-source:userset;mso-width-alt:2779;width:57pt'>
<col width=140 style='mso-width-source:userset;mso-width-alt:5120;width:105pt'>
<col width=33 span=3 style='mso-width-source:userset;mso-width-alt:1206;
width:25pt'>
<col width=38 span=29 style='mso-width-source:userset;mso-width-alt:1389;
width:29pt'>
<col width=42 span=18 style='mso-width-source:userset;mso-width-alt:1536;
width:32pt'>
<col width=33 span=2 style='mso-width-source:userset;mso-width-alt:1206;
width:25pt'>
<col width=55 style='mso-width-source:userset;mso-width-alt:2011;width:41pt'>
<col width=33 style='mso-width-source:userset;mso-width-alt:1206;width:25pt'>
<col width=52 style='mso-width-source:userset;mso-width-alt:1901;width:39pt'>
<col width=33 span=8 style='mso-width-source:userset;mso-width-alt:1206;
width:25pt'>
<col width=36 style='mso-width-source:userset;mso-width-alt:1316;width:27pt'>
<col width=52 style='mso-width-source:userset;mso-width-alt:1901;width:39pt'>
<tr height=17 style='height:12.75pt'>
 <td colspan=50 height=17 class=xl95 width=2079 style='height:12.75pt;
 width:1582pt'>&#272;&#417;n v&#7883;: {{rootOrganData.org_name}}</td>
 <td width=42 style='width:32pt'></td>
 <td width=42 style='width:32pt'></td>
 <td width=42 style='width:32pt'></td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td colspan=50 height=17 class=xl95 style='height:12.75pt'>&#272;&#7883;a
 ch&#7881;: {{rootOrganData.address}}<span style='mso-spacerun:yes'> </span></td>
 <td colspan=3 style='mso-ignore:colspan'></td>
</tr>
<tr height=24 style='mso-height-source:userset;height:18.0pt'>
 <td colspan=50 height=24 class=xl96 style='height:18.0pt'>B&#7842;NG
 TH&#7888;NG KÊ CH&#7844;M CÔNG</td>
 <td colspan=3 style='mso-ignore:colspan'></td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td colspan=50 height=17 class=xl97 style='height:12.75pt'>Thang {{month}} nam {{year}}</td>
 <td colspan=3 style='mso-ignore:colspan'></td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td height=17 colspan=53 style='height:12.75pt;mso-ignore:colspan'></td>
</tr>
<tr height=20 style='mso-height-source:userset;height:15.0pt'>
 <td rowspan=2 height=48 class=xl93 width=32 style='height:36.0pt;width:24pt'>STT</td>
 <td rowspan=2 class=xl93 width=76 style='width:57pt'>Mã nhân viên</td>
 <td rowspan=2 class=xl93 width=140 style='width:105pt'>Tên nhân viên</td>
 <td rowspan=2 class=xl93 width=33 style='width:25pt'>&nbsp;</td>
 <td class=xl66 style='border-left:none'>1</td>
 <td class=xl66 style='border-left:none'>2</td>
 <td class=xl66 style='border-left:none'>3</td>
 <td class=xl66 style='border-left:none'>4</td>
 <td class=xl66 style='border-left:none'>5</td>
 <td class=xl66 style='border-left:none'>6</td>
 <td class=xl66 style='border-left:none'>7</td>
 <td class=xl66 style='border-left:none'>8</td>
 <td class=xl66 style='border-left:none'>9</td>
 <td class=xl66 style='border-left:none'>10</td>
 <td class=xl66 style='border-left:none'>11</td>
 <td class=xl66 style='border-left:none'>12</td>
 <td class=xl66 style='border-left:none'>13</td>
 <td class=xl66 style='border-left:none'>14</td>
 <td class=xl66 style='border-left:none'>15</td>
 <td class=xl66 style='border-left:none'>16</td>
 <td class=xl66 style='border-left:none'>17</td>
 <td class=xl66 style='border-left:none'>18</td>
 <td class=xl66 style='border-left:none'>19</td>
 <td class=xl66 style='border-left:none'>20</td>
 <td class=xl66 style='border-left:none'>21</td>
 <td class=xl66 style='border-left:none'>22</td>
 <td class=xl66 style='border-left:none'>23</td>
 <td class=xl66 style='border-left:none'>24</td>
 <td class=xl66 style='border-left:none'>25</td>
 <td class=xl66 style='border-left:none'>26</td>
 <td class=xl66 style='border-left:none'>27</td>
 <td class=xl66 style='border-left:none'>28</td>
 <td class=xl66 style='border-left:none'><div *ngIf="dateOfMonth.numDateOfMonth>=29">29</div></td>
 <td class=xl66 style='border-left:none'><div *ngIf="dateOfMonth.numDateOfMonth>=30">30</div></td>
 <td class=xl66 style='border-left:none'><div *ngIf="dateOfMonth.numDateOfMonth==31">31</div></td>
 <td colspan=2 class=xl101 width=84 style='border-right:.5pt solid black;
 border-left:none;width:64pt'>Ngày công</td>
 <td colspan=2 class=xl103 width=84 style='border-right:.5pt solid black;
 border-left:none;width:64pt'>Gi&#7901; công</td>
 <td rowspan=2 class=xl104 width=42 style='width:32pt'>V&#7855;ng KP</td>
 <td colspan=2 class=xl103 width=84 style='border-right:.5pt solid black;
 border-left:none;width:64pt'>Ngày ngh&#7881;<span
 style='mso-spacerun:yes'> </span></td>
 <td rowspan=2 class=xl104 width=42 style='width:32pt'>T&#7893;ng công</td>
 <td colspan=2 class=xl103 width=84 style='border-right:.5pt solid black;
 border-left:none;width:64pt'>Vào tr&#7877;</td>
 <td colspan=2 class=xl103 width=84 style='border-right:.5pt solid black;
 border-left:none;width:64pt'>Ra s&#7899;m</td>
 <td rowspan=2 class=xl104 width=42 style='width:32pt'>Ph&#7841;t tr&#7877;</td>
 <td colspan=2 class=xl103 width=84 style='border-right:.5pt solid black;
 border-left:none;width:64pt'>Vào s&#7899;m</td>
 <td colspan=2 class=xl103 width=84 style='border-right:.5pt solid black;
 border-left:none;width:64pt'>Ra tr&#7877;</td>
 <td rowspan=2 class=xl105 width=42 style='width:32pt'>T. N&#259;ng su&#7845;t</td>
</tr>
<tr height=28 style='mso-height-source:userset;height:21.0pt'>
 <td class=xl67 style='border-top:none;border-left:none' 
      *ngFor="let day of daysOfWeeks; let i = index">{{day}}</td> 
      
 <td class=xl104 width=42 style='border-top:none;width:32pt'>NT</td>
 <td class=xl104 width=42 style='border-top:none;border-left:none;width:32pt'>CT</td>
 <td class=xl104 width=42 style='border-top:none;border-left:none;width:32pt'>NT</td>
 <td class=xl104 width=42 style='border-top:none;border-left:none;width:32pt'>CT</td>
 <td class=xl104 width=42 style='border-top:none;border-left:none;width:32pt'>Có
 phép<span style='mso-spacerun:yes'> </span></td>
 <td class=xl104 width=42 style='border-top:none;border-left:none;width:32pt'>TS</td>
 <td class=xl104 width=42 style='border-top:none;border-left:none;width:32pt'>L&#7847;n</td>
 <td class=xl104 width=42 style='border-top:none;border-left:none;width:32pt'>Phút</td>
 <td class=xl104 width=42 style='border-top:none;border-left:none;width:32pt'>L&#7847;n</td>
 <td class=xl104 width=42 style='border-top:none;border-left:none;width:32pt'>Phút</td>
 <td class=xl104 width=42 style='border-top:none;border-left:none;width:32pt'>L&#7847;n</td>
 <td class=xl104 width=42 style='border-top:none;border-left:none;width:32pt'>Phút</td>
 <td class=xl104 width=42 style='border-top:none;border-left:none;width:32pt'>L&#7847;n</td>
 <td class=xl104 width=42 style='border-top:none;border-left:none;width:32pt'>Phút</td>
</tr>
<tr height=20 style='mso-height-source:userset;height:15.0pt'>
 <td height=20 class=xl68 width=32 style='height:15.0pt;width:24pt'>1</td>
 <td class=xl68 width=76 style='border-left:none;width:57pt'>2</td>
 <td class=xl68 width=140 style='border-left:none;width:105pt'>3</td>
 <td class=xl68 width=33 style='border-left:none;width:25pt'>4</td>
 <td class=xl68 width=33 style='border-left:none;width:25pt'>5</td>
 <td class=xl68 width=33 style='border-left:none;width:25pt'>6</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>7</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>8</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>9</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>10</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>11</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>12</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>13</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>14</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>15</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>16</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>17</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>18</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>19</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>20</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>21</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>22</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>23</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>24</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>25</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>26</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>27</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>28</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>29</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>30</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>31</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>32</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>33</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>34</td>
 <td class=xl68 width=38 style='border-left:none;width:29pt'>35</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>36</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>37</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>38</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>39</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>40</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>41</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>42</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>43</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>44</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>45</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>46</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>47</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>48</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>49</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>50</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>51</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>52</td>
 <td class=xl68 width=42 style='border-left:none;width:32pt'>53</td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td height=17 class=xl98 colspan=3 style='height:12.75pt;mso-ignore:colspan'>
    organizationName</td>
 <td class=xl99>&nbsp;</td>
 <td class=xl99>&nbsp;</td>
 <td class=xl99>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
 <td class=xl100>&nbsp;</td>
</tr>

<!-- row-->
<ng-container *ngFor="let data of reportData; let idx = index">
 
    <ng-container
     *ngIf="idx%2 == 0; then oddData; else evenData">
   </ng-container>
    <ng-template #oddData>

       
<tr height=17 style='height:12.75pt'>
 <td rowspan=2 height=34 class=xl87 style='border-bottom:.5pt solid black;
 height:25.5pt;border-top:none'>{{idx+1}}</td>
 <td rowspan=2 class=xl89 style='border-bottom:.5pt solid black;border-top:
 none'>{{data.ext_id}}&nbsp;</td>
 <td rowspan=2 class=xl91 style='border-bottom:.5pt solid black;border-top:
 none'>{{data.last_name}} {{data.first_name}} &nbsp;</td>
 <td class=xl70 style='border-top:none;border-left:none'>Vào</td>
 <td class=xl69 style='border-top:none;border-left:none'>{{data.I1}}&nbsp;</td>
 <td class=xl70 style='border-top:none;border-left:none'>{{data.I2}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I3}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I4}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I5}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I6}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I7}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I8}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I9}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I10}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I11}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I12}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I13}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I14}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I15}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I16}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I17}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I18}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I19}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I20}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I21}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I22}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I23}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I24}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I25}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I26}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I27}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I28}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I29}}&nbsp;</td>
 <td class=xl71 style='border-left:none'>{{data.I30}}&nbsp;</td>
 <td class=xl108 style='border-left:none'>{{data.I31}}&nbsp;</td>

 <td rowspan=2 class=xl112 >{{data.sum_data.nc_nt}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.nc_nl}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.gc_nt}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.gc_nl}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.vang_kp}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.nn_cp}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.nn_ts}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.tong}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.vaotre_lan}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.vaotre_phut}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.rasom_lan}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.rasom_phut}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.phat_tre}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.vaosom_lan}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.vaosom_phut}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.ratre_lan}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.ratre_phut}}&nbsp;</td>
 <td rowspan=2 class=xl112 >{{data.sum_data.nangsuat}}&nbsp;</td>
</tr>

<tr height=17 style='height:12.75pt'>
 <td height=17 class=xl73 style='height:12.75pt;border-top:none;border-left:
 none'>Ra</td>
 <td class=xl72 style='border-top:none;border-left:none'>{{data.O1}}&nbsp;</td>
 <td class=xl73 style='border-top:none;border-left:none'>{{data.O2}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O3}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O4}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O5}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O6}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O7}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O8}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O9}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O10}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O11}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O12}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O13}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O14}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O15}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O16}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O17}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O18}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O19}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O20}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O21}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O22}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O23}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O24}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O25}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O26}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O27}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O28}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O29}}&nbsp;</td>
 <td class=xl74 style='border-left:none'>{{data.O30}}&nbsp;</td>
 <td class=xl109 style='border-top:none;border-left:none'>{{data.O31}}&nbsp;</td>
</tr>
</ng-template>
<ng-template #evenData>
<!-- New color -->
<tr height=17 style='height:12.75pt'>
 <td rowspan=2 height=34 class=xl81 style='border-bottom:.5pt solid black;
 height:25.5pt;border-top:none'>{{idx+1}}</td>
 <td rowspan=2 class=xl83 style='border-bottom:.5pt solid black;border-top:
 none'>{{data.ext_id}}</td>
 <td rowspan=2 class=xl85 style='border-bottom:.5pt solid black;border-top:
 none'>{{data.last_name}} {{data.first_name}}&nbsp;</td>
 <td class=xl76 style='border-top:none;border-left:none'>Vào</td>
 <td class=xl75 style='border-top:none;border-left:none'>{{data.I1}}&nbsp;</td>
 <td class=xl76 style='border-top:none;border-left:none'>{{data.I2}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I3}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I4}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I5}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I6}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I7}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I8}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I9}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I10}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I11}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I12}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I13}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I14}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I15}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I16}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I17}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I18}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I19}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I20}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I21}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I22}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I23}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I24}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I25}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I26}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I27}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I28}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I29}}&nbsp;</td>
 <td class=xl77 style='border-top:none;border-left:none'>{{data.I30}}&nbsp;</td>
 <td class=xl110 style='border-top:none;border-left:none'>{{data.I31}}&nbsp;</td>

 <td rowspan=2 class=xl113 >{{data.sum_data.nc_nt}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.nc_nl}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.gc_nt}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.gc_nl}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.vang_kp}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.nn_cp}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.nn_ts}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.tong}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.vaotre_lan}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.vaotre_phut}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.rasom_lan}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.rasom_phut}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.phat_tre}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.vaosom_lan}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.vaosom_phut}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.ratre_lan}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.ratre_phut}}&nbsp;</td>
 <td rowspan=2 class=xl113 >{{data.sum_data.nangsuat}}&nbsp;</td>


</tr>
<tr height=17 style='height:12.75pt'>
 <td height=17 class=xl79 style='height:12.75pt;border-top:none;border-left:
 none'>Ra</td>
 <td class=xl78 style='border-top:none;border-left:none'>{{data.O1}}&nbsp;</td>
 <td class=xl79 style='border-top:none;border-left:none'>{{data.O2}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O3}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O4}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O5}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O6}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O7}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O8}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O9}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O10}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O11}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O12}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O13}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O14}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O15}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O16}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O17}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O18}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O19}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O20}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O21}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O22}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O23}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O24}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O25}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O26}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O27}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O28}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O29}}&nbsp;</td>
 <td class=xl80 style='border-left:none'>{{data.O30}}&nbsp;</td>
 <td class=xl111 style='border-top:none;border-left:none'>{{data.O31}}&nbsp;</td>
</tr>
</ng-template>
</ng-container>
<!-- end of row -->
<tr height=17 style='height:12.75pt'>
 <td height=17 colspan=53 style='height:12.75pt;mso-ignore:colspan'></td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td height=17 colspan=3 style='height:12.75pt;mso-ignore:colspan'></td>
 <td class=xl65 colspan=2 style='mso-ignore:colspan'>Ghi chú:</td>
 <td colspan=48 style='mso-ignore:colspan'></td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td height=17 colspan=4 style='height:12.75pt;mso-ignore:colspan'></td>
 <td colspan=6 style='mso-ignore:colspan'>NT: Ngày th&#432;&#7901;ng, CT:
 Cu&#7889;i tu&#7847;n</td>
 <td colspan=43 style='mso-ignore:colspan'></td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td height=17 colspan=4 style='height:12.75pt;mso-ignore:colspan'></td>
 <td colspan=11 style='mso-ignore:colspan'>TC1: T&#259;ng ca m&#7913;c 1, TC2:
 T&#259;ng ca m&#7913;c 2, TC3: T&#259;ng ca m&#7913;c 3</td>
 <td colspan=38 style='mso-ignore:colspan'></td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td height=17 colspan=4 style='height:12.75pt;mso-ignore:colspan'></td>
 <td colspan=9 style='mso-ignore:colspan'>OM:Ngh&#7881; &#7889;m;
 TS:Ngh&#7881; Thai S&#7843;n; R:Vi&#7879;c riêng có l&#432;&#417;ng;<span
 style='mso-spacerun:yes'> </span></td>
 <td colspan=40 style='mso-ignore:colspan'></td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td height=17 colspan=4 style='height:12.75pt;mso-ignore:colspan'></td>
 <td colspan=25 style='mso-ignore:colspan'>V: V&#7855;ng (không có ch&#7845;m
 công); Tr: &#272;i tr&#7877;; Sm: V&#7873; s&#7899;m; KR: Ch&#7881; có
 gi&#7901; ch&#7845;m công vào; KV: Ch&#7881; có gi&#7901; ch&#7845;m công ra;
 +: T&#259;ng ca; X: Ch&#7845;m công &#273;úng gi&#7901;;<span
 style='mso-spacerun:yes'> </span></td>
 <td colspan=24 style='mso-ignore:colspan'></td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td height=17 colspan=4 style='height:12.75pt;mso-ignore:colspan'></td>
 <td colspan=15 style='mso-ignore:colspan'>Ngoài ra còn ký hi&#7879;u ghép
 c&#7911;a các kí hi&#7879;u &#273;&#417;n. Ví d&#7909;: X+ là ch&#7845;m công
 &#273;úng gi&#7901; và có t&#259;ng ca</td>
 <td colspan=34 style='mso-ignore:colspan'></td>
</tr>
</table>
<!--
<hr>
reportingCycle:{{reportingCycle}}<br>
numDateOfMonth:{{dateOfMonth.numDateOfMonth}}<br>
dateOfMonth.firstDayOfWeek:{{dateOfMonth.firstDayOfWeek}}
-->
