<hr>
<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
<div class="container bootstrap snippet">
    <div class="row">
        <div class="col-sm-12"><h1>{{userData['username']}} </h1>
          <p class="text-danger">UserID: {{userData['id']}} </p></div>        
  </div>
    <div class="row">
        
  		    <div class="col-sm-4"><!--left col-->
              
                <div class="form-group" [ngClass]="{ 'has-danger': invalidAvatar() }">
                        <label for="avatar" class="form-control-label">Click to avatar to change</label>
                        <p *ngIf="invalidAvatar()"  id="avatar_error" class="invalid-feedback">Upload avatar please.</p>
                        <app-profile-picture [(url)]=picurl [editmode]=editEnabled 
                                    [oldUserid]=userId
                                    [smallsize]=smallsize [up4RegisterMode]=up4RegisterEnabled id="avatar" (avatarIdChanged)="onAvatarIdChanged($event)" ></app-profile-picture>
                </div> 

               
                <div class="panel panel-default">
                    <div class="panel-heading">Website <i class="fa fa-link fa-1x"></i></div>
                    <div class="panel-body"><a href="https://www.hoanglongclinic.vn">www.hoanglongclinic.vn</a></div>
                </div>
          
          
                <ul class="list-group">
                    <li class="list-group-item text-muted">Activity <i class="fa fa-dashboard fa-1x"></i></li>
                    <li class="list-group-item text-right"><span class="pull-left"><strong>Check in</strong></span> 125</li>
                    <li class="list-group-item text-right"><span class="pull-left"><strong>Task</strong></span> 13</li>            
                </ul> 
               
                <div class="panel panel-default">
                    <div class="panel-heading">Social Media</div>
                    <div class="panel-body">
                        <i class="fa fa-facebook fa-2x"></i> <i class="fa fa-github fa-2x"></i> <i class="fa fa-twitter fa-2x"></i> <i class="fa fa-pinterest fa-2x"></i> <i class="fa fa-google-plus fa-2x"></i>
                    </div>
                </div>          
            </div><!--/col-4-->
    	    <div class="col-sm-8">                
                <hr>  
                    <div class="form-group" [ngClass]="{ 'has-danger': invalidOrgId() }"> <!-- Org ID -->
                        <label for="org_id" class="control-label">Tên công ty</label>
                        <input type="text" formControlName="org_id" class="form-control" [ngClass]="{ 'is-invalid': invalidOrgId() }" id="org_id" name="org_id" placeholder="Company Name Id" readonly >
                        <p *ngIf="invalidOrgId()" id="org_id_error" class="invalid-feedback">Invalid Organization ID.</p>
                    </div>

                    <div class="form-group" [ngClass]="{ 'has-danger': invalidUserName() }"> <!-- Userr Name -->
                        <label for="username" class="form-control-label">User Name *</label>
                        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': invalidUserName() }" id="username" name="username" placeholder="Tên truy nhập">
                        <p *ngIf="invalidUserName()" id="first_name_error" class="invalid-feedback">Invalid user name.</p>
                    </div>

                    <!--
        
                    <div class="form-group" [ngClass]="{ 'has-danger': invalidFirstName() }"> 
                          <label for="first_name" class="form-control-label">First Name *</label>
                          <input type="text" formControlName="first_name" class="form-control" [ngClass]="{ 'is-invalid': invalidFirstName() }" id="first_name" name="first_name" placeholder="Tên">
                          <p *ngIf="invalidFirstName()" id="first_name_error" class="invalid-feedback">Invalid first name.</p>
                      </div>
                  
                      <div class="form-group" [ngClass]="{ 'has-danger': invalidLastName() }"> 
                          <label for="last_name" class="form-control-label">Last Name *</label>
                          <input type="text" formControlName="last_name" class="form-control" [ngClass]="{ 'is-invalid': invalidLastName() }" id="last_name" name="last_name" placeholder="Họ và tên đệm">
                          <p *ngIf="invalidLastName()" id="last_name_error" class="invalid-feedback">Invalid last name.</p>
                      </div>        
                    -->

                      <div class="form-group" [ngClass]="{ 'has-danger': invalidTelephone() }"> <!-- Telephone -->
                        <label for="telephone" class="form-control-label">Telephone *</label>
                        <input type="text" formControlName="telephone" class="form-control" [ngClass]="{ 'is-invalid': invalidTelephone() }" id="telephone" name="telephone" placeholder="0987654321">
                        <p *ngIf="invalidTelephone()" id="telephone_error" class="invalid-feedback">Invalid telephone.</p>
                    </div>
                  
                      <div class="form-group" [ngClass]="{ 'has-danger': invalidEmail() }"> <!-- Email -->
                          <label for="email" class="form-control-label">E-mail</label>
                          <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': invalidEmail() }" id="email" name="email" placeholder="your@email.com">
                          <p *ngIf="invalidEmail()" id="email_error" class="invalid-feedback">Invalid email address.</p>
                      </div>

                      <div class="form-group" [ngClass]="{ 'has-danger':   invalidRoles() }"> 
                        <label for="webAppRoles" class="control-label">Chọn quyền người dùng:</label>
                        <select class="custom-select" formControlName="webAppRoles" id="webAppRoles" name="webAppRoles" placeholder="Chọn quyền của người dùng">
                            <option value="sysadmin">sysadmin</option>
                            <option value="supporter">supporter</option>
                            <option value="editor">editor</option>  
                         </select>
                        <p *ngIf="invalidRoles()" id="orgListCmb_error" class="invalid-feedback">Invalid User Roles.</p>
                    </div>


                    <div class="form-group" [ngClass]="{ 'has-danger': invalidPassword() }"> 
                        <label for="password" class="form-control-label">Mật khẩu</label>
                        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': invalidPassword() }" id="password" name="password" placeholder="Nhập mật khẩu">
                        <p *ngIf="invalidPassword()" id="passwd_error" class="invalid-feedback">Invalid password.</p>
                    </div>

                    <div class="form-group" [ngClass]="{ 'has-danger': invalidRePassword() }"> 
                        <label for="repassword" class="form-control-label">Nhập lại mật khẩu</label>
                        <input type="password" formControlName="repassword" class="form-control" [ngClass]="{ 'is-invalid': invalidRePassword() }" id="repassword" name="repassword" placeholder="Nhập lại mật khẩu">
                        <p *ngIf="invalidRePassword()" id="email_error" class="invalid-feedback">Invalid password</p>
                    </div>


                    <div class="form-group has-error">
                        <div class="col-xs-12">
                            <br>
                            <button class="btn btn-lg btn-success" type="submit"><i class="glyphicon glyphicon-ok-sign"></i> Save</button>
                            <button class="btn btn-lg" type="reset"><i class="glyphicon glyphicon-repeat"></i> Reset</button>
                        </div>
                    </div>
     
               
              </div>
          

        
        </div>
    </div>
</form>
    