<div class="container-md">
    <h4 class="text-primary">Organization Management</h4>
    For company: {{rootOrganData.org_name}}
    <mat-tab-group>
        <mat-tab label="Organization List">
          <ng-template matTabContent>
            <!-------------------->
           
    <p data-placement="top" data-toggle="tooltip" title="New Sub Org">
      <button class="btn btn-success btn-xs" 
        data-title="New Sub Org" (click)="clickNewSubOrg(0)" data-toggle="modal" data-target="#newsuborg" >
        <i class="fa fa-plus"></i> New</button></p>

            <table mat-table
       [dataSource]="dataSource" multiTemplateDataRows
       class="mat-elevation-z8">
  <!--
       <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
        <th mat-header-cell *matHeaderCellDef> {{column}} </th>
        <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
      </ng-container>
--> 
  
  <ng-container matColumnDef="org_id">
    <th mat-header-cell *matHeaderCellDef> ID </th>
    <td mat-cell *matCellDef="let element" > {{element.org_id}} </td>
  </ng-container>
  <ng-container matColumnDef="org_parent_id">
    <th mat-header-cell *matHeaderCellDef> Parent Id </th>
    <td mat-cell *matCellDef="let element"> {{element.org_parent_id}} </td>
  </ng-container>
  <ng-container matColumnDef="org_name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.org_name}} </td>
  </ng-container>
  <ng-container matColumnDef="short_name">
    <th mat-header-cell *matHeaderCellDef> Short Name </th>
    <td mat-cell *matCellDef="let element"> {{element.short_name}} </td>
  </ng-container>
  <ng-container matColumnDef="org_desc">
    <th mat-header-cell *matHeaderCellDef> Description </th>
    <td mat-cell *matCellDef="let element"> {{element.org_desc}} </td>
  </ng-container>
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef> Edit </th>
    <td mat-cell *matCellDef="let element"> 
      <p data-placement="top" data-toggle="tooltip" title="Edit">
        <button class="btn btn-primary btn-xs" 
          data-title="Edit" (click)="clickEditMethod(element.org_id)"  data-toggle="modal" data-target="#edit" ><i class="fa fa-pencil-square-o"></i></button></p>  
     </td>
  </ng-container>
  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef> Delete </th>
    <td mat-cell *matCellDef="let element"> 
      <p data-placement="top" data-toggle="tooltip" title="Delete">
        <button class="btn btn-danger btn-xs" 
          data-title="Delete" (click)="clickDeleteMethod(element.org_id)"   data-toggle="modal" data-target="#delete" >
          <i class="fa fa-trash"></i></button></p>
      
      </td>
  </ng-container>


  

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
      <div class="example-element-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

      <div class="container-md">
      <div class="row">
        <div class="col-xs-6 col-md-4">org ID:</div><div class="col-xs-6 col-md-8">{{element.org_id}}</div>
      </div>          
      <div class="row">
        <div class="col-xs-6 col-md-4">org_name:</div><div class="col-xs-6 col-md-8">{{element.org_name}}</div>
      </div>  
      <div class="row">
        <div class="col-xs-6 col-md-4">Description:</div><div class="col-xs-6 col-md-8">{{element.org_desc}}</div>
      </div>  
      <div class="row">
        <div class="col-xs-6 col-md-4">short_name:</div><div class="col-xs-6 col-md-8">{{element.short_name}}</div>
      </div>  
      <div class="row">
        <div class="col-xs-6 col-md-4">contact_point:</div><div class="col-xs-6 col-md-8">{{element.contact_point}}</div>
      </div>  
      <div class="row">
        <div class="col-xs-6 col-md-4">address:</div><div class="col-xs-6 col-md-8">{{element.address}}</div>
      </div>   
      <div class="row">
        <div class="col-xs-6 col-md-4">pos_idx:</div><div class="col-xs-6 col-md-8">{{element.pos_idx}}</div>
      </div>
      <div class="row">
        <div class="col-xs-6 col-md-4">created_on:</div><div class="col-xs-6 col-md-8">{{element.created_on}}</div>
      </div>

      </div>
      
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>

              

              <!-------------------->
          </ng-template>
        </mat-tab>
        <mat-tab label="Members">
          <ng-template matTabContent>
              <!-------------------->
            The Second Content
            <!-------------------->
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      
</div>