<form [formGroup]="userForm" >
<mat-tab-group mat-align-tabs="start">
  <mat-tab label="Trạng thái phác đồ">
    <strong>Danh sách phác đồ bệnh nhân đang có:</strong>
       <div *ngIf="currentRegimen">
      
      
        <div class="form-group" [ngClass]="{ 'has-danger':   invalidRegimen() }"> 
          <label for="selectRegimen" class="control-label">( Phác đồ đầu tiên là phác đồ đang hoạt động ):</label>
          <select class="custom-select" (change)="onChangeRegimen($event)" 
                formControlName="selectRegimen" id="selectRegimen" name="selectRegimen" placeholder="Chọn phác đồ">
              
              <option *ngFor="let regim of orginData" [ngValue]="regim.patient_regimen_id">
                Phác đồ  {{ regim.regimen_id }} - ID:
              {{ regimenName }} ({{ regim.created_date }})
              </option>
           </select>
          <p *ngIf="invalidRegimen()" id="orgListCmb_error" class="invalid-feedback">Phac do khong hop le.</p>
        </div>
      <hr />

      <div *ngIf="currentRegimen.regimen_id==0"  style="color: red;">
        <p>Bệnh nhân chưa được gán phác đồ </p>
        Trạng thái hiện tại : {{ currentRegimen.state }}<br />
      </div>
      <div *ngIf="currentRegimen.regimen_id!=0">
        ID = {{ currentRegimen.patient_regimen_id }}<br />
        ID Bệnh nhân : {{ currentRegimen.patient_id }}<br />
        Phác đồ  uống thuốc : {{ currentRegimen.regimen_id }} / {{regimenName}}<br />
        Trạng thái : {{ currentRegimen.state }}<br />
        Mã kích hoạt : {{ currentRegimen.active_code }}<br />
        Bước thực hiện hiện tại : {{ currentRegimen.current_step }}<br />
        Ngày hiệu lực của phác đồ : {{ currentRegimen.eff_date }}<br />
        Ngày hết hạn phác đồ : {{ currentRegimen.exp_date }}<br />
        Ngày khởi tạo phác đồ: {{ currentRegimen.created_date }}<br />
        Ngày cập nhật sửa đổi: {{ currentRegimen.updated_date }}<br />
        Nơi thực hiện phác đồ : {{ currentRegimen.regimen_where }}<br />
    </div>

      <hr />
      <div >
        <div class="form-group" [ngClass]="{ 'has-danger': invalidActiveCode() }"> 
          <label for="active_code" class="form-control-label">Đổi mã kích hoạt </label>
          <input type="text" formControlName="active_code" class="form-control" [ngClass]="{ 'is-invalid': invalidActiveCode() }" 
          id="active_code" name="active_code" placeholder="Nhập mã kích hoạt">   
        </div>
        
        <button class="btn btn-warning" (click)="onChangeActiveCode(1)">
          <i class="fa fa-check"></i>Đổi mã kích hoạt
        </button>
      </div>
      <div>
        <div class="form-group" [ngClass]="{ 'has-danger':   invalidState() }"> 
          <label for="selectState" class="control-label">Thay đổi trạng thái :</label>
          <select class="custom-select" (change)="changeState($event)" formControlName="selectState" id="selectState" name="selectState" placeholder="Chọn trạng thái mới">
              <option *ngFor="let state of regimen_states" [ngValue]="state.value">
                {{ state.value }} - {{ state.strDesc }} / ( {{ state.strText }} )
              </option>
           </select>
          <p *ngIf="invalidState()" id="orgListCmb_error" class="invalid-feedback">Invalid state.</p>
        </div>
        <div style="color: red;">{{ stateDesc }}</div>
        
        <button class="btn btn-warning" (click)="onUpdatePatientRegimenState()">
          <i class="fa fa-check"></i>Cập nhật trạng thái
        </button>
        &nbsp;
        
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Câu hỏi sàng lọc">
    <mat-card *ngFor="let quest of quest_list">
      <mat-card-header>
        <strong> {{ quest.quest }}</strong></mat-card-header
      >
      <mat-card-content>
        <li *ngFor="let subquest of quest.ids">
          <div>
            <div class="ib">{{ subquest.quest }} : </div>
            <div class="ib" style="color: blue;" *ngIf="!subquest.answer"><i class="fa fa-close text-success"></i> {{ subquest.answer }}</div>
            <div class="ib" style="color: red;" *ngIf="subquest.answer"><i class="fa fa-close text-danger"></i> {{ subquest.answer }}</div>
          
          </div>
        </li>
      </mat-card-content>
    </mat-card>
  </mat-tab>

  <mat-tab label="Thực hiện phác đồ">
    <div *ngIf="currentRegimen">
      <h6>
        Uống thuốc tại :{{
          currentRegimen.regimen_where === "clinic" ? "Phòng khám" : "Nhà"
        }}
      </h6>
    </div>
    <mat-card
      *ngFor="
        let progress_timing of regimen_progress_timing;
        let indexOfelement = index
      "
    >
      <mat-card-header>
        <div>
          <strong
            >Bước:{{ indexOfelement + 1 }} {{ progress_timing.name }}  </strong
          >
          <i *ngIf="progress_timing.processing" [ngClass]="'processing'">
            Đang thực hiện
          </i>
        </div>
      </mat-card-header>
      <div>
        Thời gian bắt đầu:{{
          progress_timing.startTime | date: "yyyy-MM-dd HH:mm:ss"
        }}
      </div>
      <div>
        Thời gian kết thúc:{{
          progress_timing.endTime | date: "yyyy-MM-dd HH:mm:ss"
        }}
      </div>
    </mat-card>

    Thong tin chi tiet:
    {{orginData | json}}
  </mat-tab>
  <mat-tab label="Lịch sử khám">Các lần đến khám</mat-tab>
  
</mat-tab-group>
</form>
<hr>
