<h4 class="text-primary">Theo dõi bệnh nhân</h4>
<div class="container-fluid">
  <div class="row">
    <div class="col-8">
      
      <div class="container-fluid">
        <div class="row">
          <div class="col-8">
            <mat-form-field>
              <!-- mat-label>Lọc tìm kiếm</mat-label -->
              <input matInput (keyup)="applyFilter($event)" placeholder="Lọc theo">
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field>
              <!-- mat-label>Lọc tìm kiếm</mat-label -->
              <input matInput  placeholder="Người hỗ trợ hiện tại" [(ngModel)]="newSupporter" (focusout)="onNewSupporterFocusOutEvent($event)" >
            </mat-form-field>
          </div>
          
        </div>
        <div class="row" *ngIf="!filterMore">
          
          <i class="fa fa-angle-double-down" (click)="filterMore=!filterMore" *ngIf="!filterMore" title="Mở rộng tìm kiếm"></i>
        </div>

      </div>
     
      <mat-card-content style="justify-content: center" *ngIf="filterMore">
        <table>
          <tr>
            <td class="tab_filter_left"><i class="fa fa-angle-double-up" (click)="filterMore=!filterMore" *ngIf="filterMore" title="Ẩn tìm kiếm"></i></td>
            <td class="tab_filter"><Strong>Ngày</Strong></td>
            <td class="tab_filter_last"><strong>Các trạng thái</strong></td>
            <td class="tab_filter_last"><strong>Địa điểm</strong></td>
          </tr>
          <tr>
            <td class="tab_filter_left">&nbsp;</td>
            <td class="tab_filter">
              <mat-checkbox
                  class="margin: 0 10px;" (change)="onClickChkToday($event)"
                  [(ngModel)]="chkToday" >Hôm nay
              </mat-checkbox>
              &nbsp;
              <mat-checkbox
                  class="margin: 0 10px;" (change)="onClickChkOtherday($event)"
                  [(ngModel)]="chkOtherDay" >Ngày khác
              </mat-checkbox>
            </td>
            <td class="tab_filter_last">
              <mat-checkbox
                  class="margin: 0 10px;" (change)="onClickChkAll()"
                    [(ngModel)]="chkAll" >Tất cả
              </mat-checkbox>
              &nbsp;
              <mat-checkbox
                  class="margin: 0 10px;" (change)="onClickChkStates()"
                    [(ngModel)]="chkSL" >Sàng lọc
              </mat-checkbox>
              &nbsp;
              <mat-checkbox
                  class="margin: 0 10px;" (change)="onClickChkStates()"
                    [(ngModel)]="chkUT" >Uống thuốc
              </mat-checkbox>
              &nbsp;
              <mat-checkbox
                  class="margin: 0 10px;" (change)="onClickChkStates()"
                    [(ngModel)]="chkDG" >Đánh giá
              </mat-checkbox>
              &nbsp;
              <mat-checkbox 
                  class="margin: 0 10px;" (change)="onClickChkStates()"
                    [(ngModel)]="chkCS" >Chờ soi
              </mat-checkbox>
            </td>
            <td class="tab_filter_last">
                &nbsp;
                <mat-checkbox
                    class="margin: 0 10px;" (change)="onClickChkLocPK()"
                      [(ngModel)]="chkPK" >P.K
                </mat-checkbox>
                &nbsp;
                <mat-checkbox 
                    class="margin: 0 10px;" (change)="onClickChkLocNha()"
                      [(ngModel)]="chkNha" >Nhà
                </mat-checkbox>
            </td>
          </tr>
        </table>
      </mat-card-content>     
    </div>
    <div class="col-4">
      <table>
        <tr>
          <th class="tab_summary">Sàng lọc</th>
          <th class="tab_summary">Uống thuốc</th>
          <th class="tab_summary">Đánh giá sạch</th>
          <th class="tab_summary_last">Chờ soi</th>
        </tr>
        <tr>
          <td class="tab_summary"><strong>{{sum_sangloc}}</strong></td>
          <td class="tab_summary"><strong>{{sum_uongthuoc}}</strong></td>
          <td class="tab_summary"><strong>{{sum_danhgia}}</strong></td>
          <td class="tab_summary_last"><strong>{{sum_chosoi}}</strong></td>
        </tr>        
      </table>
      <div style="display: flex; justify-content: flex-end">
        <mat-checkbox 
                  class="margin: 0 10px;" (change)="onClickCountAll()"
                    [(ngModel)]="chkCountAll" >Thống kê toàn bộ
        </mat-checkbox>
      </div>
      &nbsp;
    </div>
  </div>
  <div class="row">
    <table mat-table [dataSource]="dataSource" class="table table-bordred table-striped" matSort multiTemplateDataRows (matSortChange)="sortData($event)">
      <!-- Position Column -->
      <ng-container matColumnDef="idx">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="idx">STT</th>
        <td mat-cell *matCellDef="let element" class="align-middle" [ngStyle]="{'color': element.otherfield_color}">
          {{element.idx}} 
        </td>
      </ng-container>
      <ng-container matColumnDef="userinfo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="user_name">Thông tin cá nhân</th>
        <td mat-cell *matCellDef="let element" class="align-middle" [ngStyle]="{'color': element.name_color}" title="{{element.tooltip}}">
          {{element.user_name}}
          <br>({{element.gioitinh}}, {{element.date_of_birth}})</td>
      </ng-container>
      <ng-container matColumnDef="telephone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="telephone">Số điện thoại</th>
	<td mat-cell *matCellDef="let element" class="align-middle" [ngStyle]="{'color': element.otherfield_color}"  title="{{element.tooltip}}">
          {{element.telephone}}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Giai đoạn/Trạng thái</th>
        <td mat-cell *matCellDef="let element" class="align-middle">
          <p style="font-size: 17px; font-weight: bold; margin-bottom: 0px;"
            [ngStyle]="{'color': element.otherfield_color}">{{element.phase_str}}</p>
          <div [ngStyle]="{'color': element.color}" title="{{element.tooltip}}">{{element.state_str}}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="patient_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="patient_id">Mã ID</th>
        <td mat-cell *matCellDef="let element" class="align-middle">{{element.patient_id}}</td>
      </ng-container>
      <ng-container matColumnDef="phongkham">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="!isSysAdmin">Khám tại</th>
        <td mat-cell *matCellDef="let element" class="align-middle" style="text-align: center"
          [ngStyle]="{'color': element.otherfield_color}" [hidden]="!isSysAdmin">{{element.phongkham}} </td>
      </ng-container>
      <!--ng-container matColumnDef="regimen_where">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Uống thuốc tại</th>
        <td mat-cell *matCellDef="let element" class="align-middle"  [ngStyle]="{'color': element.otherfield_color}">
          <select class="custom-select" (change)="onChangeLocation(element,$event)" style="width: auto;max-width: 100px;" 
              disabled="{{element.otherfield_color=='gray'}}"
                 id="selectLocation" name="selectLocation" placeholder="Chọn địa điểm" value="{{element.regimen_where}}"
                  [(ngModel)]="element.regimen_where " title="{{element.regimen_where}}">
              <option [ngValue]="0">Chọn địa điểm</option>
              <option value='clinic'>Tại PK</option>
              <option value='home'>Tại nhà</option>
          </select>
        </td>
      </ng-container -->
      <ng-container matColumnDef="regimen_where">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="regimen_where_show">Uống tại</th>
        <td mat-cell *matCellDef="let element" class="align-middle" style="text-align: center"
          [ngStyle]="{'color': element.otherfield_color}">{{element.regimen_where_show}}</td>
      </ng-container>
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="state">Trạng thái phác đồ</th>
        <td mat-cell *matCellDef="let element" class="align-middle">{{this.getStateName(element.state)}}
        </td>
      </ng-container>
      <!-- "lastest_patient_regimen_id",
    "regimen_id",
    "active_code", -->
      <ng-container matColumnDef="lastest_patient_regimen_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã điều trị</th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'color': element.otherfield_color}">
          {{element.lastest_patient_regimen_id}}
        </td>
      </ng-container>
      <ng-container matColumnDef="regimen_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phác đồ</th>
        <td mat-cell *matCellDef="let element" class="align-middle" [ngStyle]="{'color': element.otherfield_color}">
          <select #mySelect class="custom-select" style="max-width: 150px; font-size: 14px;"
            (change)="onChangeRegimen(element,$event,mySelect.value)"
            disabled="{{element.otherfield_color=='gray' || (element.state!=1 && element.state!=2 && element.state!=11) || ( element.state==2 && element.active_code!=null)}}" id="selectRegimen"
            name="selectRegimen" placeholder="Chọn phác đồ" [ngModel]="element.regimen_id"
            title="{{element.regimen_id}}">
            <option [ngValue]="0">[Chưa có phác đồ]</option>
            <option *ngFor="let regimen of regimenList" [ngValue]="regimen.id">{{regimen.shortname}}</option>
          </select>
        </td>
      </ng-container>
      <ng-container matColumnDef="doctor_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bác sỹ chỉ định</th>
        <td mat-cell *matCellDef="let element" class="align-middle" [ngStyle]="{'color': element.otherfield_color}">
          <select class="custom-select" (change)="onChangeDoctor(element,$event)"
            style="width: auto; max-width: 120px; font-size: 14px;"
            disabled="{{element.otherfield_color=='gray' || (element.state!=1 && element.state!=2 && element.state!=11) || ( element.state==2 && element.active_code!=null)}}" id="selectDoctor"
            name="selectDoctor" placeholder="Chọn bác sỹ" value="{{element.doctor_name}}"
            [(ngModel)]="element.doctor_name" title="{{element.doctor_name}}">
            <option [ngValue]="0">Chọn bác sỹ</option>
            <option *ngFor="let doctor of doctorList" [ngValue]="doctor.name">{{doctor.name}}</option>
          </select>
        </td>
      </ng-container>
      <ng-container matColumnDef="starttime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="start_time">T.gian bắt đầu</th>
        <td mat-cell *matCellDef="let element" class="align-middle" style="text-align: center"
          [ngStyle]="{'color': element.otherfield_color}">
          {{element.start_time}}
        </td>
      </ng-container>
      <ng-container matColumnDef="updatedtime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="updated_date_str">T.gian cập nhật</th>
        <td mat-cell *matCellDef="let element" class="align-middle" style="text-align: center"
          [ngStyle]="{'color': element.otherfield_color}">
          {{element.updated_date_str}}
        </td>
      </ng-container>
      <ng-container matColumnDef="supporter">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hỗ trợ</th>
        <td mat-cell *matCellDef="let element" class="align-middle" [ngStyle]="{'color': element.otherfield_color}">
          {{element.supporter}}          
        </td>
      </ng-container>
      <ng-container matColumnDef="active_code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã kích hoạt</th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'color': element.otherfield_color}">
          {{element.active_code}}
        </td>
      </ng-container>
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef>Active</th>
        <td mat-cell *matCellDef="let element" class="align-middle">
          <div *ngIf="element.b1">
            <!-- a routerLink="/regimen-sel/{{element.patient_id}}" -->
            <button class="btn btn-info btn-xs" matTooltip="Kích hoạt phác đồ" (click)="onClickActive(element)"
              data-title="Edit" data-toggle="modal" data-target="#active">
              <i class="fa fa-power-off"></i></button>
            <!-- /a -->
          </div>
          <div *ngIf="!element.b1">
            <button class="btn btn-light btn-xs" matTooltip="Kích hoạt phác đồ" data-title="Edit" data-toggle="modal"
              data-target="#active" disabled>
              <i class="fa fa-power-off"></i></button>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="pause">
        <th mat-header-cell *matHeaderCellDef>Pause</th>
        <td mat-cell *matCellDef="let element" class="align-middle">
          <div *ngIf="element.b2">
            <button class="btn btn-warning btn-xs" matTooltip="Tạm dừng" data-title="Edit"
              (click)="onClickPause(element)" data-toggle="modal" data-target="#pause">
              <i class="fa fa-pause"></i></button>
          </div>
          <!-- div *ngIf="!element.b2">
            <button class="btn btn-light btn-xs" matTooltip="Tạm dừng"
              data-title="Edit" (click)="onClickPause(element)"  data-toggle="modal" data-target="#pause" disabled>
              <i class="fa fa-pause"></i></button>
          </div -->
          <div *ngIf="element.b3">
            <button class="btn btn-success btn-xs" matTooltip="Tiếp tục" data-title="Edit"
              (click)="onClickContinue(element)" data-toggle="modal" data-target="#continue">
              <i class="fa fa-play"></i></button>
          </div>
          <div *ngIf="!element.b3 && !element.b2">
            <button class="btn btn-light btn-xs" matTooltip="Tiếp tục" data-title="Edit"
              (click)="onClickContinue(element)" data-toggle="modal" data-target="#continue" disabled>
              <i class="fa fa-play"></i></button>
          </div>

        </td>
      </ng-container>

      <ng-container matColumnDef="continue">
        <th mat-header-cell *matHeaderCellDef>Contin</th>
        <td mat-cell *matCellDef="let element" class="align-middle">

        </td>
      </ng-container>

      <ng-container matColumnDef="block">
        <th mat-header-cell *matHeaderCellDef>Block</th>
        <td mat-cell *matCellDef="let element" class="align-middle">
          <div *ngIf="element.b4">
            <button class="btn btn-danger btn-xs" matTooltip="Khóa" data-title="Edit" (click)="onClickBlock(element)"
              data-toggle="modal" data-target="#block">
              <i class="fa fa-lock"></i></button>
          </div>
          <div *ngIf="!element.b4">
            <button class="btn btn-light btn-xs" matTooltip="Khóa" data-title="Edit" (click)="onClickBlock(element)"
              data-toggle="modal" data-target="#block" disabled>
              <i class="fa fa-lock"></i></button>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="reset">
        <th mat-header-cell *matHeaderCellDef>Reset</th>
        <td mat-cell *matCellDef="let element" class="align-middle">
          <div *ngIf="element.b5">
            <button class="btn btn-primary btn-xs" matTooltip="Khởi động lại" data-title="Edit"
              (click)="onClickReset(element)" data-toggle="modal" data-target="#reset">
              <i class="fa fa-refresh"></i></button>
          </div>
          <div *ngIf="!element.b5">
            <button class="btn btn-light btn-xs" matTooltip="Khởi động lại" data-title="Edit"
              (click)="onClickReset(element)" data-toggle="modal" data-target="#reset" disabled>
              <i class="fa fa-refresh"></i></button>
          </div>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element">
      </tr>

    </table>

    <mat-paginator [pageSizeOptions]="[30,60,100]" showFirstLastButtons></mat-paginator>

  </div>
</div>


<!-- {{patientList | json}} -->
<!-- {{currentUser | json}} -->
