import { Component, OnInit } from "@angular/core";
import { UserProfileService } from "../user-profile.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-regimen-select",
  templateUrl: "./regimen-select.component.html",
  styleUrls: ["./regimen-select.component.scss"],
})
export class RegimenSelectComponent implements OnInit {
  regimenList: any;
  patientId = "0";
  userForm: FormGroup;
  selectedValue = 0;

  addNew = false;

  orginData = [];
  currentRegimen : any = null;
  currentRegimenIdx = 0;
  countOfRegimen = 0;
  selPatientRegimenId = 0; // ID cua Patient_regimen

  defPatientRegimen = {
    patient_id: 0,
    regimen_id: 0,
    state: 1,
    active_code: 1,
    current_step: 0,
    eff_date: null,
    exp_date: null,
    created_date: new Date(),
    updated_date: new Date(),
    org_regimen: null,
    step_timing: null,
    start_time: null,
    other_data: null,
    supporter_id: null,
    regimen_where: null,
  };

  constructor(
    private userProfileService: UserProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      regmenId: new FormControl(),
    });

    this.patientId = this.route.snapshot.paramMap.get("patientId");
    console.log("patientId:" + this.patientId);
    this.getRegimen();
  }

  getPatientRegimenByPatientId(patientId): void {

    

    console.log("Call getPatientRegimenByPatientId");
    this.userProfileService
      .getPatientRegimenByPatientId(patientId)
      .subscribe({next:(data) => {

        console.log(JSON.stringify(data));
        if (data.length>0) {
          this.orginData = data;
          this.countOfRegimen = data.length;
          this.currentRegimenIdx = 0;
          this.currentRegimen = data[this.currentRegimenIdx];
          this.selectedValue = this.currentRegimen["regimen_id"];
          this.addNew = false;
        } else {
          // Tao moi 
          this.defPatientRegimen.patient_id = patientId;
          this.addNew = true;

          this.currentRegimen = this.defPatientRegimen;

        }
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});
  }

  onChangeRegimen(event) {
    this.regimenList.every((regim, index) => {
      if (regim.regimen_id === event.value) {
        this.selectedValue = regim.regimen_id;
        return false;
      } else {
        return true;
      }
    });
  }

  onGoBack() {
    this._location.back();
  }

  onSubmit() {
    this.onSubmitRegimen();
    this._location.back();
  }

  onSubmitRegimen() {
    //requery before subbmit, because maybe create in mobile app
    console.log("Call onSubmitRegimen for "+this.patientId);
    this.userProfileService
      .getPatientRegimenByPatientId(this.patientId)
      .subscribe({next:(data) => {

        console.log(JSON.stringify(data));
        if (data.length>0) {
          this.orginData = data;
          this.countOfRegimen = data.length;
          this.currentRegimenIdx = 0;
          this.currentRegimen = data[this.currentRegimenIdx];
          // this.selectedValue = this.currentRegimen["regimen_id"];

          //update regimen
          let patient_regimen_id = this.currentRegimen["patient_regimen_id"];
          if (
            confirm("Are you sure to change regimen for " + patient_regimen_id + "? ")
          ) {
              this.userProfileService
                .updateRegimenOfPatientRegimen(patient_regimen_id, this.selectedValue)
                .subscribe((data) => {
                  //this.userName = data.username;
                  alert("Đổi phác đồ thành công!");
                  this.currentRegimen["regimen_id"] = this.selectedValue;
                });
            }
          //end
        } else {
          // Not allow create, only create on app after filter 
          alert('Không thể cập nhật phác đồ, vui lòng thực hiện sàng lọc trước');
        }
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});
    
    // if (this.addNew) {
        
    //     this.defPatientRegimen.regimen_id  = this.selectedValue;

    //     this.userProfileService
    //     .createPatientRegimenByPatientId(this.patientId,this.defPatientRegimen)
    //     .subscribe((data) => {
    //         alert("Gán phác đồ thành công!");
    //         this.currentRegimen["regimen_id"] = this.selectedValue;
    //     });
    // } else {

    //     let patient_regimen_id = this.currentRegimen["patient_regimen_id"];
    //     if (
    //       confirm("Are you sure to change regimen for " + patient_regimen_id + "? ")
    //     ) {
    //         this.userProfileService
    //           .updateRegimenOfPatientRegimen(patient_regimen_id, this.selectedValue)
    //           .subscribe((data) => {
    //             //this.userName = data.username;
    //             alert("Đổi phác đồ thành công!");
    //             this.currentRegimen["regimen_id"] = this.selectedValue;
    //           });
    //       }
    // }

    // console.log("onChange regimen_id:" + this.selectedValue);
    
  }

  getRegimen(): void {
    this.userProfileService.getRegimenList("").subscribe((data) => {
      this.regimenList = data;
      this.getPatientRegimenByPatientId(this.patientId);
    });
  }
}
