import { Component, OnInit } from '@angular/core';
import {UserProfileService} from '../user-profile.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import {Location} from '@angular/common';

@Component({
  selector: 'app-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.scss']
})
export class PatientSearchComponent implements OnInit {

  submitted = false;

  patientId = null;
  message : string;
  telephone : string = null;

  userForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,  
    private userProfileService: UserProfileService,
    private _location: Location,
    private router: Router
  ) { }

  ngOnInit() {

    this.telephone = this.route.snapshot.paramMap.get('telephone');


    this.userForm = this.formBuilder.group({      
      msisdn: [this.telephone, Validators.required],      
    });

    if (this.telephone) {
      this.onSubmit();
    }
  }

  invalidTelephone()
  {
    return (this.submitted && this.userForm.controls.msisdn.errors != null) ;
  }

  onSubmit() {
    this.userProfileService.getPatientByTelephone(this.userForm.controls.msisdn.value)
      .subscribe({next:(data) => {
        console.log(JSON.stringify(data));

        if (data.length>=1) {
          this.patientId = data[0].patient_id;                            
          this.message = "";
        } else {
          this.patientId = null;
          this.message = "Not found!";
        }
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});
  }

}
