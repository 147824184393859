<div class="container-md">
    <h4 class="text-primary">Checkin Log Review</h4>

    <section class="check-section">
      Machine:
      <mat-checkbox class="check-margin" [(ngModel)]="mac_chk_all" (change)="chkChangeAll('mac')" >All</mat-checkbox>
      <mat-checkbox class="check-margin" [(ngModel)]="mac_chk_notcheck" (change)="chkChangeSingle('mac')" >Not Checked</mat-checkbox>
      <mat-checkbox class="check-margin" [(ngModel)]="mac_chk_ok" (change)="chkChangeSingle('mac')"  >Pass</mat-checkbox>
      <mat-checkbox class="check-margin" [(ngModel)]="mac_chk_phone" (change)="chkChangeSingle('mac')" >Phone</mat-checkbox>
      <mat-checkbox class="check-margin" [(ngModel)]="mac_chk_other" (change)="chkChangeSingle('mac')" >Other</mat-checkbox>
    </section>
      <section class="check-section">
        Manual : 
        <mat-checkbox class="check-margin" [(ngModel)]="man_chk_all" (change)="chkChangeAll('man')">All</mat-checkbox>
        <mat-checkbox class="check-margin" [(ngModel)]="man_chk_notcheck" (change)="chkChangeSingle('man')" >Not Checked</mat-checkbox>
      <mat-checkbox class="check-margin" [(ngModel)]="man_chk_ok"  (change)="chkChangeSingle('man')">Pass</mat-checkbox>
      <mat-checkbox class="check-margin" [(ngModel)]="man_chk_phone" (change)="chkChangeSingle('man')">Phone</mat-checkbox>
      <mat-checkbox class="check-margin" [(ngModel)]="man_chk_other" (change)="chkChangeSingle('man')">Other</mat-checkbox>
    </section>

    <form [formGroup]="userForm" >

     

     <div class="form-group" [ngClass]="{ 'has-danger':   invalidOrgId() }"> <!-- Org Type -->
        <label for="orgListCmb" class="control-label">Select Org ID:</label>
        <select class="custom-select" (change)="changeOrgId($event)" formControlName="orgListCmb" id="orgListCmb" name="orgListCmb" >
            <option value="0" >All</option>
            <option *ngFor="let org of orgList" [ngValue]="org.org_id">{{org.org_id}} - {{org.org_name}} </option>
         </select>
        <p *ngIf="invalidOrgId()" id="orgListCmb_error" class="invalid-feedback">Invalid User.</p>
    </div>
  
  </form>

  <div class="row">    
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" class="table table-bordred table-striped" matSort multiTemplateDataRows>

      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Order No. </th>
        <td mat-cell *matCellDef="let element"> {{element.position}} </td>
      </ng-container>

      <ng-container matColumnDef="org_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Org Id </th>
        <td mat-cell *matCellDef="let element"> {{element.org_id}} </td>
      </ng-container>

      <ng-container matColumnDef="review">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Manual </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.review_result.length==2">
            <i *ngIf="element.manual_check_ok || element.review_result.length==1" class="fa fa-check text-success"></i> 
            <i *ngIf="!element.manual_check_ok && element.review_result.length!=1" class="fa fa-bolt text-danger"></i>   
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="frauddet">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Machine </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.fraud || element.fraud==0">
            <i *ngIf="!element.fraud_type || element.fraud_type=='None'" class="fa fa-check text-success"></i> 
            <i *ngIf="element.fraud_type && element.fraud_type!='None'" class="fa fa-bolt text-danger"></i>   
            <font color="red" *ngIf="element.fraud_type!='None'">  {{element.fraud_type}} </font>
          </div>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="face">
        <th mat-header-cell *matHeaderCellDef > Face </th>
        <td mat-cell *matCellDef="let element">
          <!-- img src="/assets/avatar.png" alt="Smiley face" height="52" width="42" / --> 
          <img [defaultImage]="defaultImage" [lazyLoad]="'face_'+element.content_data.imgId" 
             [errorImage]="imageToShowOnError"  height="52" width="52"  
             (mouseenter) ="expandedElement = expandedElement === element ? null : element"
             class="shadow-none p-1 mb-0 bg-light rounded"> 
        </td>
      </ng-container>
      
       <ng-container matColumnDef="usr_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> User Id </th>
        <td mat-cell *matCellDef="let element"> {{element.usr_id}} </td>
      </ng-container>

       <!-- Name Column -->
       <ng-container matColumnDef="ext_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ext Id </th>
        <td mat-cell *matCellDef="let element"> {{element.ext_id}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
        <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
      </ng-container>
  
      <!-- Weight Column -->
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
        <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="telephone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Telephone </th>
        <td mat-cell *matCellDef="let element"> {{element.telephone}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>
      <ng-container matColumnDef="created_on">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Time </th>
        <td mat-cell *matCellDef="let element"> {{element.created_on}} </td>
      </ng-container>

      
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="8">
          <div class="example-element-detail"
               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">            
               
              <img *ngIf="element.content_data" [defaultImage]="defaultImage" 
              [lazyLoad]="element.img_url" 
              height="400" width="640" [errorImage]="imageToShowOnError"  class="shadow-lg p-3 mb-5 bg-white rounded">               
              &nbsp;
              <div *ngIf="element.content_data">
              DeviceId: {{element.content_data.deviceId}} &nbsp;
              MsgID:{{element.content_data.msgId}}

              <h4 class="check-h4">Machine Check</h4>
              <section class="check-section">
                <mat-checkbox class="check-margin" [(ngModel)]="element.machine_check_ok" >Pass</mat-checkbox>
                <mat-checkbox class="check-margin" [(ngModel)]="element.machine_check_phone">Phone</mat-checkbox>
                <mat-checkbox class="check-margin" [(ngModel)]="element.machine_check_other">Other</mat-checkbox>
              </section>
              <h4 class="check-h4">Manual Check</h4>
              <section class="check-section">
                <mat-checkbox class="check-margin" [(ngModel)]="element.manual_check_ok" (change)="chkChangeValue(element,'OK')">Pass</mat-checkbox>
                <mat-checkbox class="check-margin" [(ngModel)]="element.manual_check_phone" (change)="chkChangeValue(element,'PHONE')">Phone</mat-checkbox>
                <mat-checkbox class="check-margin" [(ngModel)]="element.manual_check_other" (change)="chkChangeValue(element,'OTHER')">Other</mat-checkbox>
              </section>
              <button type="submit" class="btn btn-primary" (click)="doSubmit($event, element)">
                <i class="fa fa fa-eye"></i> submit</button>  
                <br>
              {{element.ret_msg}}
            </div>
          </div>
        </td>
      </ng-container>

  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <!-- tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr -->

      <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    </table>
  
    <mat-paginator [pageSizeOptions]="[20,50,100]" showFirstLastButtons></mat-paginator>
  
</div>
</div>
