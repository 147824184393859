import { Component, OnInit , Inject} from '@angular/core';
import {UserProfileService} from '../user-profile.service';
import {Location} from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as myGlobal from '../globals'; 
import { CdkStepperNext } from '@angular/cdk/stepper';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  first_name: string;
  last_name: string;
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})

export class UserProfileComponent implements OnInit {

  img_data : string =null;
  orgData : object;
  userId : any;
  userData  = new UserClass();

  currentUser = null;

  debug_data : String;

  public up4RegisterEnabled = false;
  public editEnabled = true;
  public smallsize = true;
  public picurl: string;

  userForm: FormGroup;
  submitted = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private usrProfileService:UserProfileService,
    private formBuilder: FormBuilder,        
      public dialog: MatDialog
    ) { }

  ngOnInit() {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //this.userId = this.route.snapshot.paramMap.get('userId');
    //this.getUserAvatar(this.userId);

    console.log(JSON.stringify(this.currentUser));
    
    this.userForm = this.formBuilder.group({
      org_id: ['Hoang Long Clinic', Validators.required],
     
      username: [this.currentUser.user.username, Validators.required],
  		// first_name: ['', Validators.required],
  		// last_name: ['', Validators.required],
      //email: ['', [Validators.required, Validators.email]]      
      telephone: ['', Validators.required],
      email : [this.currentUser.user.email,null],
      webAppRoles: [{
        value: this.currentUser.user.webAppRoles,
        disabled: true
      }, Validators.required],
      password: ['', Validators.required],
      repassword: ['', Validators.required],
    });
  }

  modifyUserProfile(userId,profileData): void {
    this.usrProfileService.modifyUserProfile(userId,profileData)
        .subscribe({next:(result) => {          
          console.log('modifyUserProfile return ' + result);         
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }
        });
  }

  getUserAvatar(userId): void {
    // this.usrProfileService.getUserProfile(userId)
    //     .subscribe(data => {
    //       this.orgData = data;
    //       this.img_data = data['imgdata'];
    //       console.log('getUserAvatar return data');
    //       //console.log('getUserAvatar return'+ JSON.stringify(data));
    //       this.userData.first_name = this.orgData['firstName'];
    //       this.userData.last_name = this.orgData['lastName'];
    //       this.userData.telephone = this.orgData['telephone'];
    //       this.userData.email = this.orgData['email'];
    //       this.userData.ext_id = this.orgData['extId'];
         
    //       this.userForm.controls.org_id.setValue( this.orgData['orgId']);
    //       this.userForm.controls.first_name.setValue(  this.orgData['firstName']);
    //       this.userForm.controls.last_name.setValue(  this.orgData['lastName']);
    //       this.userForm.controls.telephone.setValue(  this.orgData['telephone']);
    //       this.userForm.controls.email.setValue(  this.orgData['email']);
    //       this.userForm.controls.ext_id.setValue(  this.orgData['extId']);
          
          
    //     });
  }

  //--------------------------
  onAvatarIdChanged(avatarId: number) {
   // this.userId = avatarId;
   
    
  }

  invalidAvatar()
  {
  	return ( this.userId==0);
  }

  invalidRoles ()
  {
    return false;
  }

  invalidUserName ()
  {
    return false;
  }

  invalidPassword ()
  {
    return false;
  }

  invalidRePassword ()
  {
    return false;
  }


  invalidOrgId()
  {
    return (this.submitted && this.userForm.controls.org_id.errors != null);
  }

  invalidExtId()
  {
    return (this.submitted && this.userForm.controls.ext_id.errors != null);
  }

  invalidFirstName()
  {
    return (this.submitted && this.userForm.controls.first_name.errors != null);
  }

  invalidLastName()
  {
  	return (this.submitted && this.userForm.controls.last_name.errors != null);
  }

  invalidTelephone()
  {
  	return (this.submitted && this.userForm.controls.telephone.errors != null);
  }

  invalidEmail()
  {
  	return (this.submitted && this.userForm.controls.email.errors != null);
  }

  onSubmit()
  {  

    this.submitted = true;
  	if(this.userForm.invalid == true || this.userId==0)
  	{
      this.debug_data = 'Invalid data!'
  		return;
  	}
  	else
  	{
    //   var profileData = {}
    //   profileData['userId'] = this.userId;
    //   profileData['orgId'] = this.userForm.controls.org_id.value;
    //   profileData['extId'] = this.userForm.controls.ext_id.value;
    //   profileData['firstName'] = this.userForm.controls.first_name.value;
    //   profileData['lastName'] = this.userForm.controls.last_name.value;
    //   profileData['telephone'] = this.userForm.controls.telephone.value;
    //   profileData['email'] = this.userForm.controls.email.value;
     
    //   this.debug_data = 'Submited!'
    //  // console.log("profileData:" + JSON.stringify(profileData));
    //   this.modifyUserProfile(this.userId,profileData);
      this.openDialog();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FinishModifyDialog, {
      width: '350px',
      data: {first_name: this.userForm.controls.first_name.value, 
              last_name: this.userForm.controls.last_name.value }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this._location.back();
     
    });
  }
}

export class UserClass {
 
  ext_id : string;
  first_name: string;
  last_name: string;
  telephone: string;
  email: string;  
  created_on : string;
}


@Component({
  selector: 'finish-register-dialog',
  templateUrl: 'finish-modify-usrprofile-dlg.html',
})

export class FinishModifyDialog {

  constructor(
    public dialogRef: MatDialogRef<FinishModifyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}