<h1 mat-dialog-title>Update Data successful!!</h1>
Organization Name : {{data.org_name}} <br>
Description: {{data.org_desc}}
<div mat-dialog-content>
  <p>Press OK to close!</p>
 
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">OK</button> 
</div>
