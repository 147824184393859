<div class="container-lg">
    <h4 class="text-primary">Thông tin phác đồ</h4>
    <form [formGroup]="userForm" >
        <div class="row">       
            <div class="col">
                <h4 class="bold"> Nhập thông tin phác đồ {{regimenId}} - {{regimenData['name']}} </h4>


                <div class="form-group" [ngClass]="{ 'has-danger': invalidName() }"> 
                    <label for="name" class="control-label">Tên phác đồ *</label>
                    <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': invalidName() }" id="name" name="name" placeholder="Tên phác đồ"  >
                    <p *ngIf="invalidName()" id="name_error" class="invalid-feedback">Tên phác đồ không hợp lệ</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidShortName() }"> 
                    <label for="shortname" class="control-label">Tên ngắn *</label>
                    <input type="text" formControlName="shortname" class="form-control" [ngClass]="{ 'is-invalid': invalidShortName() }" id="shortname" name="shortname" placeholder="Tên viết gọn"  >
                    <p *ngIf="invalidShortName()" id="shortname_error" class="invalid-feedback">Tên ngắn không hợp lệ</p>
                </div>
    
                <div class="form-group" [ngClass]="{ 'has-danger': invalidVersion() }">
                    <label for="version" class="form-control-label">Mã phiên bản *</label>
                    <input type="text" formControlName="version" class="form-control" [ngClass]="{ 'is-invalid': invalidVersion() }" id="version" name="version" placeholder="Phiên bản">
                    <p *ngIf="invalidVersion()" id="version_error" class="invalid-feedback">Phiên bản phác đồ.</p>
                </div>
            
                <div class="form-group" [ngClass]="{ 'has-danger': invalidNote1() }">
                    <label for="note1" class="form-control-label">Thông tin 1 </label>
                    <textarea type="text" formControlName="note1" class="form-control" [ngClass]="{ 'is-invalid': invalidNote1() }" id="note1" name="note1" placeholder="Note1">
                    </textarea>

                    <p *ngIf="invalidNote1()" id="note1_error" class="invalid-feedback">Invalid last name.</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidNote2() }">
                    <label for="before" class="form-control-label">Thông tin 2 </label>
                    <textarea type="text" formControlName="before" class="form-control" [ngClass]="{ 'is-invalid': invalidNote2() }" id="before" name="before" placeholder="before">
                    </textarea>

                    <p *ngIf="invalidNote2()" id="note2_error" class="invalid-feedback">Invalid last name.</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidNote3() }">
                    <label for="during" class="form-control-label">Thông tin 3 </label>
                    <textarea type="text" formControlName="during" class="form-control" [ngClass]="{ 'is-invalid': invalidNote3() }" id="during" name="during" placeholder="during">
                    </textarea>

                    <p *ngIf="invalidNote3()" id="note3_error" class="invalid-feedback">Invalid last name.</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidCreated_date() }" *ngIf = "!isAddNew">
                    <label for="created_date" class="form-control-label">Ngày tạo *</label>
                    <input type="text" formControlName="created_date" class="form-control" [ngClass]="{ 'is-invalid': invalidCreated_date() }" id="created_date" name="created_date" placeholder="Ngày tạo">
                    <p *ngIf="invalidCreated_date()" id="created_date_error" class="invalid-feedback">Invalid last name.</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidUpdated_date() }" *ngIf = "!isAddNew">
                    <label for="updated_date" class="form-control-label">Ngày câp nhật *</label>
                    <input type="text" formControlName="updated_date" class="form-control" [ngClass]="{ 'is-invalid': invalidUpdated_date() }" id="updated_date" name="updated_date" placeholder="Ngày tạo">
                    <p *ngIf="invalidUpdated_date()" id="updated_date_error" class="invalid-feedback">Invalid last name.</p>
                </div>
                
                <div>
                    <strong>Các bước phác đồ </strong>

                    <div class="ib" (click)="onSubStepAdd(null)" *ngIf = "noChildren"><i class="fa fa-plus text-success"> Tạo mới bước phác đồ</i></div>&nbsp;
                  <mat-list>
                      <mat-list-item *ngFor="let step of regimenData['regimensteps']">
                      <mat-icon matListIcon>check</mat-icon>
                      <h3 matLine> {{step.name}} </h3>
                      <p matLine>
                        <span> {{step.note}} </span>                        
                      </p>
                     
                       <br>
                       <div class="ib" (click)="onSubStepEdit(step)"><i class="fa fa-cog text-primary"></i></div>&nbsp;
                        <div class="ib" (click)="onSubStepAdd(step)"><i class="fa fa-plus text-success"></i></div>&nbsp;
                        <div class="ib" (click)="onSubStepUp(step)"><i class="fa fa-arrow-up " style="color:burlywood"></i></div>&nbsp;
                        <div class="ib" (click)="onSubStepDown(step)"><i class="fa fa-arrow-down "  style="color:burlywood"></i></div>&nbsp;
                        <div class="ib" (click)="onSubStepDelete(step)"><i class="fa fa-close text-danger"></i></div>&nbsp;
                    
                      
                    </mat-list-item>
                  </mat-list>
                </div>
            </div>
        </div>


        <div class="row">  
            <div class="form-group has-error"> 
                <button type="submit" class="btn btn-primary" (click)="onSubmit()">
                    <i class="fa fa-check"></i> {{buttonSubmitTitle}} </button>   &nbsp;                 
                
                <button  class="btn btn-danger" (click)="onDeleteRegimen()" >
                <i class="fa fa-check"></i> Xóa phác đồ</button>   &nbsp; 
                <button mat-raised-button class="btn btn-success" (click)="onGoBack()"><i class="fa fa-check"></i> Quay lại</button>&nbsp;         
            </div>   
        </div>
    </form>
</div>

{{regimenData | json}}