import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FileDropDirective, FileSelectDirective,FileUploadModule} from 'ng2-file-upload';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import {ReactiveFormsModule , FormsModule}  from '@angular/forms'
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
  import { CommonModule } from '@angular/common';
  import {MatMenuModule} from '@angular/material/menu';
  import {MatDatepickerModule} from '@angular/material/datepicker'; 
  import { MatMomentDateModule } from "@angular/material-moment-adapter";

import { AppComponent } from './app.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { UserListComponent } from './user-list/user-list.component';
import { RegisterFormComponent ,FinishRegisterDialog} from './register-form/register-form.component';
import { UserProfileComponent ,FinishModifyDialog} from './user-profile/user-profile.component';
import { RegisterLogComponent } from './register-log/register-log.component';
import { ReportComponent } from './report/report.component';
import { MaterialModule } from './material.module';
import { AppRoutingModule } from './app-routing.module';
import { CheckinLogComponent } from './checkin-log/checkin-log.component';
import { CheckinReviewComponent } from './checkin-review/checkin-review.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from "@angular/material/dialog";
import { OrganizationManagerComponent } from './organization-manager/organization-manager.component';
import { DeviceManagerComponent } from './device-manager/device-manager.component';
import { OrganizationDataComponent, FinishOrgDataDialog } from './organization-data/organization-data.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTreeModule} from '@angular/material/tree';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import { PersonalReportComponent } from './personal-report/personal-report.component';
import { LazyLoadImageModule, intersectionObserverPreset, LoadImageProps } from 'ng-lazyload-image';
import * as myGlobal from './globals';
import { LoginFormComponent } from './login-form/login-form.component';
import { DeviceMonitorComponent } from './device-monitor/device-monitor.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import { PatientComponent } from './patient/patient.component';
import {MatRadioModule} from '@angular/material/radio';
import { PatientRegimenComponent } from './patient-regimen/patient-regimen.component';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip';
//import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { FilterQuestionManComponent } from './filter-question-man/filter-question-man.component';
import { PatientMonitorComponent } from './patient-monitor/patient-monitor.component';
import { SystemLogComponent } from './system-log/system-log.component';
import { ArticleManComponent } from './article-man/article-man.component';
import { RegimenManComponent } from './regimen-man/regimen-man.component';
import { RegimenSelectComponent } from './regimen-select/regimen-select.component';
import { RegimenComponent } from './regimen/regimen.component';
import { RegimenInfoComponent } from './regimen-info/regimen-info.component';
import { AfterProcessManComponent } from './after-process-man/after-process-man.component';
import {MatListModule} from '@angular/material/list';
import { ArticleItemComponent } from './article-item/article-item.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CKEditorModule } from 'ckeditor4-angular';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { FilterQuestionComponent } from './filter-question/filter-question.component';
import { PatientSearchComponent } from './patient-search/patient-search.component';
import { MessageManagementComponent } from './message-management/message-management.component';
import { MessageEditorComponent } from './message-editor/message-editor.component';
import { AfterProcessInfoComponent } from './after-process-info/after-process-info.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { SizeDetectorComponentComponent } from './size-detector-component/size-detector-component.component';
import { DataExportComponent } from './data-export/data-export.component';
import { RegimenStepInfoComponent } from './regimen-step-info/regimen-step-info.component';
import { RegimenStepManComponent } from './regimen-step-man/regimen-step-man.component';


export function loadImage({  imagePath }: LoadImageProps): Promise<string> {
  //console.log("DEBUG:Call loadImage with " + imagePath);

  let _path ;

  let data = JSON.parse(localStorage.getItem('currentUser'));
  let orgId =  data.org_id;

  // /console.log('Load img:', imagePath);

  if ((imagePath.substr(0,4)=="http") || (imagePath.substr(0,8)=="/assets/")) {
    _path = imagePath;
  } else if (imagePath.substr(0,5)=="face_") {
    imagePath  = imagePath.substr(5);
    if ( imagePath.substr(0,8)!="/assets/") {
      _path = myGlobal.api_endpoint_1 + "checkin_faceimg/" + orgId + "/" + imagePath;
    } else {
      _path =  imagePath;
    }
  } else if (imagePath.includes('/')) {   // Neu co / nghia la da co orgId -> Bo qua
    _path = myGlobal.api_endpoint_1 + "checkin_img/" +  imagePath;
    //console.log('Load from path1:', _path);
  } else {
    _path = myGlobal.api_endpoint_1 + "checkin_img/" + orgId + "/" + imagePath;
    //console.log('Load from path2:', _path);
  }

  return fetch(_path).then(res => res.blob()).then(blob => URL.createObjectURL(blob));
}

//https://www.npmjs.com/package/ng-lazyload-image

@NgModule({
  declarations: [
    AppComponent,
    //FileSelectDirective,
    //FileDropDirective,
    ProfilePictureComponent,
    UserListComponent,
    RegisterFormComponent,
    UserProfileComponent,
    RegisterLogComponent,
    CheckinLogComponent,
    CheckinReviewComponent,
    PageNotFoundComponent,
    ReportComponent,
    FinishRegisterDialog,
    FinishModifyDialog,
    OrganizationManagerComponent,
    DeviceManagerComponent,
    OrganizationDataComponent,
    FinishOrgDataDialog,
    PersonalReportComponent,
    LoginFormComponent,
    DeviceMonitorComponent,
    PatientListComponent,
    PatientComponent,
    PatientRegimenComponent,
    ChangePasswordComponent,
    UserInfoComponent,
    FilterQuestionManComponent,
    PatientMonitorComponent,
    SystemLogComponent,
    ArticleManComponent,
    RegimenManComponent,
    RegimenSelectComponent,
    RegimenComponent,
    RegimenInfoComponent,
    AfterProcessManComponent,
    ArticleItemComponent,
    ConfirmationDialogComponent,
    FilterQuestionComponent,
    PatientSearchComponent,
    MessageManagementComponent,
    MessageEditorComponent,
    AfterProcessInfoComponent,
    SizeDetectorComponentComponent,
    DataExportComponent,
    RegimenStepInfoComponent,
    RegimenStepManComponent,

  ],
    imports: [

        BrowserModule, HttpModule, FileUploadModule,
        MatDialogModule,
        MatTabsModule,
        MatTreeModule,
        MatIconModule,
        MatRadioModule,
        ReactiveFormsModule, FormsModule,
        HttpClientModule, CommonModule,
        MatInputModule,
        MatSelectModule,
        MaterialModule,
        MatCheckboxModule,
        MatCardModule,
        MatTooltipModule,
        // NgxMatDatetimePickerModule, 
        // NgxMatTimepickerModule,
        MatDatepickerModule,
        MatDatepickerModule, 
        MatMomentDateModule,
        MatListModule,
        AppRoutingModule,
        //MatTableModule,
        //MatPaginatorModule,
        MatExpansionModule,
        MatSortModule,
        MatProgressSpinnerModule,
        BrowserAnimationsModule,
        MatMenuModule,
        // LazyLoadImageModule
        LazyLoadImageModule.forRoot({loadImage}),
        AngularEditorModule,
        CKEditorModule, MatSlideToggleModule,

    ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    FinishRegisterDialog,
    FinishModifyDialog,
    FinishOrgDataDialog,
    ConfirmationDialogComponent]
})
export class AppModule { }
