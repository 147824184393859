import { Component, OnInit, Input } from "@angular/core";
import { UserProfileService } from "../user-profile.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import * as io from "socket.io-client";
import * as myGlobal from "../globals";

interface SelectState {
  value: string;
  strText: string;
  strDesc: string;
}

@Component({
  selector: "app-patient-regimen",
  templateUrl: "./patient-regimen.component.html",
  styleUrls: ["./patient-regimen.component.scss"],
})
export class PatientRegimenComponent implements OnInit {
  @Input() resetFormData: Subject<boolean> = new Subject<boolean>();

  @Input() input_patientId: string = "";

  userForm: FormGroup;
  patientId = "0";
  orginData: Array<Regimen> = [];
  currentRegimen = new Regimen();
  currentRegimenIdx = 0;
  countOfRegimen = 0;
  selPatientRegimenId = 0; // ID cua Patient_regimen
  regimenName = "";
  // lstRegimenCtl = new FormControl();
  selectState = null;
  stateDesc = "";
  // -- Thong tin ket xuat tu JSON
  other_data = [];
  quest_list = [];
  step_timing = [];
  // luu thong tin step cua regimen
  regimen_step = [];
  regimen_progress_timing = [];
  regimen = [];

  // ===============================
  //socket cho app
  private url = myGlobal.api_mobile_app_ws;
  private socket;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userProfileService: UserProfileService,
    private router: Router
  ) {
    this.socket = io(this.url, {
      secure: true,
      transports: ["websocket"],
      path: "/websocket",
      reconnectionDelay: 1000,
      reconnection: true,
      reconnectionAttempts: Infinity,
      jsonp: false,
    });
    this.socket.on("connect", () => console.log("connected"));
    this.socket.on("error", console.error);
    this.socket.on("connect_error", console.error);
  }

  ngOnInit() {
    // Neu khong co input, se lay tham so tu route
    if (!this.input_patientId) {
      console.log(this.input_patientId);
      this.patientId = this.route.snapshot.paramMap.get("patientId");
    } else {
      // Lay tham so neu duoc goi truc tiep
      console.log(this.input_patientId);
      this.patientId = this.input_patientId;
    }

    this.resetFormData.subscribe({next:(response) => {
      if (response) {
        // Reload data khi co du lieu moi tu lop cha
        this.getPatientRegimenByPatientId(this.patientId);
      }
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});

    this.userForm = this.formBuilder.group({
      selectRegimen: ["", Validators.required],
      active_code: ["", Validators.nullValidator],
      selectState: ["", Validators.nullValidator],
    });

    //this.patientId = this.route.snapshot.paramMap.get('patientId');
    this.getPatientRegimenByPatientId(this.patientId);
  }

  getPatientRegimenByPatientId(patientId): void {
    console.log("Call getPatientRegimenByPatientId");
    this.userProfileService
      .getPatientRegimenByPatientId(patientId)
      .subscribe({next:(data) => {
        this.orginData = data;
        this.countOfRegimen = data.length;
        this.currentRegimenIdx = 0;
        this.currentRegimen = data[this.currentRegimenIdx];

        if (this.currentRegimen) {
          this.selPatientRegimenId = this.currentRegimen.patient_regimen_id;
          this.other_data = JSON.parse(this.currentRegimen.other_data);
          this.step_timing = JSON.parse(this.currentRegimen.step_timing);
          this.questProcess(this.other_data);
          this.regimenTimingProcess(this.step_timing);
          // get regimen
          this.getRegimenById(this.currentRegimen.regimen_id);

          this.userForm.controls.selectRegimen.setValue(
            this.currentRegimen.patient_regimen_id
          );
          this.userForm.controls.active_code.setValue(
            this.currentRegimen.active_code
          );
          this.userForm.controls.selectState.setValue(
            this.currentRegimen.state + ""
          );
        } else {
          this.other_data = [];
        }
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});
  }

  updateRegimenOfPatientState(pat_reg_id, state): void {
    this.userProfileService
      .updateRegimenOfPatientState(pat_reg_id, state)
      .subscribe({next:(data) => {
        console.log("Return data: " + data);
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});
  }

  getRegimenById(regimen_id) {
    if (regimen_id != null && regimen_id !== undefined && regimen_id > 0) {
      this.userProfileService.getRegimenById(regimen_id).subscribe({next:(data) => {
        // console.log("Return data "+ JSON.stringify(data));
        data.regimensteps.sort((a, b) => a.index > b.index);
        // console.log(
        //    'Return regimensteps ' + JSON.stringify(data)
        // );
        // this.regimen = data;
        // this.regimen_step = data['regimensteps'];
        this.regimenName = data.name;

        this.processRegimenPatient(data);
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});
    }
  }

  processRegimenPatient(regimen_patient) {
    this.regimen_step = [];
    if (regimen_patient === null) {
      return;
    } else {
      regimen_patient.regimensteps.forEach((q, index) => {
        let regimen_step_timing = this.regimen_progress_timing[index];
        if (regimen_step_timing !== null && regimen_step_timing !== undefined) {
          regimen_step_timing.name = q.name;
        } else {
          regimen_step_timing = new RegimenTimeStep();
          regimen_step_timing.name = q.name;
          this.regimen_progress_timing.push(regimen_step_timing);
        }
      });
    }
  }

  onChangeActiveCode(event) {
    const newActiveCode = this.userForm.controls.active_code.value;
    const patient_regimen_id = this.currentRegimen.patient_regimen_id;
    if (
      confirm(
        "Are you sure to change active code for " + patient_regimen_id + "? "
      )
    ) {
      this.userProfileService
        .updateRegimenOfPatientActiveCode(patient_regimen_id, newActiveCode)
        .subscribe({next:(data) => {
          // this.userName = data.username;
          alert("Doi ma kich hoat thanh cong!");
          this.currentRegimen.active_code = newActiveCode;
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});
      console.log("onChangeActiveCode:" + newActiveCode);
    }
  }

  onUpdatePatientRegimenState() {
    this.selectState = this.userForm.controls.selectState.value;

    console.log("Change regimen state: " + this.selectState);
    if (this.currentRegimen) {
      this.userProfileService
        .updateRegimenOfPatientState(
          this.currentRegimen.patient_regimen_id,
          this.selectState
        )
        .subscribe({next:(data) => {
          console.log("Return data: " + data);
          alert("Cap nhat thanh cong!");

          //TODO sent event to socket
          console.log(
            "send socket 'patient change state' value " +
              this.currentRegimen.patient_id
          );
          this.socket.emit(
            myGlobal.patient_events.patient_change_state,
            this.currentRegimen.patient_id
          );
          //end

          this.ngOnInit();
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});
    }
  }

  onChangeRegimen(event) {
    const idx = this.userForm.controls.selectRegimen.value;
    console.log("Select : " + idx);

    this.orginData.every((regim, index) => {
      if (regim.patient_regimen_id === idx) {
        this.currentRegimen = regim;
        this.currentRegimenIdx = index;
        console.log(regim);

        this.other_data = JSON.parse(regim.other_data);

        this.questProcess(this.other_data);

        this.userForm.controls.active_code.setValue(
          this.currentRegimen.active_code
        );
        this.userForm.controls.selectState.setValue(
          this.currentRegimen.state + ""
        );

        return false;
      } else {
        return true;
      }
    });
  }

  questProcess(other_data) {
    this.quest_list = [];

    if (other_data === null || !other_data.hasOwnProperty("quests")) {
      return;
    }

    other_data.quests.forEach((q) => {
      const obj = new QuestElement();
      obj.quest = q.quest;
      obj.id = q.id;
      this.quest_list.push(obj);
      obj.ids = [];
      q.ids.forEach((sub_q) => {
        const subobj = new QuestElement();
        subobj.quest = sub_q.quest;
        subobj.id = sub_q.id;
        obj.ids.push(subobj);
      });
    });

    if (other_data.hasOwnProperty("answer")) {
      let p1 = 0;
      other_data.answer.forEach((sub_ans) => {
        let p2 = 0;
        sub_ans.forEach((item_answer) => {
          this.quest_list[p1].ids[p2].answer = item_answer;
          p2 = p2 + 1;
        });
        p1 = p1 + 1;
      });
    }
  }

  regimenTimingProcess(step_timing) {
    this.regimen_progress_timing = [];

    if (step_timing === null) {
      return;
    }

    step_timing.forEach((s, index) => {
      const obj = new RegimenTimeStep();
      obj.startTime = s.start_time;
      obj.endTime = s.end_time;
      if (index === this.currentRegimen.current_step) {
        obj.processing = true;
      }
      this.regimen_progress_timing.push(obj);
      console.log("Start time:" + new Date(s.start_time));
    });
  }

  invalidRegimen() {
    return false;
  }

  invalidActiveCode() {
    return false;
  }

  invalidState() {
    return false;
  }

  changeState(event) {}

  // Tooltip cho regimen_states
  getToolTipData() {
    if (this.selectState) {
      let msg = "";
      this.regimen_states.forEach((item) => {
        if (item.value === this.selectState) {
          msg += item.strDesc + " ";
          this.stateDesc = item.strDesc; // + "  ( " + item.value + " - " + item.strText + " )";
        }
      });
      return msg;
    } else {
      this.stateDesc = "";
      return "please select a state";
    }
  }

  //---------------------------------------
  regimen_states: SelectState[] = [
    {
      value: "1",
      strText: "PATIENT_PRE_FILTER",
      strDesc: "Trước khi sàng lọc",
    },
    {
      value: "2",
      strText: "PATIENT_STATE_IDLE",
      strDesc: "Đã thực hiện sàng lọc, đủ điều kiện thực hiện phác đồ",
    },
    {
      value: "3",
      strText: "PATIENT_STATE_READY_ACTIVE",
      strDesc:
        "Trạng thái sau khi lựa chọn địa điểm uống thuốc, ngày nội soi và kích hoạt phác đồ",
    },
    {
      value: "4",
      strText: "PATIENT_STATE_ACTIVE",
      strDesc: "Trạng thái đang thực hiện phác đồ",
    },
    {
      value: "41",
      strText: "PATIENT_STATE_ACTIVE_SUSPEND",
      strDesc: "Trạng thái tạm ngưng phác đồ khi bấm SOS",
    },
    {
      value: "42",
      strText: "PATIENT_STATE_WAIT_FOR_TIME_MORNING",
      strDesc: "Thực  hiện phác đồ ở nhà, chờ chuyển sang ngày mới",
    },
    {
      value: "43",
      strText: "PATIENT_STATE_SUSPEND_NOT_ALLOW_SELF_ACTIVE",
      strDesc: "Trạng thái dừng phác đồ, không cho phép tự kích hoạt lại",
    },
    {
      value: "5",
      strText: "PATIENT_STATE_CONFIRM",
      strDesc: "Đã confirm độ sạch sau khi thực hiện phác đồ",
    },
    {
      value: "6",
      strText: "PATIENT_STATE_AFTER_CONFIRM",
      strDesc: "Thái thái sau xác nhận độ sạch thành công",
    },
    {
      value: "7",
      strText: "PATIENT_STATE_POST_SUPPORT",
      strDesc:
        "Trạng thái sau khi thực hiện phác đồ và được confirm là sạch, Đủ điều kiện nội soi",
    },
    {
      value: "8",
      strText: "PATIENT_STATE_POST_SUPPORT_NOT_ENOUGH_CONDITION",
      strDesc:
        "Trạng thái sau khi thực hiện phác đồ và được confirm là chưa sạch, chưa đủ điều kiện",
    },
    {
      value: "11",
      strText: "PATIENT_AFTER_FILTER_NOT_ALLOW_REGIMEN",
      strDesc: "Sau sàng lọc nhưng không đáp ứng được điều kiện phác đồ",
    },
  ];
}

export class QuestElement {
  id: number;
  quest: string;
  ids: QuestElement[];
  answer: string;
}

export class RegimenTimeStep {
  startTime: number;
  endTime: number;
  name: string;
  processing: boolean;
}

export class Regimen {
  name: string;
  current_step: number;
  steps: RegimenStep[];
  patient_regimen_id: number;
  other_data: string;
  active_code: string;
  regimen_id: number;
  step_timing: string;
  state: string;
  patient_id: number;
  eff_date: string;
  exp_date: string;
  created_date: string;
  updated_date: string;
  regimen_where: string;
}

export class RegimenStep {
  name: string;
  time: number;
}
