<div class="container-lg">
    <h4 class="text-primary">Thông tin câu hỏi sàng lọc</h4>
    <form [formGroup]="userForm" >
        <div class="row">       
            <div class="col">

                <h4 *ngIf="!createNew" class="bold"> Nhập thông tin câu hỏi id : {{questId}}
                    - {{questData['quest']}} </h4>
              <h4 class="bold" *ngIf="createNew"> Tạo mới câu hỏi </h4>

                <div *ngIf="parentObj"><strong>Câu hỏi lớp cha: </strong> {{parentObj.quest}}</div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidQuest() }"> 
                    <label for="quest" class="control-label">Câu hỏi*</label>
                    <input type="text" formControlName="quest" class="form-control" [ngClass]="{ 'is-invalid': invalidQuest() }" id="quest" name="quest" placeholder="Câu hỏi"  >
                    <p *ngIf="invalidQuest()" id="name_error" class="invalid-feedback">Câu hỏi không hợp lệ</p>
                </div>
    
                <div class="form-group" [ngClass]="{ 'has-danger': invalidIndex() }">
                    <label for="index" class="form-control-label">Index *</label>
                    <input type="text" formControlName="index" class="form-control" [ngClass]="{ 'is-invalid': invalidIndex() }" id="index" name="index" placeholder="Thứ tự">
                    <p *ngIf="invalidIndex()" class="invalid-feedback" id="index_error">Thứ tự không hợp lệ.</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidGender() }">
                    <label for="gender" class="form-control-label">Chọn giới tính * &nbsp;</label>
                    <!-- input type="text" formControlName="gender" class="form-control" [ngClass]="{ 'is-invalid': invalidGender() }" id="gender" name="gender" placeholder="0 - Name, 1 - Nu, 2 - All" -->

                    <select class="custom-select" formControlName="gender"   style="width: auto;max-width: 300px;"   
                            [ngClass]="{ 'is-invalid': invalidGender() }"                      
                            id="gender" name="gender" placeholder="Chọn giới tính" 
                             >
                        <option  value="2">Chọn giới tính</option>
                        <option value='0' >Nam</option>
                        <option value='1'>Nữ </option>
                    </select>

                    

                    <p *ngIf="invalidGender()" class="invalid-feedback" id="sex_error"> Giới tính không hợp lệ.</p>
                </div>


                

            
                <div class="form-group" [ngClass]="{ 'has-danger': invalidCreated_date() }">
                    <label for="created_date" class="form-control-label">Ngày tạo *</label>
                    <input type="text" formControlName="created_date" class="form-control" [ngClass]="{ 'is-invalid': invalidCreated_date() }" id="created_date" name="created_date" placeholder="Ngày tạo" disabled>
                    <p *ngIf="invalidCreated_date()" id="created_date_error" class="invalid-feedback">Invalid created date.</p>
                </div>

                <div class="form-group" [ngClass]="{ 'has-danger': invalidUpdated_date() }">
                    <label for="updated_date" class="form-control-label">Ngày tạo *</label>
                    <input type="text" formControlName="updated_date" class="form-control" [ngClass]="{ 'is-invalid': invalidUpdated_date() }" id="updated_date" name="updated_date" placeholder="Ngày tạo" disabled>
                    <p *ngIf="invalidUpdated_date()" id="updated_date_error" class="invalid-feedback">Invalid updated date.</p>
                </div>

              
                
               
            </div>
        </div>


        <div class="row">  
            <div class="form-group has-error"> 
                <button type="submit" class="btn btn-primary" (click)="onSubmit()">
                    <i class="fa fa-check"></i> Cập nhật</button>   &nbsp;                 
                
               
                <button mat-raised-button class="btn btn-success" (click)="onGoBack()"><i class="fa fa-check"></i> Quay lại</button>&nbsp;         
            </div>   
        </div>
    </form>
</div>

<!-- {{quest_list|json}} -->
