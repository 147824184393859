<div class="container-md">
    <h4 class="text-primary">Chọn phác đồ điều trị</h4>
    
      Hãy chọn phác đồ điều trị cho bệnh nhân mã số  {{patientId}}
      <!-- <form [formGroup]="userForm"> -->

        <div class="row"> 
        <mat-form-field>
            
            <mat-select [(ngModel)]="selectedValue" name="regimen"
            (selectionChange)="onChangeRegimen($event)"
            >
              <mat-option *ngFor="let regimen of regimenList" [value]="regimen.id">
                {{regimen.id}} - {{regimen.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row"> 
          <p> Selected phác đồ số : {{selectedValue}} </p>
       
        </div>
          <div class="row"> 
            <button  class="btn btn-primary" (click)="onSubmit()">
              <i class="fa fa-check"></i> Cập nhật</button>   &nbsp; 
              <button class="btn btn-success" (click)="onGoBack()">
                  <i class="fa fa-mail-reply"></i> Quay lại</button>   &nbsp; 

        </div>
     
      <!-- </form> -->
</div>
     
  
