<div class="container-md">
    <h4 class="text-primary">Checkin Log</h4>

  <div class="row">    
    <mat-checkbox
    class="margin: 0 10px;"
    [(ngModel)]="showFraudOnly" (change)="chkChangeFilterType()">Show Fraud Only</mat-checkbox>
    <mat-form-field>

      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" class="table table-bordred table-hover" matSort multiTemplateDataRows>

      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Order No. </th>
        <td mat-cell *matCellDef="let element"> {{element.position}} </td>
      </ng-container>

      <ng-container matColumnDef="review">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let element">
          <i *ngIf="element.result_ok" class="fa fa-check text-success"></i> 
          <i *ngIf="!element.result_ok" class="fa fa-bolt text-danger"></i>             
          <font color="red" *ngIf="element.fraud_type">  {{element.fraud_type}} </font>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="face">
        <th mat-header-cell *matHeaderCellDef > Face </th>
        <td mat-cell *matCellDef="let element">
          <!-- img src="/assets/avatar.png" alt="Smiley face" height="52" width="42" / --> 
          <img [defaultImage]="defaultImage" [lazyLoad]="'face_'+element.content_data.imgId" 
             [errorImage]="imageToShowOnError"  height="52" width="52"  
             (mouseenter) ="expandedElement = expandedElement === element ? null : element"
             class="shadow-none p-1 mb-0 bg-light rounded"> 
        </td>
      </ng-container>
      
       <ng-container matColumnDef="usr_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> User Id </th>
        <td mat-cell *matCellDef="let element"> {{element.usr_id}} </td>
      </ng-container>

       <!-- Name Column -->
       <ng-container matColumnDef="ext_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ext Id </th>
        <td mat-cell *matCellDef="let element"> {{element.ext_id}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
        <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
      </ng-container>
  
      <!-- Weight Column -->
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
        <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="telephone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Telephone </th>
        <td mat-cell *matCellDef="let element"> {{element.telephone}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>
      <ng-container matColumnDef="created_on">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Time </th>
        <td mat-cell *matCellDef="let element"> {{element.created_on}} </td>
      </ng-container>

      
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="8">
          <div class="example-element-detail"
               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">            
               
              <img *ngIf="element.content_data" [defaultImage]="defaultImage" 
              [lazyLoad]="element.content_data.imgId" 
              height="400" width="640" [errorImage]="imageToShowOnError"  class="shadow-lg p-3 mb-5 bg-white rounded">               
              &nbsp;
              <div *ngIf="element.content_data">
              DeviceId: {{element.content_data.deviceId}} &nbsp;
              MsgID:{{element.content_data.msgId}}
            </div>
          </div>
        </td>
      </ng-container>

  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <!-- tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr -->

      <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    </table>
  
    <mat-paginator [pageSizeOptions]="[10,20,50]" showFirstLastButtons></mat-paginator>
  
</div>
</div>
