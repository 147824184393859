import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {UserProfileService} from '../user-profile.service';
import {MatSort} from '@angular/material/sort';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class UserListComponent implements OnInit {

  displayedColumns: string[] = ['idx','status','user_id','username', 'email','telephone', 'webAppRoles',  'blocked'];
  
  userList: any;
  dataSource = new MatTableDataSource<UserElement>(this.userList);

  isSysAdmin = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  img_data : string =null;
  json_obj : object;
    expandedElement: any;
  

  constructor(
    private userProfileService:UserProfileService,
    private router: Router
    ) { }


  ngOnInit()
  {
      this.getUserList();
      this.dataSource.sort = this.sort;

      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.isSysAdmin = currentUser['user'].webAppRoles =='sysadmin';
  
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
   }


   applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  mouseEnter(data : string){
    console.log("mouse enter : " + data);
  }

  chkChangeFilterType () {
    this.getUserList();
    
  }

  getUserList() {
    this.userProfileService.getUserList().subscribe({next:(data: []) => {
        console.log(data);
        
        this.userList = [];

        var idx = 0;

        data.forEach(e => {
          idx ++;
          var obj= new UserElement();

          obj = e;
          obj['idx'] = idx;
          
          this.userList.push(obj);
        
        });

        this.dataSource.data = this.userList ;
        this.dataSource.sort = this.sort;
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
  }

  onDeleteUser(userId) {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if  (currentUser['user'].webAppRoles !='sysadmin') {
      alert("Permission denied!");
      return;
    }

    if (userId == currentUser['user'].id) {
      alert("Không thể tự xoá user!");
      return;
    }
    if (confirm("Are you sure to delete user id=" + userId + " ? \nLUU Y: Khong khoi phuc duoc so lieu!")) {
      console.log("Delete User ID=" + userId); 

      this.userProfileService.deleteUserById(userId )
        .subscribe({next:(data) => {
          alert("Xoa thanh cong");
          this.userProfileService.sysLog('UserListComponent','Delete User',
                " id=" + userId + " "  );

          this.ngOnInit();
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});
      
    }
  }
 
};

export class UserElement {
  idx: number;
  user_id : number;
  username : string;
  email: string;
  telephone: string;
  webAppRoles: string;
  blocked: string;    
  status : string;
}

