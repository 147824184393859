import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {UserProfileService} from '../user-profile.service';
import {MatSort} from '@angular/material/sort';
import {checkTimeOut} from '../utils';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-regimen-man',
  templateUrl: './regimen-man.component.html',
  styleUrls: ['./regimen-man.component.scss']
})
export class RegimenManComponent implements OnInit {

  displayedColumns: string[] = ['position','status','id','name', 'version','created_at', 'updated_at', 'edit','delete'];

  regimenList: any;
  dataSource = new MatTableDataSource<RegimentElement>(this.regimenList);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
    enable_delete_btn: boolean;

  constructor(
    private userProfileService: UserProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location
  ) { }

  ngOnInit() {
    this.getRegimenList();
    this.dataSource.sort = this.sort;

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
   }


   getRegimenList() {
      this.regimenList = [];

      this.userProfileService.getRegimenList('0').subscribe({next:(data: []) => {

        if (!checkTimeOut(data,this.router)) {
            return;
        }
        
        this.regimenList = data;
        var i = 1;
        this.regimenList.forEach(e => {        
          e['position'] = i;    
          i = i +1;
          
        });

       // console.log(this.regimenList);
        this.dataSource.data = this.regimenList ;//= new MatTableDataSource<PeriodicElement>(this.lastaccess); 
        this.dataSource.sort = this.sort;
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});

     
    };

    onClickEdit(regimenId: string) {
      this.router.navigate(["regimen-info/" , regimenId]);
    }

    clickDeleteMethod(id: any) {

    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
}


export class RegimentElement {
  position: number;
  status : string;
  id: string;  
  name: string;
  version: string;  
  created_at : string;
  updated_at : string;
  
    
}
