<div class="container-lg">
    <h4 class="text-primary">Danh sách thủ thuật nội soi</h4>
    <div class="row">
        <mat-form-field>
            <mat-label>Lọc tìm kiếm</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Nhập từ khóa ...">
        </mat-form-field>
        
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort
            class="table table-bordred table-striped">
            <ng-container matColumnDef="idx">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Số thứ tự</th>
                <td mat-cell *matCellDef="let element">{{element.index}}</td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tên thủ thuật</th>
                <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>
            <ng-container matColumnDef="tag">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag</th>
                <td mat-cell *matCellDef="let element">{{element.tags}}</td>
            </ng-container>
            <ng-container matColumnDef="re_examination">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tái khám</th>
                <td mat-cell *matCellDef="let element">
                    <i *ngIf="element['re-examination']=='1'" class="fa fa-check text-success"></i>
                    <i *ngIf="element['re-examination']!='1'" class="fa fa-bolt text-danger"></i>
                </td>
            </ng-container>
            <ng-container matColumnDef="biopsy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Lấy sinh thiết</th>
                <td mat-cell *matCellDef="let element">
                    <i *ngIf="element.biopsy=='1'" class="fa fa-check text-success"></i>
                    <i *ngIf="element.biopsy!='1'" class="fa fa-bolt text-danger"></i>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td  mat-cell *matCellDef="let element">
                    
                   <div class="ib" title="Sửa"><a routerLink="/afterprocess/edit/{{element.id}}"><i class="fa fa-cog text-primary"></i></a></div>&nbsp;
                   <div class="ib" (click)="clickAddProduce(element)" title="Chèn thêm câu hỏi"><i class="fa fa-plus text-success"></i></div>&nbsp;
                   <div class="ib"                                                        *ngIf="element['pos']==1"><i class="fa fa-arrow-up " style="color:lightgray"></i></div>
                   <div class="ib" (click)="clickMoveUpProduce(element)" title="Lên" *ngIf="element['pos']!=1"><i class="fa fa-arrow-up " style="color:burlywood"></i></div>&nbsp;
                   <div class="ib"                                                        *ngIf="element['pos']==2"><i class="fa fa-arrow-down "  style="color:lightgray"></i></div>
                   <div class="ib" (click)="clickMoveDownProduce(element)" title="Xuống" *ngIf="element['pos']!=2"><i class="fa fa-arrow-down "  style="color:burlywood"></i></div>&nbsp;
                   <div class="ib" (click)="clickDeleteProduce(element)"  title="Xóa câu hỏi con"><i class="fa fa-close text-danger"></i></div>&nbsp;
               
                </td>
            </ng-container>
              

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="8">
                    <div class="example-element-detail"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-8">
                                    Index : {{element.index}} &nbsp;<br>
                                    Thủ thuật : {{element.name}} &nbsp;<br>
                                    Tag : {{element.tags}} &nbsp;<br>
                                    Tái khám : {{element['re-examination']}} &nbsp;<br>
                                    Lấy sinh thiết : {{element.biopsy}} &nbsp;<br>
                                </div>
                                <!-- div class="col-sm-4">
                                    <div  class="ib" data-placement="top" data-toggle="tooltip" title="Edit">
                                        <a routerLink="/afterprocess/edit/{{element.id}}">
                                        <button class="btn btn-primary btn-xs" 
                                        data-title="Edit" data-toggle="modal"  ><i class="fa fa-pencil-square-o"></i></button></a></div>
                                    
                                </div -->
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <!-- tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr -->
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10,20,50]" showFirstLastButtons></mat-paginator>
    </div>
</div>