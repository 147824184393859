import { Component, OnInit , Inject} from '@angular/core';
import {UserProfileService} from '../user-profile.service';
import {Location} from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as myGlobal from '../globals'; 
import { CdkStepperNext } from '@angular/cdk/stepper';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-regimen-info',
  templateUrl: './regimen-info.component.html',
  styleUrls: ['./regimen-info.component.scss']
})
export class RegimenInfoComponent implements OnInit {

  regimenId = '0';
  regimenData = [];
  userForm: FormGroup;

  isAddNew = false;
  buttonSubmitTitle = "Cập nhật";

  children = [];
  noChildren = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private userProfileService:UserProfileService,
    private formBuilder: FormBuilder,        
      public dialog: MatDialog

  ) { }

  ngOnInit() {
    this.regimenId = this.route.snapshot.paramMap.get('regimenId');

    

    this.userForm = this.formBuilder.group({      
      name: ['', Validators.required],
      shortname: ['', Validators.required],
  		version: ['', Validators.required],
  		created_date: [{value:'', disabled: true}, Validators.required],      
      updated_date: [{value:'', disabled: true}, Validators.required],
      note1 : ['', Validators.required],
      before : ['', Validators.required],
      during : ['', Validators.required],
      regimensteps : [],
    });


    if (this.regimenId == 'addNew') {
      this.isAddNew = true;
      this.regimenId = '0';
      this.buttonSubmitTitle = 'Tạo mới';
    } else {
      this.isAddNew = false;
      this.getRegimenById(this.regimenId )
    }

  }

  getRegimenById(regimenId) {
    this.regimenData = [];
    this.userProfileService.getRegimenById(regimenId).subscribe({next:(data: []) => {
      this.regimenData = data;   
      
      if (!this.regimenData.hasOwnProperty('regimensteps')) {
        console.log('DEBUG: regimensteps NULLL!')
        this.regimenData['regimensteps']=[];
      } else {
        console.log("DEBUG=->>" + JSON.stringify(this.regimenData['regimensteps']));
      }

      this.noChildren = this.regimenData['regimensteps'].length==0;
      
      this.userForm.controls.name.setValue(  this.regimenData['name']);
      this.userForm.controls.shortname.setValue(  this.regimenData['shortname']);
      this.userForm.controls.version.setValue(  this.regimenData['version']);
      this.userForm.controls.created_date.setValue(  this.regimenData['created_at']);
      this.userForm.controls.updated_date.setValue(  this.regimenData['updated_at']);
      this.userForm.controls.note1.setValue(  this.regimenData['note']);
      this.userForm.controls.during.setValue(  this.regimenData['during']);
      this.userForm.controls.before.setValue(  this.regimenData['before']);

      
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});   
  };

  invalidName() { return false};
  invalidShortName() { return false};
  invalidVersion() { return false};
  invalidCreated_date() { return false};
  invalidUpdated_date() { return false};
  invalidNote1() { return false};
  invalidNote2() { return false};
  invalidNote3() { return false};

  onGoBack()
  {  
    this._location.back();
  }
  
  onSubmit() {

    var body =  {
     // index: 0,
      name: this.userForm.controls.name.value,      
      shortname: this.userForm.controls.shortname.value,   
      version :  this.userForm.controls.version.value, 
     // regimenId: this.regimenId,
      note:this.userForm.controls.note1.value,
      during:this.userForm.controls.during.value,
      before: this.userForm.controls.before.value,

    };


    if (this.isAddNew) { 
      this.userProfileService.addNewRegimen(
      body
      ).subscribe({next:(result) => {
                  
          alert('Tạo mới thành công phác đồ ' + this.userForm.controls.name.value );
          this._location.back();
      
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});
      this.userProfileService.sysLog('RegimenInfoComponent','submit','Add Regimen = ' +  this.userForm.controls.name.value);

    } else {
      this.userProfileService.updateRegimenById(
        this.regimenId,
        body
        ).subscribe({next:(result) => {
                    
            alert('Cập nhật phác đồ ' + this.userForm.controls.name.value  + ' thành công ');
            this._location.back();
        
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});
        this.userProfileService.sysLog('RegimenInfoComponent','submit','Update Quest= ' +  this.userForm.controls.name.value);

    }
    
  };

  
  onDeleteRegimen() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Có chắc chắn muốn xoá phác đồ này không ?"
    });
    dialogRef.afterClosed().subscribe({next:(result) => {
      if(result) {
        
        this.userProfileService.sysLog('RegimenInfoComponent','delete','regimenId=' + this.regimenId);
        alert('Xoa thanh cong');
      }
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
  };

  onSubStepEdit(step) {
    this.editRegimenStepInfo(this.regimenId,step.id,step.index);
    this.userProfileService.sysLog('RegimenInfoComponent','edit','regimenId=' + this.regimenId);

  };
  onSubStepAdd(step) {
    this.userProfileService.sysLog('RegimenInfoComponent','add sub step','regimenId=' + this.regimenId);
    if (step) {
      this.editRegimenStepInfo(this.regimenId,0,step.index);
    } else {
      this.editRegimenStepInfo(this.regimenId,0,0);
    }
      
  };

  editRegimenStepInfo(regimenId,regimenStepId,idx) {
    this.router.navigateByUrl('/regimen-step-info/' + regimenId + '/' + regimenStepId + '/' + idx);
  }


  onSubStepUp(step) {    
    var idx =  this.regimenData['regimensteps'].indexOf(step);
    if ( idx ==0 ) return;
    var tmp = this.regimenData['regimensteps'][idx - 1];
    this.regimenData['regimensteps'][idx - 1] = step;
    this.regimenData['regimensteps'][idx] = tmp;
    this.reOrderQuests(this.regimenData['regimensteps']);
    //alert("onMoveUpSession " +  idx);    
  }

  onSubStepDown(step) {
    var idx =  this.regimenData['regimensteps'].indexOf(step);
    if ( idx >= this.regimenData['regimensteps'].length -1 ) return;    

    var tmp = this.regimenData['regimensteps'][idx + 1];
    this.regimenData['regimensteps'][idx + 1] = step;
    this.regimenData['regimensteps'][idx] = tmp;

    this.reOrderQuests(this.regimenData['regimensteps']);
    //alert("onMoveDownSession " +  idx);    

  }

  reOrderQuests(list) {
    var idx = 1;
    list.forEach(element => {
      element.index = idx;
      idx = idx + 1;
    });
  }

  onSubStepDelete(step) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Có chắc chắn muốn xoá bước này không ?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        
        var idx =  this.regimenData['regimensteps'].indexOf(step);
        if (idx > -1 ) {
          this.regimenData['regimensteps'].splice(idx,1);
        }

        alert('Xoa thanh cong');
      }
    });
  }

}
