import {Router} from "@angular/router";

export function checkTimeOut(result, router): boolean {

    if (result.hasOwnProperty('result')) {
        //console.log('Check timeout');
        if (result.result=='timeout') {
            localStorage.removeItem('currentUser');
            router.navigate(['/login']);            
            return false;
        }
    }
    //router.navigate(['/login']);    
    return true;
}