import { Component, OnInit,ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {UserProfileService} from '../user-profile.service';
import {MatSort} from '@angular/material/sort';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {checkTimeOut} from '../utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-checkin-log',
  templateUrl: './checkin-log.component.html',
  styleUrls: ['./checkin-log.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CheckinLogComponent implements OnInit {
  displayedColumns: string[] = ['position','review','face','usr_id', 'ext_id','last_name', 'first_name',  'telephone','email','created_on'];
  
  lastaccess: any;
  dataSource = new MatTableDataSource<PeriodicElement>(this.lastaccess);

  defaultImage = '/assets/avatar.png';
  imageToShowOnError  = '/assets/avatar.png';
   expandedElement: PeriodicElement | null;
   showFraudOnly = false;
   mySubscription: any;

  //image = 'https://images.unsplash.com/photo-1443890923422-7819ed4101c0?fm=jpg';
  //imgurl = 'http://localhost:3001/checkin_img/40/53d8c3e42bb000f002411ab86bb00052d75321d0228300680b0b9bd463a7c3133d814149229808553037f599188fc3c2'
  // screen_lg = '400px';
  //   screen_md = '202px';
  //  // defaultImage = 'https://www.placecage.com/1000/1000';
  //   image1 = 'https://images.unsplash.com/photo-1422004707501-e8dad229e17a?fm=jpg';
  //   image2 = 'https://images.unsplash.com/photo-1439931444800-9bcc83f804a6?fm=jpg';
  //   image3 = 'https://images.unsplash.com/photo-1417128281290-30a42da46277?fm=jpg';


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private userProfileService: UserProfileService,
        private router: Router
          ) {

            // this.router.routeReuseStrategy.shouldReuseRoute = function () {
            //   return false;
            // };
            
            // this.mySubscription = this.router.events.subscribe((event) => {
            //   if (event instanceof NavigationEnd) {
            //     // Trick the Router into believing it's last link wasn't previously loaded
            //     this.router.navigated = false;
            //   }
            // });

           }


  //   ngOnDestroy() {
  //   if (this.mySubscription) {
  //     this.mySubscription.unsubscribe();
  //   }
  // }
  

  ngOnInit() {
    this.getCheckinLog();
    this.dataSource.sort = this.sort;
  }

 

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
   }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  mouseEnter(data : string){
    console.log("mouse enter : " + data);
  }

  chkChangeFilterType () {
    this.getCheckinLog();
    
  }

  getCheckinLog() {
    this.lastaccess = [];

    var filterType = '0';
    if (this.showFraudOnly) {
      filterType = '1';
    }

    this.userProfileService.getCheckinLog(filterType).subscribe({next:(data: []) => {

      if (!checkTimeOut(data,this.router)) {
          return;
      }
      //console.log('DAT:' + JSON.stringify(data));
      //this.lastaccess = data;
      
      data.forEach(e => {
        var obj= new PeriodicElement();

        obj['position'] = e['row_number'];
        
        obj['usr_id'] = e['usr_id'];
        obj['ext_id'] = e['ext_id'];
        obj['first_name'] = e['first_name'];
        obj['last_name'] = e['last_name'];
        obj['telephone'] = e['telephone'];
        obj['email'] = e['email'];
        obj['created_on'] = e['created_on'];
        obj['review_result'] = e['review_result'];
        obj['content_data'] = JSON.parse(e['content_data']);

        obj['result_ok'] = true;
        obj['fraud_type'] = '';
        if (obj['review_result']!=null) {
          obj['result_ok'] = ((obj['review_result']=='0') || (obj['review_result']=='00') || (obj['review_result']=='01')) ;
          
          if (obj['review_result']=='02') {
            obj['fraud_type'] = 'phone';
          } else if (obj['review_result']=='03') {
            obj['fraud_type'] = 'other';
          }
        } 
        
        if (obj.content_data === null || obj.content_data === undefined) {     
          obj.content_data = {'imgId':'/assets/avatar.png'};
        }
        this.lastaccess.push(obj);
       
      });

      this.dataSource.data = this.lastaccess ;//= new MatTableDataSource<PeriodicElement>(this.lastaccess); 
      this.dataSource.sort = this.sort;
      //this.dataSource.paginator = this.paginator;   
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
  }
}

export class PeriodicElement {
  position: number;
  ext_id : string;
  first_name: string;
  last_name: string;
  telephone: string;
  email: string;  
  created_on : string;
  content_data: any;
    
}
