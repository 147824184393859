import { Component, OnInit , Inject} from '@angular/core';
import {UserProfileService} from '../user-profile.service';
import {Location} from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as myGlobal from '../globals'; 
import { CdkStepperNext } from '@angular/cdk/stepper';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-regimen-step-info',
  templateUrl: './regimen-step-info.component.html',
  styleUrls: ['./regimen-step-info.component.scss']
})
export class RegimenStepInfoComponent implements OnInit {

  regimenId = '0';
  regimenStepId = '0';
  idx = '0';

  isAddNew = false;
  buttonSubmitTitle = 'Cập nhật';

  regimenStepData = [];
  userForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private userProfileService:UserProfileService,
    private formBuilder: FormBuilder,        
      public dialog: MatDialog
  ) {

    this.regimenId = this.route.snapshot.paramMap.get('regimenId');
    this.regimenStepId = this.route.snapshot.paramMap.get('regimenStepId');
    this.idx = this.route.snapshot.paramMap.get('idx');

    this.isAddNew = this.regimenStepId=='0';
    if (this.isAddNew) {
      this.buttonSubmitTitle = 'Tạo mới';
    }
    
    this.userForm = this.formBuilder.group({      
      name: ['', Validators.required],
      desc: ['', Validators.required],
  		index: ['', Validators.required],
  		created_date: [{value:'', disabled: true}, Validators.required],      
      updated_date: [{value:'', disabled: true}, Validators.required],
      time : ['', Validators.required],
      max_time : ['', Validators.required],
      min_time : ['', Validators.required],
      regimen_step_when : [],
      note : [],
    });

   }

  ngOnInit(): void {
      this.getStepRegimenById(this.regimenStepId);
  }

  getStepRegimenById(regimenStepId) {
    this.regimenStepData = [];
    this.userProfileService.getStepRegimenById(regimenStepId).subscribe({next:(data: []) => {
      this.regimenStepData = data;   
      
      this.userForm.controls.name.setValue(  this.regimenStepData['name']);
      this.userForm.controls.desc.setValue(  this.regimenStepData['desc']);
      this.userForm.controls.index.setValue(  this.regimenStepData['index']);
      this.userForm.controls.created_date.setValue(  this.regimenStepData['created_at']);
      this.userForm.controls.updated_date.setValue(  this.regimenStepData['updated_at']);
      this.userForm.controls.note.setValue(  this.regimenStepData['note']);
      this.userForm.controls.time.setValue(  this.regimenStepData['time']);
      this.userForm.controls.max_time.setValue(  this.regimenStepData['max_time']);
      this.userForm.controls.min_time.setValue(  this.regimenStepData['min_time']);
      this.userForm.controls.regimen_step_when.setValue(  this.regimenStepData['regimen_step_when']);

      
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});   
  };

  invalidName() { return false};
  invalidDesc() { return false};
  invalidIndex() { return false};
  invalidCreated_date() { return false};
  invalidUpdated_date() { return false};
  invalidTime() { return false};
  invalidMaxTime() { return false};
  invalidMintime() { return false};
  invalidNote() { return false};
  invalidregimen_step_when() { return false};

  onSubmit() {
    alert('Cap nhat');
  }

  onGoBack() {
    this._location.back();
  }
}
