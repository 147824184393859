
import {Component, OnInit} from '@angular/core';
import {UserProfileService} from '../user-profile.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Location} from '@angular/common';

@Component({
  selector: 'app-after-process-info',
  templateUrl: './after-process-info.component.html',
  styleUrls: ['./after-process-info.component.scss']
})
export class AfterProcessInfoComponent implements OnInit {

  apId = '0';
  apName = '';

  submitted = false;

  
  buttonSubmitTitle = 'Cập nhật';

  isAddNew = false;
  prodIdx = "1";

  userForm: FormGroup;


  constructor(
      private route: ActivatedRoute,
      private formBuilder: FormBuilder,
      private userProfileService: UserProfileService,
      private _location: Location,
      private router: Router
  ) { }

  ngOnInit() {

    this.userForm = this.formBuilder.group({      
      name: ['', Validators.required],      
      tags: ['', null],
      re_examination: [0, null],
      biopsy: [0, null],
    });



    var cmd = this.route.snapshot.paramMap.get('cmd');
    this.apId = this.route.snapshot.paramMap.get('apId');

    if (cmd == 'addNew') {
      this.prodIdx =  this.apId ;
      this.isAddNew = true;
      this.apId = '0';
      this.buttonSubmitTitle = 'Tạo mới';
    } else {
      this.isAddNew = false;
      this.buttonSubmitTitle = 'Cập nhật';
      this.userProfileService.getEndoscopicProcedureById(this.apId  )
          .subscribe({next:(data) => {
            this.apName = data['name'];

            this.userForm.controls.name.setValue( data['name']);
            this.userForm.controls.tags.setValue( data['tags']);
            this.userForm.controls.re_examination.setValue( data['re-examination']);
            this.userForm.controls.biopsy.setValue( data['biopsy']);
          
          }, error:err => {
            console.error('Observer got an error: ' + err);
            alert('Lỗi kết nối mạng! ');
          }});
    }
  }

  changeRole(event) {

  }

  invalidApName()
  {
    return (this.submitted && this.userForm.controls.name.errors != null) ;
  }
  

  onGoBack() {
    this._location.back();
  }

  onSubmit() {
    this.submitted = true;
  
    if(this.userForm.invalid != true )
  	{

      if (!this.isAddNew) {
        if (confirm("Có chắc chăn muôn cập nhật thủ thuật: " + this.apName  + " ( id=" + this.apId + ") ? ")) {
            this.userProfileService.updateEndoscopicProcedureById(this.apId ,{

              name:this.userForm.controls.name.value ,
              tags: this.userForm.controls.tags.value ,
              're-examination': this.userForm.controls.re_examination.value ,
              biopsy: this.userForm.controls.biopsy.value ,
              
            } )
            .subscribe({next:(data) => {
              //this.userName = data.username;
                this.userProfileService.sysLog('AfterProcessInfoComponent','Update After procedure',
                    this.userForm.controls.name.value   + " ( id=" + this.apId + "):" + 
                    this.userForm.controls.tags.value  );
                alert("Cập nhật thành công!");
                this._location.back();
            }, error:err => {
              console.error('Observer got an error: ' + err);
              alert('Lỗi kết nối mạng! ');
            }
            });
        }
      } else {
        this.userProfileService.addNewEndoscopicProcedureById({
          name:this.userForm.controls.name.value ,
          tags: this.userForm.controls.tags.value ,
          're-examination': this.userForm.controls.re_examination.value ,
          biopsy: this.userForm.controls.biopsy.value ,
          index: this.prodIdx,
          } )
        .subscribe({next:(data) => {
          //this.userName = data.username;

            this.userProfileService.sysLog('AfterProcessInfoComponent','Create New',
                this.userForm.controls.name.value   + " ( id=" + this.apId + "):" + 
                this.userForm.controls.tags.value );

             alert("Tạo mới thành công!");


            this._location.back();
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }
        });
      }
  	} 
  }


  changeOrgId($event: Event) {

  }
}


export class ProcedureElement {
  id : number;
  idx: number;
  name: string;
  tag: string;
  gender: string;
  date_of_birth: string;
  remark: string;
  email: string;
  created_date: string;
  updated_date: string;
  status: string;
  re_examination : string;
  biopsy : string;
}