import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckinLogComponent } from './checkin-log/checkin-log.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { RegisterLogComponent } from './register-log/register-log.component';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { ReportComponent } from './report/report.component';
import { PersonalDailyReportComponent } from './personal-daily-report/personal-daily-report.component';
import { OrganizationManagerComponent } from './organization-manager/organization-manager.component';
import { DeviceManagerComponent } from './device-manager/device-manager.component';
import { DeviceMonitorComponent } from './device-monitor/device-monitor.component';
import { OrganizationDataComponent, FinishOrgDataDialog } from './organization-data/organization-data.component';
import { PersonalReportComponent } from './personal-report/personal-report.component'; 
import { LoginFormComponent } from './login-form/login-form.component'; 
import { AuthGuard } from './auth.guard';
import { CheckinReviewComponent } from './checkin-review/checkin-review.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import {  PatientComponent } from './patient/patient.component';
import { PatientRegimenComponent } from './patient-regimen/patient-regimen.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FilterQuestionManComponent } from './filter-question-man/filter-question-man.component';
import { PatientMonitorComponent } from './patient-monitor/patient-monitor.component';
import { SystemLogComponent } from './system-log/system-log.component';
import { ArticleManComponent } from './article-man/article-man.component';
import { ArticleItemComponent } from './article-item/article-item.component';
import { RegimenManComponent } from './regimen-man/regimen-man.component';
import { RegimenSelectComponent } from './regimen-select/regimen-select.component';
import { RegimenInfoComponent } from './regimen-info/regimen-info.component';
import { AfterProcessManComponent } from './after-process-man/after-process-man.component';
import { FilterQuestionComponent } from './filter-question/filter-question.component';
import { PatientSearchComponent } from './patient-search/patient-search.component';
import { MessageManagementComponent } from './message-management/message-management.component';
import { MessageEditorComponent } from './message-editor/message-editor.component';
import { AfterProcessInfoComponent } from './after-process-info/after-process-info.component';
import { DataExportComponent } from './data-export/data-export.component';
import { RegimenStepInfoComponent } from './regimen-step-info/regimen-step-info.component';

const routes: Routes = [
{ path: 'patient/:patientId', component: PatientComponent , canActivate: [AuthGuard]},
{ path: 'patient-monitor', component: PatientMonitorComponent , canActivate: [AuthGuard]},
{ path: 'patient-regimen/:patientId', component: PatientRegimenComponent , canActivate: [AuthGuard]},
{ path: 'patient-list', component: PatientListComponent , canActivate: [AuthGuard]},
{ path: 'patient-search', component: PatientSearchComponent , canActivate: [AuthGuard]},
{ path: 'patient-search/:telephone', component: PatientSearchComponent , canActivate: [AuthGuard]},

{ path: 'user-man', component: UserListComponent , canActivate: [AuthGuard]},
{ path: 'user-info/:userId', component: UserInfoComponent , canActivate: [AuthGuard]},
{ path: 'change-pass/:userId', component: ChangePasswordComponent , canActivate: [AuthGuard]},
{ path: 'filterquest-man', component: FilterQuestionManComponent , canActivate: [AuthGuard]},
{ path: 'filterquest/:questId/:parentId/:idsIndex', component: FilterQuestionComponent , canActivate: [AuthGuard]},
{ path: 'system-log', component: SystemLogComponent , canActivate: [AuthGuard]},
{ path: 'article-man', component: ArticleManComponent , canActivate: [AuthGuard]},

{ path: 'regimen-man', component: RegimenManComponent , canActivate: [AuthGuard]},
{ path: 'regimen-sel/:patientId', component: RegimenSelectComponent , canActivate: [AuthGuard]},
{ path: 'regimen-info/:regimenId', component: RegimenInfoComponent , canActivate: [AuthGuard]},
{ path: 'regimen-step-info/:regimenId/:regimenStepId/:idx', component: RegimenStepInfoComponent , canActivate: [AuthGuard]},

{ path: 'afterprocess-man', component: AfterProcessManComponent , canActivate: [AuthGuard]},
{ path: 'afterprocess/:cmd/:apId', component: AfterProcessInfoComponent , canActivate: [AuthGuard]},

{ path: 'article-item/:postId', component: ArticleItemComponent , canActivate: [AuthGuard]},

{ path: 'message-man', component: MessageManagementComponent , canActivate: [AuthGuard]},
{ path: 'message-edit/:msgId', component: MessageEditorComponent , canActivate: [AuthGuard]},

{ path: 'data-export', component: DataExportComponent , canActivate: [AuthGuard]},



// { path: 'checkin-log', component: CheckinLogComponent , canActivate: [AuthGuard]},
// { path: 'register-form', component: RegisterFormComponent , canActivate: [AuthGuard]},
// { path: 'register-log', component: RegisterLogComponent , canActivate: [AuthGuard]},
// { path: 'report', component: ReportComponent , canActivate: [AuthGuard]},
// { path: 'dailyreport', component: PersonalDailyReportComponent , canActivate: [AuthGuard]},
// { path: 'device-man', component: DeviceManagerComponent , canActivate: [AuthGuard]},
// { path: 'device-mon', component: DeviceMonitorComponent , canActivate: [AuthGuard]},

// { path: 'org-man', component: OrganizationManagerComponent , canActivate: [AuthGuard]},
// { path: 'org-data/:cmd/:orgId', component: OrganizationDataComponent , canActivate: [AuthGuard]},
// { path: 'personal-report', component: PersonalReportComponent , canActivate: [AuthGuard]},
// { path: 'checkin-log-review', component: CheckinReviewComponent , canActivate: [AuthGuard]},


{ path: 'user-profile', component: UserProfileComponent , canActivate: [AuthGuard]},
{ path: 'login', component: LoginFormComponent },
{ path: '',component: DashBoardComponent},//, canActivate: [AuthGuard] },
   
{ path: '**', redirectTo: ''} //component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: [DashBoardComponent, PersonalDailyReportComponent, ]
})
export class AppRoutingModule { }
