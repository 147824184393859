<form [formGroup]="userForm" (ngSubmit)="onSubmit()">

    <div class="form-group" [ngClass]="{ 'has-danger':   invalidReportingCycle() }"> <!-- Org Type -->
        <label for="reportingCycleListCmb" class="control-label">Select Reporting Cycle:</label>
        <select class="custom-select" (change)="changeReportingCycle($event)" formControlName="reportingCycleListCmb" id="reportingCycleListCmb" name="reportingCycleListCmb" >
            <option value="" disabled>Select reporting cycle</option>
            <option *ngFor="let rp of reportingCycleList" [ngValue]="rp.reporting_cycle">{{rp.reporting_cycle}}</option>
         </select>
        <p *ngIf="invalidReportingCycle()" id="reportingCycleListCmb_error" class="invalid-feedback">Invalid Reporting Cycle.</p>
    </div>
    
   <div class="form-group" [ngClass]="{ 'has-danger':   invalidUsrId() }"> <!-- Org Type -->
      <label for="userListCmb" class="control-label">Select User:</label>
      <select class="custom-select" (change)="changeUserId($event)" formControlName="userListCmb" id="userListCmb" name="userListCmb" >
          <option value="" disabled>Select user</option>
          <option *ngFor="let usr of userList" [ngValue]="usr.usr_id">{{usr.last_name}} {{usr.first_name}}</option>
       </select>
      <p *ngIf="invalidUsrId()" id="userListCmb_error" class="invalid-feedback">Invalid User.</p>
  </div>

  <!-- div class="form-group has-error"> 
   <button type="submit" class="btn btn-primary" >
       <i class="fa fa-check"></i> Query</button>                    
    </div -->        
<button class="btn btn-success"  (click)="exportTable()"> Export to Excel file</button>
</form>
<br>
<br>

<div *ngIf="userData">
<table border=0 cellpadding=0 cellspacing=0 width=737 style='border-collapse:
collapse;table-layout:fixed;width:552pt' id="tblReport">
<col width=80 style='mso-width-source:userset;mso-width-alt:2925;width:60pt'>
<col width=43 span=6 style='mso-width-source:userset;mso-width-alt:1572;
width:32pt'>
<col width=44 style='mso-width-source:userset;mso-width-alt:1609;width:33pt'>
<col width=43 style='mso-width-source:userset;mso-width-alt:1572;width:32pt'>
<col width=42 style='mso-width-source:userset;mso-width-alt:1536;width:32pt'>
<col width=43 style='mso-width-source:userset;mso-width-alt:1572;width:32pt'>
<col width=40 style='mso-width-source:userset;mso-width-alt:1462;width:30pt'>
<col width=42 style='mso-width-source:userset;mso-width-alt:1536;width:32pt'>
<col width=43 style='mso-width-source:userset;mso-width-alt:1572;width:32pt'>
<col width=52 style='mso-width-source:userset;mso-width-alt:1901;width:39pt'>
<col width=50 style='mso-width-source:userset;mso-width-alt:1828;width:38pt'>
<tr height=28 style='mso-height-source:userset;height:21.0pt'>
 <td colspan=16 height=28 class=xl84 width=737 style='border-right:.5pt solid black;
 height:21.0pt;width:552pt'>B&#7842;NG CHI TI&#7870;T CH&#7844;M CÔNG</td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td colspan=16 height=17 class=xl87 style='border-right:.5pt solid black;
 height:12.75pt'>Mã nhân viên: {{userData.ext_id}}<span style='mso-spacerun:yes'>   </span>Tên
 nhân viên:<span style='mso-spacerun:yes'>  </span>{{userData.last_name}} {{userData.first_name}} /{{userData.usr_id}}<span
 style='mso-spacerun:yes'>  </span>B&#7897; ph&#7853;n: ------</td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td colspan=2 height=17 class=xl82 style='border-right:.5pt solid black;
 height:12.75pt'>Ngày th&#432;&#7901;ng</td>
 <td class=xl65 style='border-top:none;border-right:none'>{{content_data.sum_data.gc_nt}}</td>
 <td class=xl65 style='border-top:none;border-right:none'>{{content_data.sum_data.nc_nt}}</td>
 <td rowspan=3 class=xl73 style='border-bottom:.5pt solid black;border-top:
 none'>&nbsp;</td>
 <td colspan=2 class=xl82 style='border-right:.5pt solid black;border-left:
 none'>&#272;i s&#7899;m</td>
 <td class=xl65 style='border-top:none;border-right:none'>{{content_data.sum_data.vaosom_phut}}</td>
 <td rowspan=3 class=xl73 style='border-bottom:.5pt solid black;border-top:
 none'>&nbsp;</td>
 <td colspan=3 class=xl82 style='border-right:.5pt solid black;border-left:
 none'>&#272;i tr&#7877;</td>
 <td rowspan=3 class=xl73 style='border-bottom:.5pt solid black;border-top:
 none'>&nbsp;</td>
 <td colspan=3 class=xl82 style='border-right:.5pt solid black;border-left:
 none'>V&#7873; s&#7899;m</td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td colspan=2 height=17 class=xl82 style='border-right:.5pt solid black;
 height:12.75pt'>Cu&#7889;i tu&#7847;n</td>
 <td class=xl65 style='border-top:none;border-right:none'>{{content_data.sum_data.gc_nl}}</td>
 <td class=xl65 style='border-top:none;border-right:none'>{{content_data.sum_data.nc_nl}}</td>
 <td colspan=2 class=xl82 style='border-right:.5pt solid black;border-left:
 none'>V&#7873; tr&#7877;</td>
 <td class=xl65 style='border-top:none;border-right:none'>{{content_data.sum_data.ratre_phut}}</td>
 <td colspan=2 class=xl82 style='border-right:.5pt solid black;border-left:
 none'>S&#7889; l&#7847;n</td>
 <td class=xl65 style='border-top:none;border-right:none'>{{content_data.sum_data.vaotre_lan}}</td>
 <td colspan=2 class=xl82 style='border-right:.5pt solid black;border-left:
 none'>S&#7889; l&#7847;n</td>
 <td class=xl65 style='border-top:none;border-right:none'>{{content_data.sum_data.rasom_lan}}</td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td colspan=2 height=17 class=xl82 style='border-right:.5pt solid black;
 height:12.75pt'>T&#7892;NG</td>
 <td class=xl65 style='border-top:none;border-right:none'>{{content_data.sum_data.gc_nt + content_data.sum_data.gc_nl}}</td>
 <td class=xl65 style='border-top:none;border-right:none'>{{content_data.sum_data.nc_nt + content_data.sum_data.nc_nl}}</td>
 <td colspan=2 class=xl82 style='border-right:.5pt solid black;border-left:
 none'>T.N&#259;ng su&#7845;t</td>
 <td class=xl65 style='border-top:none;border-right:none'>{{content_data.sum_data.nangsuat}}</td>
 <td colspan=2 class=xl82 style='border-right:.5pt solid black;border-left:
 none'>S&#7889; phút</td>
 <td class=xl65 style='border-top:none;border-right:none'>{{content_data.sum_data.vaotre_phut}}</td>
 <td colspan=2 class=xl82 style='border-right:.5pt solid black;border-left:
 none'>S&#7889; phút</td>
 <td class=xl65 style='border-top:none;border-right:none'>{{content_data.sum_data.rasom_phut}}</td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td rowspan=3 height=51 class=xl73 style='border-bottom:.5pt solid black;
 height:38.25pt;border-top:none'>&nbsp;</td>
 <td colspan=14 class=xl76 style='border-right:.5pt solid black;border-left:
 none'>Các lo&#7841;i v&#7855;ng</td>
 <td rowspan=3 class=xl70 width=50 style='border-bottom:.5pt solid black;
 border-top:none;width:38pt'>&nbsp;</td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td height=17 class=xl66 width=43 style='height:12.75pt;border-top:none;
 border-left:none;width:32pt'>V</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>OM</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>TS</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>R</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>Ro</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>P</td>
 <td class=xl66 width=44 style='border-top:none;border-left:none;width:33pt'>F</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>CO</td>
 <td class=xl66 width=42 style='border-top:none;border-left:none;width:32pt'>CD</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>H</td>
 <td class=xl66 width=40 style='border-top:none;border-left:none;width:30pt'>CT</td>
 <td class=xl66 width=42 style='border-top:none;border-left:none;width:32pt'>Le</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>&nbsp;</td>
 <td class=xl66 width=52 style='border-top:none;border-left:none;width:39pt'>&nbsp;</td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td height=17 class=xl66 width=43 style='height:12.75pt;border-top:none;
 border-left:none;width:32pt'>0</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>0</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>0</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>0</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>0</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>0</td>
 <td class=xl66 width=44 style='border-top:none;border-left:none;width:33pt'>0</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>0</td>
 <td class=xl66 width=42 style='border-top:none;border-left:none;width:32pt'>0</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>0</td>
 <td class=xl66 width=40 style='border-top:none;border-left:none;width:30pt'>0</td>
 <td class=xl66 width=42 style='border-top:none;border-left:none;width:32pt'>0</td>
 <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
 <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td colspan=16 height=17 class=xl76 style='border-right:.5pt solid black;
 height:12.75pt'>Chi ti&#7871;t</td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td rowspan=2 height=34 class=xl70 width=80 style='border-bottom:.5pt solid black;
 height:25.5pt;border-top:none;width:60pt'>Ngày</td>
 <td rowspan=2 class=xl70 width=43 style='border-bottom:.5pt solid black;
 border-top:none;width:32pt'>Th&#7913;</td>
 <td colspan=2 class=xl80 width=86 style='border-right:.5pt solid black;
 border-left:none;width:64pt'>1</td>
 <td colspan=2 class=xl80 width=86 style='border-right:.5pt solid black;
 border-left:none;width:64pt'>2</td>
 <td colspan=2 class=xl80 width=87 style='border-right:.5pt solid black;
 border-left:none;width:65pt'>3</td>
 <td rowspan=2 class=xl70 width=43 style='border-bottom:.5pt solid black;
 border-top:none;width:32pt'>&#272;i tr&#7877;</td>
 <td rowspan=2 class=xl70 width=42 style='border-bottom:.5pt solid black;
 border-top:none;width:32pt'>V&#7873; s&#7899;m</td>
 <td rowspan=2 class=xl70 width=43 style='border-bottom:.5pt solid black;
 border-top:none;width:32pt'>T.Gi&#7901;</td>
 <td rowspan=2 class=xl70 width=40 style='border-bottom:.5pt solid black;
 border-top:none;width:30pt'>Công</td>
 <td rowspan=2 class=xl70 width=42 style='border-bottom:.5pt solid black;
 border-top:none;width:32pt'>Ph&#7841;t tr&#7877;</td>
 <td rowspan=2 class=xl70 width=43 style='border-bottom:.5pt solid black;
 border-top:none;width:32pt'>&#272;i s&#7899;m</td>
 <td rowspan=2 class=xl70 width=52 style='border-bottom:.5pt solid black;
 border-top:none;width:39pt'>V&#7873; tr&#7877;</td>
 <td rowspan=2 class=xl70 width=50 style='border-bottom:.5pt solid black;
 border-top:none;width:38pt'>T.N&#259;ng su&#7845;t</td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td height=17 class=xl66 width=43 style='height:12.75pt;border-top:none;
 border-left:none;width:32pt'>Vào</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>Ra</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>Vào</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>Ra</td>
 <td class=xl66 width=43 style='border-top:none;border-left:none;width:32pt'>Vào</td>
 <td class=xl66 width=44 style='border-top:none;border-left:none;width:33pt'>Ra</td>
</tr>

<ng-container *ngFor="let data of content_data.all; let idx = index">
 
    <ng-container
     *ngIf="data.DN == 'CN'; then cnData; else otherData">
   </ng-container>

<ng-template #otherData>
<tr height=17 style='height:12.75pt'>
 <td height=17 class=xl91 style='height:12.75pt'>{{data.D}}</td>
 <td class=xl67 style='border-right:none'>{{data.DN}}</td>
 <td class=xl93 style='border-right:none'>{{data.I}}&nbsp;</td>
 <td class=xl93 style='border-right:none'>{{data.O}}&nbsp;</td>
 <td class=xl93 style='border-right:none'>&nbsp;</td>
 <td class=xl93 style='border-right:none'>&nbsp;</td>
 <td class=xl93 style='border-right:none'>&nbsp;</td>
 <td class=xl93 style='border-right:none'>&nbsp;</td>
 <td class=xl93 style='border-right:none'>{{data.sdata.arrive_late}}</td>
 <td class=xl93 style='border-right:none'>{{data.sdata.leave_early}}</td>
 <td class=xl93 style='border-right:none'>{{data.sdata.straight_hours}}</td>
 <td class=xl93 style='border-right:none'>{{data.sdata.workday}}</td>
 <td class=xl93 style='border-right:none'>{{data.sdata.penalty}}</td>
 <td class=xl93 style='border-right:none'>{{data.sdata.arrive_early}}</td>
 <td class=xl93 style='border-right:none'>{{data.sdata.leave_late}}</td>
 <td class=xl93 style='border-right:none'>{{data.sdata.overtime_hours}}</td>
</tr>
</ng-template>

<ng-template #cnData>
<tr height=17 style='height:12.75pt'>
 <td height=17 class=xl92 style='height:12.75pt'>{{data.D}}</td>
 <td class=xl68 style='border-right:none'>CN</td>
 <td class=xl94 style='border-right:none'>{{data.I}}&nbsp;</td>
 <td class=xl94 style='border-right:none'>{{data.O}}&nbsp;</td>
 <td class=xl94 style='border-right:none'>&nbsp;</td>
 <td class=xl94 style='border-right:none'>&nbsp;</td>
 <td class=xl94 style='border-right:none'>&nbsp;</td>
 <td class=xl94 style='border-right:none'>&nbsp;</td>
 <td class=xl94 style='border-right:none'>{{data.sdata.arrive_late}}</td>
 <td class=xl94 style='border-right:none'>{{data.sdata.leave_early}}</td>
 <td class=xl94 style='border-right:none'>{{data.sdata.straight_hours/60}}</td>
 <td class=xl94 style='border-right:none'>{{data.sdata.workday}}</td>
 <td class=xl94 style='border-right:none'>{{data.sdata.penalty}}</td>
 <td class=xl94 style='border-right:none'>{{data.sdata.arrive_early}}</td>
 <td class=xl94 style='border-right:none'>{{data.sdata.leave_late}}</td>
 <td class=xl94 style='border-right:none'>{{data.sdata.overtime_hours/60}}</td>
</tr>
</ng-template>
</ng-container>

<tr height=17 style='height:12.75pt'>
 <td height=17 class=xl69 style='height:12.75pt'>&nbsp;</td>
 <td class=xl69>&nbsp;</td>
 <td class=xl69>&nbsp;</td>
 <td class=xl69>&nbsp;</td>
 <td class=xl69>&nbsp;</td>
 <td class=xl69>&nbsp;</td>
 <td class=xl69>&nbsp;</td>
 <td class=xl69>&nbsp;</td>
 <td class=xl69>&nbsp;</td>
 <td class=xl69>&nbsp;</td>
 <td class=xl69>&nbsp;</td>
 <td class=xl69>&nbsp;</td>
 <td class=xl69>&nbsp;</td>
 <td class=xl69>&nbsp;</td>
 <td class=xl69>&nbsp;</td>
 <td class=xl69>&nbsp;</td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td height=17 colspan=9 style='height:12.75pt;mso-ignore:colspan'></td>
 <td colspan=4 class=xl72>Kí tên</td>
 <td colspan=3 style='mso-ignore:colspan'></td>
</tr>
<tr height=34 style='height:25.5pt;mso-xlrowspan:2'>
 <td height=34 colspan=16 style='height:25.5pt;mso-ignore:colspan'></td>
</tr>
<tr height=17 style='height:12.75pt'>
 <td height=17 colspan=9 style='height:12.75pt;mso-ignore:colspan'></td>
 <td colspan=4 class=xl72><span style='mso-spacerun:yes'> </span></td>
 <td colspan=3 style='mso-ignore:colspan'></td>
</tr>

</table>
</div>
