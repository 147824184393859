<div class="container-md">
    <h4 class="text-primary">Thủ thuật nội soi</h4>
    <form [formGroup]="userForm" >
        <div class="row" *ngIf="isAddNew">  
            <strong>Tạo mới thủ thuật nội soi</strong>
        </div>
        <div class="row" *ngIf="!isAddNew">  
            <strong>Cập nhật cho thủ thuật : {{apName}} (ID :{{apId}})</strong>
        </div>
        <div class="row"> 
            <div class="col">


                <div class="form-group" [ngClass]="{ 'has-danger': invalidApName() }"> 
                    <label for="name" class="form-control-label">Tên thủ thuật </label>
                    <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': invalidApName() }" id="name" name="name" placeholder="Nhập tên thủ thuật" >   
                </div>

                <div class="form-group" > 
                    <label for="tags" class="form-control-label">Tags </label>
                    <input type="text" formControlName="tags" class="form-control" id="tags" name="tags" placeholder="Nhập tags">   
                </div>

                <div class="form-group" >                     
                    <div>
                    Tái khám: <input type="checkbox" formControlName="re_examination" class="form-control" id="re_examination" name="re_examination" >
                    </div>
                </div>
                <div class="form-group" >                     
                    <div>
                    Lấy sinh thiết: <input type="checkbox" formControlName="biopsy" class="form-control" id="biopsy" name="biopsy" >
                    </div>
                </div>
               
            </div>
        </div>
           
        <div class="row"> 
            <button type="submit" class="btn btn-primary" (click)="onSubmit()"><i class="fa fa-check"></i> {{buttonSubmitTitle}}</button>   &nbsp;             
            <button class="btn btn-success" (click)="onGoBack()"><i class="fa fa-mail-reply"></i> Quay lại</button>   &nbsp; 

        </div>
    </form>
</div>

