<h4 class="text-primary">Data Export</h4>

<div class="container-fluid">
  <div class="row">
    <div class="col">
        <form [formGroup]="userForm">

            <mat-form-field appearance="fill">
                <mat-label>From Date</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="fromDate"
                    (dateInput)="dateEvent('input', $event)" (dateChange)="dateEvent('change', $event)"
                >
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>To Date</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="toDate"
                    (dateInput)="dateEvent('input', $event)" (dateChange)="dateEvent('change', $event)">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
              &nbsp;
            <button class="btn btn-info"  (click)="doRequery()"> Requery</button> &nbsp;&nbsp;
            <button class="btn btn-success"  (click)="exportTable()"> Export to Excel file</button>
        </form>
    </div>
</div>
<br>

<div class="row" *ngIf="patientList" >
<table border=0 cellpadding=0 cellspacing=0 width=5676 style='border-collapse:
collapse;table-layout:fixed;width:4259pt'  id="tblReport">
<col class=xl66 width=50 style='mso-width-source:userset;mso-width-alt:1828;
width:38pt'>
<col class=xl65 width=95 style='mso-width-source:userset;mso-width-alt:3474;
width:71pt'>
<col class=xl65 width=79 style='mso-width-source:userset;mso-width-alt:2889;
width:59pt'>
<col class=xl65 width=81 style='mso-width-source:userset;mso-width-alt:2962;
width:61pt'>
<col class=xl65 width=105 style='mso-width-source:userset;mso-width-alt:3840;
width:79pt'>
<col class=xl65 width=112 style='mso-width-source:userset;mso-width-alt:4096;
width:84pt'>
<col class=xl65 width=46 style='mso-width-source:userset;mso-width-alt:1682;
width:35pt'>
<col class=xl65 width=155 style='mso-width-source:userset;mso-width-alt:5668;
width:116pt'>
<col class=xl65 width=162 style='mso-width-source:userset;mso-width-alt:5924;
width:122pt'>
<col class=xl65 width=88 style='mso-width-source:userset;mso-width-alt:3218;
width:66pt'>
<col class=xl65 width=215 style='mso-width-source:userset;mso-width-alt:7862;
width:161pt'>
<col class=xl65 width=216 style='mso-width-source:userset;mso-width-alt:7899;
width:162pt'>
<col class=xl65 width=81 style='mso-width-source:userset;mso-width-alt:2962;
width:61pt'>
<col class=xl65 width=225 style='mso-width-source:userset;mso-width-alt:8228;
width:169pt'>
<col class=xl65 width=132 style='mso-width-source:userset;mso-width-alt:4827;
width:99pt'>
<col class=xl65 width=369 style='mso-width-source:userset;mso-width-alt:13494;
width:277pt'>
<col class=xl65 width=123 style='mso-width-source:userset;mso-width-alt:4498;
width:92pt'>
<col class=xl65 width=133 style='mso-width-source:userset;mso-width-alt:4864;
width:100pt'>
<col class=xl65 width=110 style='mso-width-source:userset;mso-width-alt:4022;
width:83pt'>
<col class=xl65 width=150 style='mso-width-source:userset;mso-width-alt:5485;
width:113pt'>
<col class=xl65 width=131 style='mso-width-source:userset;mso-width-alt:4790;
width:98pt'>
<col class=xl65 width=137 style='mso-width-source:userset;mso-width-alt:5010;
width:103pt'>
<col class=xl65 width=99 style='mso-width-source:userset;mso-width-alt:3620;
width:74pt'>
<col class=xl65 width=213 style='mso-width-source:userset;mso-width-alt:7789;
width:160pt'>
<col class=xl65 width=139 style='mso-width-source:userset;mso-width-alt:5083;
width:104pt'>
<col class=xl65 width=85 style='mso-width-source:userset;mso-width-alt:3108;
width:64pt'>
<col class=xl65 width=115 style='mso-width-source:userset;mso-width-alt:4205;
width:86pt'>
<col width=128 style='mso-width-source:userset;mso-width-alt:4681;width:96pt'>
<col class=xl65 width=116 style='mso-width-source:userset;mso-width-alt:4242;
width:87pt'>
<col width=120 style='mso-width-source:userset;mso-width-alt:4388;width:90pt'>
<col class=xl65 width=132 style='mso-width-source:userset;mso-width-alt:4827;
width:99pt'>
<col class=xl65 width=155 span=6 style='mso-width-source:userset;mso-width-alt:
5668;width:116pt'>
<col class=xl65 width=149 style='mso-width-source:userset;mso-width-alt:5449;
width:112pt'>
<col class=xl65 width=129 span=2 style='mso-width-source:userset;mso-width-alt:
4717;width:97pt'>
<col class=xl65 width=124 style='mso-width-source:userset;mso-width-alt:4534;
width:93pt'>
<col class=xl65 width=73 style='mso-width-source:userset;mso-width-alt:2669;
width:55pt'>
<col class=xl65 width=103 style='mso-width-source:userset;mso-width-alt:3766;
width:77pt'>
<tr class=xl74 height=47 style='mso-height-source:userset;height:35.25pt'>
 <td height=47 class=xl67 width=50 style='height:35.25pt;width:38pt;
 font-size:11.0pt;color:black;font-weight:700;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>ID</td>
 <td class=xl72 width=95 style='border-left:none;width:71pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>H&#7885;
 và tên</td>
 <td class=xl72 width=79 style='border-left:none;width:59pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Gi&#7899;i
 tính</td>
 <td class=xl72 width=81 style='border-left:none;width:61pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>N&#259;m
 sinh</td>
 <td class=xl72 width=105 style='border-left:none;width:79pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>S&#7889;
 &#273;i&#7879;n tho&#7841;i</td>
 <td class=xl72 width=112 style='border-left:none;width:84pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Mã
 BN/Mã NC</td>
 <td class=xl72 width=46 style='border-left:none;width:35pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>S&#7889;t</td>
 <td class=xl72 width=155 style='border-left:none;width:116pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Không
 &#273;i ngoài &#273;&#432;&#7907;c</td>
 <td class=xl72 width=162 style='border-left:none;width:122pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Không
 &#273;ánh h&#417;i &#273;&#432;&#7907;c</td>
 <td class=xl72 width=88 style='border-left:none;width:66pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Nôn
 nhi&#7873;u</td>
 <td class=xl72 width=215 style='border-left:none;width:161pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>C&#259;ng
 c&#7913;ng b&#7909;ng, ch&#432;&#7899;ng b&#7909;ng</td>
 <td class=xl72 width=216 style='border-left:none;width:162pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>&#272;au
 b&#7909;ng d&#7919; d&#7897;i nh&#432; dao &#273;âm</td>
 <td class=xl72 width=81 style='border-left:none;width:61pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Tiêu
 ch&#7843;y</td>
 <td class=xl72 width=225 style='border-left:none;width:169pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>D&#7883;
 &#7913;ng (thu&#7889;c, th&#7913;c &#259;n, th&#7901;i ti&#7871;t)</td>
 <td class=xl72 width=132 style='border-left:none;width:99pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Suy
 tim/gan/th&#7853;n</td>
 <td class=xl72 width=369 style='border-left:none;width:277pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>T&#259;ng
 huy&#7871;t áp n&#7863;ng, b&#7879;nh m&#7841;ch vành, r&#7889;i lo&#7841;n
 nh&#7883;p tim</td>
 <td class=xl72 width=123 style='border-left:none;width:92pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>&#272;ái
 tháo &#273;&#432;&#7901;ng</td>
 <td class=xl72 width=133 style='border-left:none;width:100pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Ung
 th&#432; &#273;&#7841;i tràng</td>
 <td class=xl72 width=110 style='border-left:none;width:83pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Hen
 ph&#7871; qu&#7843;n</td>
 <td class=xl72 width=150 style='border-left:none;width:113pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Thu&#7889;c
 tâm th&#7847;n kinh</td>
 <td class=xl72 width=131 style='border-left:none;width:98pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Thu&#7889;c
 gây nghi&#7879;n</td>
 <td class=xl72 width=137 style='border-left:none;width:103pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Thu&#7889;c
 ch&#7889;ng &#273;ông</td>
 <td class=xl72 width=99 style='border-left:none;width:74pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>&#272;ang
 có thai</td>
 <td class=xl72 width=213 style='border-left:none;width:160pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>&#272;ang
 trong chu k&#7923; kinh nguy&#7879;t</td>
 <td class=xl72 width=139 style='border-left:none;width:104pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>&#272;&#7911;
 &#273;i&#7873;u ki&#7879;n u&#7889;ng thu&#7889;c t&#7841;i nhà</td>
 <td class=xl72 width=85 style='border-left:none;width:64pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>&#272;&#7883;a
 &#273;i&#7875;m khám</td>
 <td class=xl72 width=115 style='border-left:none;width:86pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Supporter<span
 style='mso-spacerun:yes'> </span></td>
 <td class=xl72 width=128 style='border-left:none;width:96pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>&#272;&#7883;a
 &#273;i&#7875;m u&#7889;ng thu&#7889;c</td>
 <td class=xl72 width=116 style='border-left:none;width:87pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Phác
 &#273;&#7891; u&#7889;ng thu&#7889;c</td>
 <td class=xl72 width=120 style='border-left:none;width:90pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Ngày
 b&#7855;t &#273;&#7847;u u&#7889;ng thu&#7889;c</td>
 <td class=xl72 width=132 style='border-left:none;width:99pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Th&#7901;i
 gian b&#7855;t &#273;&#7847;u u&#7889;ng thu&#7889;c</td>
 <td class=xl72 width=155 style='border-left:none;width:116pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Th&#7901;i
 gian u&#7889;ng thu&#7889;c b&#432;&#7899;c 1 (phút)</td>
 <td class=xl72 width=155 style='border-left:none;width:116pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Th&#7901;i
 gian u&#7889;ng thu&#7889;c b&#432;&#7899;c 2 (phút)</td>
 <td class=xl72 width=155 style='border-left:none;width:116pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Th&#7901;i
 gian u&#7889;ng thu&#7889;c b&#432;&#7899;c 3 (phút)</td>
 <td class=xl72 width=155 style='border-left:none;width:116pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Th&#7901;i
 gian u&#7889;ng thu&#7889;c b&#432;&#7899;c 4 (phút)</td>
 <td class=xl72 width=155 style='border-left:none;width:116pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Th&#7901;i
 gian u&#7889;ng thu&#7889;c b&#432;&#7899;c 5 (phút)</td>
 <td class=xl72 width=155 style='border-left:none;width:116pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>Th&#7901;i
 gian u&#7889;ng thu&#7889;c b&#432;&#7899;c 6 (phút)</td>
 <td class=xl72 width=149 style='border-left:none;width:112pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>T&#7893;ng
 th&#7901;i gian u&#7889;ng thu&#7889;c (phút)</td>
 <td class=xl72 width=129 style='border-left:none;width:97pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>&#272;ánh
 giá m&#7913;c &#273;&#7897; s&#7841;ch l&#7847;n 1</td>
 <td class=xl72 width=129 style='border-left:none;width:97pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>&#272;ánh
 giá m&#7913;c &#273;&#7897; s&#7841;ch l&#7847;n 2</td>
 <td class=xl72 width=124 style='border-left:none;width:93pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>S&#7889;
 l&#7847;n &#273;i v&#7879; sinh<span style='mso-spacerun:yes'> </span></td>
 <td class=xl73 width=73 style='border-left:none;width:55pt;font-size:11.0pt;
 color:black;font-weight:700;text-decoration:none;text-underline-style:none;
 text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>&#272;&#7911;
 &#273;i&#7873;u ki&#7879;n soi</td>
</tr>

<tr class=xl66 height=21 style='height:15.75pt'
*ngFor="let e of patientList" 
>
 <td height=21 class=xl68 width=50 style='height:15.75pt;border-top:none;
 width:38pt;font-size:11.0pt;color:black;font-weight:400;text-decoration:none;
 text-underline-style:none;text-line-through:none;font-family:Calibri;
 border:.5pt solid windowtext'>{{e.idx}}</td>
 <td class=xl69 width=95 style='border-top:none;border-left:none;width:71pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{e.user_name}}</td>
 <td class=xl69 width=79 style='border-top:none;border-left:none;width:59pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{e.gioitinh}}</td>
 <td class=xl69 width=81 style='border-top:none;border-left:none;width:61pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{e.date_of_birth}}</td>
 <td class=xl69 width=105 style='border-top:none;border-left:none;width:79pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{e.telephone}}</td>
 <td class=xl69 width=112 style='border-top:none;border-left:none;width:84pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{e.ext_id}}</td>
 <td class=xl69 width=46 style='border-top:none;border-left:none;width:35pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,0)}}<span
 style='mso-spacerun:yes'> </span></td>
 <td class=xl69 width=155 style='border-top:none;border-left:none;width:116pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,1)}}<span
 style='mso-spacerun:yes'> </span></td>
 <td class=xl69 width=162 style='border-top:none;border-left:none;width:122pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,2)}}</td>
 <td class=xl69 width=88 style='border-top:none;border-left:none;width:66pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,3)}}</td>
 <td class=xl69 width=215 style='border-top:none;border-left:none;width:161pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,4)}}</td>
 <td class=xl69 width=216 style='border-top:none;border-left:none;width:162pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,5)}}</td>
 <td class=xl69 width=81 style='border-top:none;border-left:none;width:61pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,6)}}</td>
 <td class=xl69 width=225 style='border-top:none;border-left:none;width:169pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,7)}}</td>
 <td class=xl69 width=132 style='border-top:none;border-left:none;width:99pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,8)}}</td>
 <td class=xl69 width=369 style='border-top:none;border-left:none;width:277pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,9)}}</td>
 <td class=xl69 width=123 style='border-top:none;border-left:none;width:92pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,10)}}</td>
 <td class=xl69 width=133 style='border-top:none;border-left:none;width:100pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,11)}}</td>
 <td class=xl69 width=110 style='border-top:none;border-left:none;width:83pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,12)}}</td>
 <td class=xl69 width=150 style='border-top:none;border-left:none;width:113pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,13)}}</td>
 <td class=xl69 width=131 style='border-top:none;border-left:none;width:98pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,14)}}</td>
 <td class=xl69 width=137 style='border-top:none;border-left:none;width:103pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns(e,15)}}</td>
 <td class=xl69 width=99 style='border-top:none;border-left:none;width:74pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns1(e,1,"Không áp d&#7909;ng")}}</td>
 <td class=xl69 width=213 style='border-top:none;border-left:none;width:160pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAns1(e,1,"Không áp d&#7909;ng")}}</td>
 <td class=xl69 width=139 style='border-top:none;border-left:none;width:104pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getAllowProcess(e)}}</td>
 <td class=xl69 width=85 style='border-top:none;border-left:none;width:64pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{e.phongkham}}</td>
 <td class=xl69 width=115 style='border-top:none;border-left:none;width:86pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{e.supporter}}</td>
 <td class=xl69 width=128 style='border-top:none;border-left:none;width:96pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{e.regimen_where_show}}</td>
 <td class=xl69 width=116 style='border-top:none;border-left:none;width:87pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getRegimenName(e.regimen_id)}}</td>
 <td class=xl69 width=120 style='border-top:none;border-left:none;width:90pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getStartDate(e)}}</td>
 <td class=xl70 width=132 style='border-top:none;border-left:none;width:99pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getStartTime(e)}}</td>
 <td class=xl69 width=155 style='border-top:none;border-left:none;width:116pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getProcessTime(e,0)}}</td>
 <td class=xl69 width=155 style='border-top:none;border-left:none;width:116pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getProcessTime(e,1)}}</td>
 <td class=xl69 width=155 style='border-top:none;border-left:none;width:116pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getProcessTime(e,2)}}</td>
 <td class=xl69 width=155 style='border-top:none;border-left:none;width:116pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getProcessTime(e,3)}}</td>
 <td class=xl69 width=155 style='border-top:none;border-left:none;width:116pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getProcessTime(e,4)}}</td>
 <td class=xl69 width=155 style='border-top:none;border-left:none;width:116pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getProcessTime(e,5)}}</td>
 <td class=xl69 width=149 style='border-top:none;border-left:none;width:112pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getTotalTime(e)}}</td>
 <td class=xl69 width=129 style='border-top:none;border-left:none;width:97pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getConfirm(e,0)}}</td>
 <td class=xl69 width=129 style='border-top:none;border-left:none;width:97pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{getConfirm(e,1)}}</td>
 <td class=xl69 width=124 style='border-top:none;border-left:none;width:93pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{e.defecation}}</td>
 <td class=xl71 width=73 style='border-top:none;border-left:none;width:55pt;
 font-size:11.0pt;color:black;font-weight:400;text-decoration:none;text-underline-style:
 none;text-line-through:none;font-family:Calibri;border:.5pt solid windowtext'>{{ allowEndoscopy(e) }}</td>
</tr>

</table>
</div>
</div>   

