import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { UserProfileService } from "../user-profile.service";
import { MatSort } from "@angular/material/sort";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { checkTimeOut } from "../utils";
import { Router } from "@angular/router";

@Component({
  selector: "app-article-man",
  templateUrl: "./article-man.component.html",
  styleUrls: ["./article-man.component.scss"],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ArticleManComponent implements OnInit {
  displayedColumns: string[] = ['idx','title','url'];
  posts = [];
  loadData: any;
  dataSource = new MatTableDataSource<PostItem>(this.posts);
  expandedElement: PostItem | null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private userProfileService: UserProfileService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getPostList();
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
   }


  getPostList() {
    this.posts = [];

    var filterType = "0";

    this.userProfileService.getPostList().subscribe((data: []) => {
      // console.log("Data ", JSON.stringify(data));
      var idx = 0;

      this.loadData = data;

      data.forEach((e) => {
        idx++;
        var obj = new PostItem();
        obj = e;
        obj["idx"] = idx;
        this.posts.push(obj);
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>:"+JSON.stringify(obj));
      });

      this.dataSource.data = this.posts;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

export class PostItem {
  id: number;
  title: string;
  url: string;
  contentHtml: string;
}
