import { Component, OnInit , Inject} from '@angular/core';
import {UserProfileService} from '../user-profile.service';
import {Location} from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as myGlobal from '../globals'; 
import { CdkStepperNext } from '@angular/cdk/stepper';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {checkTimeOut} from '../utils';

@Component({
  selector: 'app-message-editor',
  templateUrl: './message-editor.component.html',
  styleUrls: ['./message-editor.component.scss']
})
export class MessageEditorComponent implements OnInit {
  msgId = '0';

  msgData:PeriodicElement = null;

  userForm: FormGroup;

  submitted = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private userProfileService:UserProfileService,
    private formBuilder: FormBuilder,        
      public dialog: MatDialog

  ) { }

  ngOnInit() {
    this.msgId = this.route.snapshot.paramMap.get('msgId');

    this.userForm = this.formBuilder.group({      
      title: ['', Validators.required],
      tag: ['', Validators.nullValidator],
      desc: ['', Validators.required],
  		content: ['', Validators.nullValidator],
  		created_at: ['', Validators.nullValidator],      
      updated_at: ['', Validators.nullValidator],
      
    });

    this.getNotifyGlobalsById(this.msgId )

  }

  getNotifyGlobalsById(msgId) {
    this.msgData = null;
    this.userProfileService.getNotifyGlobalsById(msgId).subscribe((data) => {
      this.msgData = data;     
      
      this.userForm.controls.title.setValue(  this.msgData['title']);
      this.userForm.controls.tag.setValue(  this.msgData['tag']);
      this.userForm.controls.desc.setValue(  this.msgData['desc']);
      this.userForm.controls.content.setValue(  this.msgData['content']);
      this.userForm.controls.created_at.setValue(  this.msgData['created_at']);
      this.userForm.controls.updated_at.setValue(  this.msgData['updated_at']);
    });   
  };

  invalidTitle() {  return (this.submitted && this.userForm.controls.title.errors != null);};
  invalidTag() { return (this.submitted && this.userForm.controls.tag.errors != null);};
  invalidContent() { return (this.submitted && this.userForm.controls.desc.errors != null);};
  invalidDesc() { return (this.submitted && this.userForm.controls.content.errors != null);};
  invalidCreatedAt() { return (this.submitted && this.userForm.controls.created_at.errors != null);};
  invalidUpdatedAt() { return (this.submitted && this.userForm.controls.updated_at.errors != null);};


  onGoBack()
  {  
    this._location.back();
  }
  
  onUpdate() {
    this.submitted = true;
    if(this.userForm.invalid == true || this.msgId=='0')
  	{
      
  		return;
  	}
    
    var newMsgData = new PeriodicElement();

    newMsgData.id = parseInt(this.msgId);
    newMsgData.title = this.userForm.controls.title.value;
    newMsgData.tag = this.userForm.controls.tag.value;
    newMsgData.desc = this.userForm.controls.desc.value;
    newMsgData.content = this.userForm.controls.content.value;

    this.userProfileService.updateNotifyGlobalsById(this.msgId,newMsgData)
      .subscribe({next:(result) => {
        if (!checkTimeOut(result,this.router)) {return;}
        console.log('return ' + result);
        this.ngOnInit();
        
        alert('Cập nhật thành công!');
        
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});
    
    this.userProfileService.sysLog('Message','update',newMsgData.id + '-' + newMsgData.title);
  };

  onAddNew() {

    this.submitted = true;
    if(this.userForm.invalid == true || this.msgId=='0')
  	{
  		return;
  	}
    
    var newMsgData = new PeriodicElement();

    //newMsgData.id = parseInt(this.msgId);
    newMsgData.title = this.userForm.controls.title.value;
    newMsgData.tag = this.userForm.controls.tag.value;
    newMsgData.desc = this.userForm.controls.desc.value;
    newMsgData.content = this.userForm.controls.content.value;

    this.userProfileService.addNotifyGlobals(newMsgData)
      .subscribe({next:(result) => {
        if (!checkTimeOut(result,this.router)) {return;}
        console.log('return ' + result);
        alert('Tạo mới thành công!');
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});

      this.userProfileService.sysLog('Message','add new',newMsgData.id + '-' + newMsgData.title);
  }

}

export class PeriodicElement {
  position: number;
  id : number;
  title : string;
  tag: string;
  desc: string;
  content: string;
  created_at: string;  
  updated_at : string;
  userType: string;
    
}
