<nav class="navbar navbar-expand-lg  navbar-dark bg-primary" >
  <a class="navbar-brand" href="#"><i class="fa fa-cogs "></i> IGH GuideApp</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" >
    <ul class="navbar-nav mr-auto">
      
        

      <li class="nav-item dropdown"  *ngIf="supporter">
        <a class="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Quản lý bệnh nhân
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item " href="./patient-monitor"><i class="fa fa-heartbeat"></i>&nbsp; Theo dõi trạng thái</a>   
          
          <div class="dropdown-divider"></div>
          <a class="dropdown-item " href="./patient-search"><i class="fa fa-search"></i>&nbsp; Tìm kiếm nhanh</a>    
          <a class="dropdown-item " href="./patient-list"><i class="fa fa-user-md"></i>&nbsp; Danh sách bệnh nhân</a>     
        </div>
      </li>

      <li class="nav-item dropdown" *ngIf="sysadmin">
        <a class="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Quản lý phác đồ
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item " href="./filterquest-man"><i class="fa fa-heartbeat"></i>&nbsp; Quản lý câu hỏi sàng lọc</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item " href="./regimen-man"> 	<i class="fa fa-map-o"></i>&nbsp; Quản lý phác đồ</a>
          <a class="dropdown-item " href="./afterprocess-man"><i class="fa fa-medkit"></i>&nbsp; Quản lý hỗ trợ sau nội soi</a>
        </div>      
      </li>
      <li class="nav-item dropdown" *ngIf="editor">
        <a class="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Quản lý thông tin
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item " href="./article-man"> <i class="fa fa-file-text-o"></i>&nbsp; Quản lý bài viết</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item " href="./message-man"><i class="fa fa-file-video-o"></i>&nbsp; Quản lý thông báo</a>          
        </div>      
      </li>

      <li class="nav-item dropdown" *ngIf="sysadmin">
        <a class="nav-link dropdown-toggle active" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Quản trị hệ thống
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          
          <a class="dropdown-item " href="./data-export"><i class="fa fa fa-map-o"></i>&nbsp; Export số  liệu</a> 
          <div class="dropdown-divider"></div>
          <a class="dropdown-item " href="./system-log"><i class="fa fa-wrench"></i>&nbsp; Nhật ký hệ thống</a>    
          <div class="dropdown-divider"></div>
          <a class="dropdown-item " href="./user-man"><i class="fa fa-users"></i>&nbsp; Quản trị người dùng</a>     
        </div>      
      </li>

      <li class="nav-item active" *ngIf="currentUser">
        <a class="nav-link " href="./user-profile" tabindex="-1" aria-disabled="false">
          <i class="fa fa-user-circle"></i> Người dùng</a>
      </li>

      <li class="nav-item active" *ngIf="currentUser">
        <a class="nav-link" (click)="logout()"  tabindex="-1" aria-disabled="false">
          <i class="fa fa-sign-out"></i>  Thoát
        </a>
      </li>

      <li class="nav-item active" *ngIf="!currentUser">
        <a class="nav-link" (click)="login()"  tabindex="-1" aria-disabled="false">
          <i class="fa fa-sign-in"></i>  Đăng nhập
        </a>
      </li>

    </ul>
    <form class="form-inline my-2 my-lg-0" [formGroup]="userForm">      
      
      <input type="text" formControlName="searchData" class="form-control mr-sm-2" id="searchData" name="searchData" placeholder="Search">  
      
      <button class="btn btn-outline-white my-2 my-sm-0" type="submit" (click)="doSearch()"><i class="fa fa-search"></i></button>
    </form>
    

    
    
  </div>
</nav>
<br>
<!------------------------------------------->
<router-outlet>
</router-outlet>
<app-size-detector-component></app-size-detector-component>
 





