import { Component, OnInit } from '@angular/core';
import { TableUtil } from '../tableUtil';
import * as myGlobal from '../globals';
import * as moment from 'moment';
import { UserProfileService } from '../user-profile.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatTableDataSource } from "@angular/material/table";
//import { moveMessagePortToContext } from 'worker_threads';

@Component({
  selector: 'app-data-export',
  templateUrl: './data-export.component.html',
  styleUrls: ['./data-export.component.scss']
})
export class DataExportComponent implements OnInit {

  userForm: FormGroup;

  patientList: any = [];
  regimenMap = new Map();

  //dataSource = new MatTableDataSource<PatientElement>(this.patientList);

  constructor(
    private usrProfileService: UserProfileService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router
  ) {

    this.userForm = this.formBuilder.group({
      fromDate: ["", Validators.required],
      toDate: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.usrProfileService.getRegimenList('0').subscribe({
      next: (data: any) => {
        data.forEach(e => {
          this.regimenMap.set(e.id, e);
        });
      }, error: err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }
    });

  }


  dateEvent(type: string, event: MatDatepickerInputEvent<Date>) {


    this.doRequery();

  }

  exportTable() {
    TableUtil.exportToExcel("tblReport");
  }

  getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return day + month + year;
  }

  getAns(obj, index) {
    return this.getAns1(obj, index, "Không");
  }

  getAns1(obj, index, defaultRet) {
    if (obj == undefined || index == undefined) {
      return "Không";
    }
    if (obj.ans == undefined) {
      return "Không";
    }
    let ans = obj['ans'];
    if (ans[index] == undefined) {
      if (defaultRet == undefined) return "Không";
      return defaultRet;
    }
    console.log("return " + ans[index]);
    return ans[index] == false ? "Không" : "Có";
  }

  getAllowProcess(obj) {
    if (obj == undefined) {
      return "Không";
    }
    if (obj.ans == undefined) {
      return "Không";
    }
    return obj.ans.find(element => element === true) >= 0 ? "Không" : "Có";
  }

  getStartDate(obj) {
    if (obj == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj[0] == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj[0].start_time == undefined) {
      return "Chưa thực hiện";
    }

    let startTime = obj.stepTimeObj[0].start_time;
    return new Date(startTime).toISOString().split("T")[0];
  }

  getStartTime(obj) {
    if (obj == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj[0] == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj[0].start_time == undefined) {
      return "Chưa thực hiện";
    }
    let startTime = obj.stepTimeObj[0].start_time;
    return moment(startTime).format('HH:mm:ss');
    // return new Date(startTime).toISOString().split("T")[1];
  }

  getTimeStepBegin(obj, index) {
    if (obj == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj[index] == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj[index].start_time == undefined) {
      return "Chưa thực hiện";
    }
    return new Date(obj.stepTimeObj[index].start_time);
  }
  getTimeStepEnd(obj, index) {
    if (obj == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj[index] == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj[index].end_time == undefined) {
      return "Chưa thực hiện";
    }
    return new Date(obj.stepTimeObj[index].end_time);
  }

  getRegimenName(id) {
    if (this.regimenMap.get(id) == undefined) {
      return "N/A " + id;
    }
    return this.regimenMap.get(id).name;
  }

  getProcessTime(obj, index) {
    if (obj == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj[index] == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj[index].end_time == undefined) {
      return "Chưa xong";
    }
    return (obj.stepTimeObj[index].end_time - obj.stepTimeObj[index].start_time) / (1000 * 60);
  }

  getTotalTime(obj) {
    if (obj == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj[0] == undefined) {
      return "Chưa thực hiện";
    }
    if (obj.stepTimeObj[0].start_time == undefined) {
      return "Chưa thực hiện";
    }
    let startTime = obj.stepTimeObj[0].start_time;
    if (startTime == undefined) {
      return "Chưa thực hiện";
    }
    let endTime = obj.stepTimeObj[obj.stepTimeObj.length - 1].end_time;
    if (endTime == undefined) {
      endTime = obj.stepTimeObj[obj.stepTimeObj.length - 1].start_time;
    }
    if (endTime == undefined) {
	return "Chưa thực hiện";
    }
    return (endTime - startTime) / (1000 * 60);
  }

  getConfirm(obj, index) {
    if (obj["answerValue"] == undefined) {
      return "Chưa hoàn thành";
    }
    if (obj["answerValue"][index] == undefined) {
      return "Chưa hoàn thành";
    }
    return "Hình " + (obj["answerValue"][index]);
  }

  allowEndoscopy(e) {
    if (e.defecation < 5) {
      return "Không";
    }
    if (e.defecation > 5 && e["answerValue"] != undefined && e["answerValue"][1] != undefined && e["answerValue"][1] <= 1) {
      return "Có";
    }
    if (e.defecation > 5 && e["answerValue"] != undefined && e["answerValue"][1] == undefined
      && e["answerValue"][0] != undefined && e["answerValue"][0] <= 1) {
      return "Có";
    }
    return "Không";
  }

  doRequery() {


    if (this.userForm.controls.toDate.value &&
      this.userForm.controls.fromDate.value) {

      var fd = new Date(this.userForm.controls.fromDate.value);
      var td = new Date(this.userForm.controls.toDate.value);

      //this.patientList = [];
      var url = 'patient-lastest-regimen/' + this.getFormattedDate(fd) + '/' + this.getFormattedDate(td);
      this.usrProfileService.getDataFromApi2Url(url)
        .subscribe({
          next: (data: []) => {
            let idx = 0;

            //console.log(data);

            this.patientList = [];
            data.forEach((e) => {
              idx++;
              let obj = new PatientElement();
              obj = e;
              obj.idx = idx;
              //HaBM: fix loi convert sai ngay thang nam
              //obj.date_of_birth = (e["date_of_birth"] + "").substring(0, 4);
              obj.date_of_birth = moment(e["date_of_birth"]).format('YYYY');

              if (obj["gender"] == "0") {
                obj["gioitinh"] = "Nữ";
              } else if (obj["gender"] == "1") {
                obj["gioitinh"] = "Nam";
              } else {
                obj["gioitinh"] = "-";
              }
              //HaBM: thay doi ten phong kham
              if (obj["location_id"] == "1") {
                obj["phongkham"] = "PK 1";
              } else {
                obj["phongkham"] = "PK 2";
              }

              if (obj["regimen_where"] == "clinic") {
                obj["regimen_where_show"] = "PK";
              } else if (obj["regimen_where"] == "home") {
                obj["regimen_where_show"] = "Nha";
              } else {
                obj["regimen_where_show"] = "";
              }

              //obj['starttime'] = '20/02/2020 10:20:30';
              //obj["supporter"] = "supporter";

              if (obj["other_data"]) {
                obj.otherdataObj = JSON.parse(obj["other_data"]);
                if (obj.otherdataObj.answer) {
                  var ans = [];
                  obj.otherdataObj.answer.forEach((subSlice) => {
                    ans = ans.concat(subSlice);
                  });
                  obj["ans"] = ans;
                }
                if (obj.otherdataObj.confirmRegimen != undefined) {
                  obj["defecation"] = obj.otherdataObj.confirmRegimen.defecation;
                  obj["answerValue"] = obj.otherdataObj.confirmRegimen.answerValue;
                }
              }
              if (obj["step_timing"]) {
                obj.stepTimeObj = JSON.parse(obj["step_timing"]);
              }
              this.patientList.push(obj);
            });

            // this.dataSource.data = this.patientList;

          }, error: err => {
            //console.error('Observer got an error: ' + JSON.stringify(err));
            alert('Lỗi kết nối mạng! ');
          }
        });
    }
  }
}

class PatientElement {
  idx: number;
  patient_id: number;
  telephone: string;
  gender: string;
  gioitinh: string;
  date_of_birth: string;
  remark: string;
  email: string;
  created_date: string;
  updated_date: string;
  status: string;
  user_name: string;
  lastest_patient_regimen_id: number;
  patient_regimen_id: number;
  regimen_id: string;
  active_code: string;
  state: number;
  step_timing: string;
  start_time: string;
  regimen_where: string;
  regimen_where_show: string;
  doctor_name: string;
  state_str: string;
  color: string;
  updated_date_str: string;
  tooltip: string;
  other_data: string;
  otherdataObj: any;
  stepTimeObj: any;

  ext_id: string;
  supporter: string;
  phongkham: string;
}
