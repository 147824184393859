<div class="container-lg">
    <h4 class="text-primary">Danh sách bệnh nhân</h4>

  <div class="row">    
    
    <mat-form-field>
      <mat-label>Lọc tìm kiếm</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Lọc theo">
    </mat-form-field>

    
    <table mat-table [dataSource]="dataSource" class="table table-bordred table-striped" matSort multiTemplateDataRows>

      <!-- Position Column -->
      <ng-container matColumnDef="idx">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Số thứ tự </th>
        <td mat-cell *matCellDef="let element"> {{element.idx}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Trạng thái </th>
        <td mat-cell *matCellDef="let element">
          <i *ngIf="element.status=='1'" class="fa fa-check text-success"></i> 
          <i *ngIf="element.status!='1'" class="fa fa-bolt text-danger"></i>                       
        </td>
      </ng-container>
  
      
       <ng-container matColumnDef="patient_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Mã ID</th>
        <td mat-cell *matCellDef="let element"> {{element.patient_id}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="telephone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Điện thoại </th>
        <td mat-cell *matCellDef="let element"> {{element.telephone}} </td>
      </ng-container>

      <ng-container matColumnDef="user_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Họ tên </th>
        <td mat-cell *matCellDef="let element"> {{element.user_name}} </td>
      </ng-container>

      <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Giới tính </th>
        <td mat-cell *matCellDef="let element"> {{element.gioitinh}} </td>
      </ng-container>
      <ng-container matColumnDef="dob">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Năm sinh </th>
        <td mat-cell *matCellDef="let element"> {{element.date_of_birth}} </td>
      </ng-container>

      
      
      <ng-container matColumnDef="remark">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ghi chú </th>
        <td mat-cell *matCellDef="let element"> {{element.remark}} </td>
      </ng-container>

      <ng-container matColumnDef="created_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ngày đăng ký </th>
        <td mat-cell *matCellDef="let element"> {{element.created_date}} </td>
      </ng-container>

      <ng-container matColumnDef="updated_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ngày cập nhật </th>
        <td mat-cell *matCellDef="let element"> {{element.updated_date}} </td>
      </ng-container>

      
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="8">
          
          <div class="example-element-detail"
               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">          
               <div class="container">                
               <div class="row">
                <div class="col-sm-6">
                  Tên : {{element.user_name}} &nbsp;<br>                  
                  Điện thoại : {{element.telephone}}  &nbsp;<br>  
                  Giới tính : {{element.gender}} &nbsp;<br>
                  Ngày sinh : {{element.date_of_birth}} &nbsp;<br>                 
                  Trạng thái : {{element.status}} &nbsp;<br>
                  Ghi chú: {{element.remark}} &nbsp;<br>
              </div>            
              <div class="col-sm-6">
                <a routerLink="/patient/{{element.patient_id}}">
                  <button type="submit" class="btn btn-primary" >
                    <i class="fa fa-check"></i> Thông tin chi tiết</button> 
                  </a><br>
                  
                <!-- <button mat-raised-button class="btn btn-warning">Thay đổi mật khẩu</button> -->
              </div>
               </div>
            </div>
          </div>

        </td>
      </ng-container>

   
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <!-- tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr -->

      <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    </table>
  
    <mat-paginator [pageSizeOptions]="[10,20,50]" showFirstLastButtons></mat-paginator>
  
</div>
</div>
