<div class="container-lg">
    <h4 class="text-primary">Câu hỏi sàng lọc</h4>

  <div class="row" *ngFor="let quest of quest_list">   
      <div class="col">
    <mat-card  class="mat-elevation-z8">
        <mat-card-header>  
          <div class="ib" (click)="onSubQuestEdit(quest,null)" title="Sửa"><i class="fa fa-cog text-primary"></i> </div>&nbsp;
          <div class="ib" (click)="onSubQuestAdd(quest,null)" title="Thêm câu hỏi con" *ngIf="quest['ids'].length==0"><i class="fa fa-plus text-success"></i></div>&nbsp;
          <strong> {{quest.quest}} </strong></mat-card-header>

        <mat-card-content style="justify-content: center">
          <div class="subquest">
          <table class="table_subquest">
            <tr *ngFor="let subquest of quest.ids" class="tr_subquest">
              <td> 
                  {{subquest.quest}} &nbsp;
              </td>
              <td class="td_subquest">
                <div class="ib" (click)="onSubQuestEdit(subquest,quest)" title="Sửa"><i class="fa fa-cog text-primary"></i></div>&nbsp;
                <div class="ib" (click)="onSubQuestAdd(quest,subquest)" title="Chèn thêm câu hỏi"><i class="fa fa-plus text-success"></i></div>&nbsp;
                <div class="ib"                                                        *ngIf="subquest['pos']==1"><i class="fa fa-arrow-up " style="color:lightgray"></i></div>
                <div class="ib" (click)="onSubQuestUp(quest,subquest)" title="Lên" *ngIf="subquest['pos']!=1"><i class="fa fa-arrow-up " style="color:burlywood"></i></div>&nbsp;
                <div class="ib"                                                        *ngIf="subquest['pos']==2"><i class="fa fa-arrow-down "  style="color:lightgray"></i></div>
                <div class="ib" (click)="onSubQuestDown(quest,subquest)" title="Xuống" *ngIf="subquest['pos']!=2"><i class="fa fa-arrow-down "  style="color:burlywood"></i></div>&nbsp;
                <div class="ib" (click)="onSubQuestDelete(quest,subquest)"  title="Xóa câu hỏi con"><i class="fa fa-close text-danger"></i></div>&nbsp;
                
              </td>
            </tr>
          </table>
        </div>
        </mat-card-content>       
        <mat-card-actions class="card-action" >
            <button mat-button class="btn btn-primary" (click)="onAddNewSession()" title="Thêm một câu hỏi chính"><i class="fa fa-plus"></i></button>&nbsp;
            <button mat-button class="btn btn-warning" (click)="onMoveUpSession(quest)"  title="Lên" *ngIf="quest['pos']!=1"><i class="fa fa-arrow-up"></i></button>&nbsp;
            <button mat-button class="btn btn-warning" (click)="onMoveDownSession(quest)"  title="Xuống" *ngIf="quest['pos']!=2"><i class="fa fa-arrow-down"></i></button>&nbsp;
            <button mat-button class="btn btn-danger" (click)="onRemoveSession(quest)"  title="Xóa câu hỏi chính này"><i class="fa fa-close"></i></button>&nbsp;
            <br>&nbsp;
          </mat-card-actions>     
    </mat-card>

    <br>
    </div>
    </div>

    <button mat-button class="btn btn-primary" (click)="onAddNewSession()" *ngIf="quest_list.length==0"><i class="fa fa-arrow-down"></i> Thêm câu hỏi</button>&nbsp;
 </div>
<hr>
