import { Component, OnInit } from '@angular/core';
import * as myGlobal from '../globals'; 
import {UserProfileService} from '../user-profile.service';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {checkTimeOut} from '../utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-device-manager',
  templateUrl: './device-manager.component.html',
  styleUrls: ['./device-manager.component.scss']
})
export class DeviceManagerComponent implements OnInit {
  
  public rootOrganData = {'org_name':''};

  constructor(private userProfileService: UserProfileService,
          private router: Router) { }

  getOrgId() {
    let data = JSON.parse(localStorage.getItem('currentUser'));
    return data.org_id;
  }

  ngOnInit() {
    this.getOrgData(this.getOrgId(),0);
  }

  getOrgData(orgId,rootOrgId): void {
    this.userProfileService.getOrgData(orgId,rootOrgId)
        .subscribe(data => {
          this.rootOrganData = data[0];
          console.log("Call getOrgData!");
          //console.log(data);
         
        });
  }

}
