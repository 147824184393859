import { Component, OnInit ,Inject } from '@angular/core';
import {Location} from '@angular/common';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as myGlobal from '../globals'; 
import {UserProfileService} from '../user-profile.service';
//import { CdkStepperNext } from '@angular/cdk/stepper';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {checkTimeOut} from '../utils';


export interface DialogData {
  org_name: string;
  org_desc: string;
}


@Component({
  selector: 'app-organization-data',
  templateUrl: './organization-data.component.html',
  styleUrls: ['./organization-data.component.scss']
})
export class OrganizationDataComponent implements OnInit {

  userForm: FormGroup;
  submitted = false;
  debug_data = '';
  rootOrgId = '0';
  orgId = '0';

  orginData = [];
  rootOrganData = [];

  orgTypeName = "Cong ty";
  orgTypeId  = 1;
  orgTypeList = [];
  
  cmd ="edit";
  parentOrgId = "0";


  getOrgId() {
    let data = JSON.parse(localStorage.getItem('currentUser'));
    return data.org_id;
  }


  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,  
    private usrProfileService:UserProfileService,
    private _location: Location,
    public dialog: MatDialog,
   // private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit() {
    this.cmd = this.route.snapshot.paramMap.get('cmd');
    this.orgId = this.route.snapshot.paramMap.get('orgId');

    this.getOrgTypeList();

    if (this.cmd=="new") {

      this.orgId = "-1";
      this.rootOrgId = this.getOrgId();
      this.parentOrgId = this.route.snapshot.paramMap.get('orgId');
      if (this.parentOrgId=="0") {
        this.parentOrgId =  this.getOrgId();
      }
      this.orgTypeId  = 2;

      this.userForm = this.formBuilder.group({
        org_id: [this.orgId, Validators.required],
        //org_type: [this.orgTypeName, Validators.required],
        orgTypeCmb: [this.orgTypeId, Validators.required],
        org_name: ['', Validators.required],
        short_name: ['', Validators.required],
        desc: ['', null],  	      
        address: ['', Validators.required],        	
        contact: ['', Validators.required],        	
        telephone: ['', Validators.required],
        email : ['',null]
      });

    } else {
      // Neu ==0 -> Cong ty
      if (this.orgId=="0") {
        this.orgId = this.getOrgId();
        this.rootOrgId = "0";
        this.orgTypeName = "Cong ty";
        this.orgTypeId  = 1;

        this.userForm = this.formBuilder.group({
          org_id: [this.orgId, Validators.required],
          //org_type: [this.orgTypeName, Validators.required],
          orgTypeCmb: [this.orgTypeId, Validators.required],
          org_name: ['', Validators.required],
          short_name: ['', Validators.required],
          desc: ['', null],  	      
          address: ['', Validators.required],        	
          contact: ['', Validators.required],        	
          telephone: ['', Validators.required],
          email : ['',null]
        });
      } else  {

        this.userForm = this.formBuilder.group({
          org_id: [this.orgId, Validators.required],
          //org_type: [this.orgTypeName, Validators.required],
          orgTypeCmb: [this.orgTypeId, Validators.required],
          org_name: ['', Validators.required],
          short_name: ['', Validators.required],
          desc: ['', null],  	      
          address: ['', null],        	
          contact: ['', null],        	
          telephone: ['', null],
          email : ['',null]
        });
      }
      this.getOrgData(this.orgId, this.rootOrgId);
    }
   
  }

   public clear() {
    this.userForm.controls.org_name.setValue(null);
    this.userForm.controls.short_name.setValue(null);
    this.userForm.controls.desc.setValue(null);
    this.userForm.controls.address.setValue(null);
    this.userForm.controls.contact.setValue(null);
    this.userForm.controls.email.setValue(null);
    this.userForm.controls.telephone.setValue(null);
   
   
    this.submitted = false;
   
    //console.log("Call Clear!");
  }

  changeOrgType(e) {
    // this.cityName.setValue(e.target.value, {
    //   onlySelf: true
    // })
    //alert(JSON.stringify(this.userForm.value))
    console.log(this.userForm.value);
  }

  getOrgTypeList(): void {
    this.usrProfileService.getOrgTypeList()
    .subscribe(data => {
     
      if (!checkTimeOut(data,this.router)) {
        return;
      }
      this.orgTypeList = data;
      //console.log("DEBUG:"  + JSON.stringify(this.orgTypeList ));
    });
  }


  getOrgData(orgId , rootOrgId): void {
    this.usrProfileService.getOrgData(orgId , rootOrgId)
        .subscribe(data => {


          if (!checkTimeOut(data,this.router)) {
            return;
          }

          this.orginData = data[0];
          //console.log("Call getOrgData!");
          //console.log(data);
         
          //console.log('getUserAvatar return'+ JSON.stringify(data));
          // this.userData.first_name = this.orginData['firstName'];
          // this.userData.last_name = this.orginData['lastName'];
          // this.userData.telephone = this.orginData['telephone'];
          // this.userData.email = this.orginData['email'];
          // this.userData.ext_id = this.orginData['extId'];
         
          this.userForm.controls.org_id.setValue( this.orginData['org_id']);
          this.userForm.controls.org_name.setValue( this.orginData['org_name']);
          this.userForm.controls.desc.setValue(  this.orginData['org_desc']);

          this.userForm.controls.address.setValue(  this.orginData['address']);
          this.userForm.controls.contact.setValue(  this.orginData['contact_point']);
          this.userForm.controls.short_name.setValue(  this.orginData['short_name']);          

          this.userForm.controls.telephone.setValue(  this.orginData['telephone']);
          this.userForm.controls.email.setValue(  this.orginData['email']);

          //this.userForm.controls.org_type.setValue(  this.orginData['org_type_name']);
          this.userForm.controls.orgTypeCmb.setValue(  this.orginData['org_type']);

          // Luu lai parentOrgId
          this.parentOrgId =  this.orginData['org_parent_id'];
          
        });
  }

  invalidOrgId()
  {
    return (this.submitted && this.userForm.controls.org_id.errors != null);
  }

  invalidOrgType()
  {
    return (this.submitted && this.userForm.controls.orgTypeCmb.errors != null);
  }

  invalidOrgName()
  {
    return (this.submitted && this.userForm.controls.org_name.errors != null);
  }

  invalidShortName()
  {
    return (this.submitted && this.userForm.controls.short_name.errors != null);
  }

  invalidDesc()
  {
  	return (this.submitted && this.userForm.controls.desc.errors != null);
  }

  invalidTelephone()
  {
  	return (this.submitted && this.userForm.controls.telephone.errors != null);
  }

  invalidEmail()
  {
  	return (this.submitted && this.userForm.controls.email.errors != null);
  }

  invalidAddress()
  {
  	return (this.submitted && this.userForm.controls.address.errors != null);
  }
  invalidContact()
  {
  	return (this.submitted && this.userForm.controls.contact.errors != null);
  }
  
  onSubmit()
  {  
    //console.log("Do Submit!");
    if(this.userForm.invalid == true )
  	{
      //console.log("Invalid?!");
      this.debug_data = 'Invalid data!'
  		return;
  	}
    
   
    var organData = {};
    organData['org_name'] = this.userForm.controls.org_name.value;
    organData['org_desc'] = this.userForm.controls.desc.value;
    organData['short_name'] = this.userForm.controls.short_name.value;
    organData['org_parent_id'] = this.parentOrgId; //.orginData['org_parent_id'];
    organData['address'] = this.userForm.controls.address.value;
    organData['contact_point'] = this.userForm.controls.contact.value;    
    organData['telephone'] = this.userForm.controls.telephone.value;
    organData['email'] = this.userForm.controls.email.value;
    organData['org_id'] = this.orgId;
    organData['org_type'] = this.userForm.controls.orgTypeCmb.value;
    
    this.debug_data = 'Submited!'
    //console.log("profileData:" + JSON.stringify(organData));
    console.log("Command:" + this.cmd);
    if (this.cmd =="edit") {
      this.modifyOrgData(this.orgId,organData, this.rootOrgId);
      this.openDialog();
    } else if (this.cmd =="new") {
      this.newOrgData(this.parentOrgId,organData, this.rootOrgId);
      this.openDialog();
    }
    
  }

  modifyOrgData(orgId,organData,rootOrgId): void {
    this.usrProfileService.modifyOrgData(orgId,organData,rootOrgId)
        .subscribe(result => {         
          if (!checkTimeOut(result,this.router)) {
            return;
          } 
          console.log('Modify Organization Data return ');// + JSON.stringify(result));         
        });
  }

  newOrgData(parentOrgId,organData,rootOrgId): void {
    this.usrProfileService.newOrgData(parentOrgId,organData,rootOrgId)
        .subscribe(result => {         
          if (!checkTimeOut(result,this.router)) {
            return;
          }  
          console.log('New Organization Data return ' );//+ JSON.stringify(result));         
        });
  }
  
  openDialog(): void {
    const dialogRef = this.dialog.open(FinishOrgDataDialog, {
      width: '450px',
      data: {org_name: this.userForm.controls.org_name.value, 
        org_desc: this.userForm.controls.desc.value }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (this.cmd=="new") {
        this._location.back();
      }
    });
  }

}


//-------------

@Component({
  selector: 'finish-org-data-dialog',
  templateUrl: 'finish-org-data-dialog.html',
})

export class FinishOrgDataDialog {

  constructor(
    public dialogRef: MatDialogRef<FinishOrgDataDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  

}
