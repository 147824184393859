<div class="container-md">
    <h4 class="text-primary">Tìm kiếm nhanh bệnh nhân</h4>
    <form [formGroup]="userForm" >
        <strong>Nhập thông tin cần tìm</strong>

        <div class="form-group" [ngClass]="{ 'has-danger': invalidTelephone() }"> 
            <label for="msisdn" class="form-control-label">Tìm theo số điện thoại </label>
            <input type="text" formControlName="msisdn" class="form-control" [ngClass]="{ 'is-invalid': invalidTelephone() }" id="msisdn" name="msisdn" placeholder="Nhập telephone">   
        </div>

        
        <div style="color: red;">{{ message }}</div>

        <button type="submit" class="btn btn-primary" (click)="onSubmit()"><i class="fa fa-search"></i> Search</button>   &nbsp; 
        
    </form>
<hr>
</div>

<div *ngIf="patientId">
<app-patient [input_patientId]="patientId"></app-patient>
</div>
