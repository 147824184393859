<div class="container-lg">
  <h4 class="text-primary">Thông tin bệnh nhân</h4>

  <form [formGroup]="userForm">
    <div class="row">
      <div class="col-md-12">
        <h4 class="bold">Nhập thông tin bệnh nhân: {{orginData['user_name']}} / {{orginData['telephone']}} </h4>
        <!-- ID: {{ patientId }} -->
        <div class="form-group" [ngClass]="{ 'has-danger': invalidUserName() }">
          <!-- First Name -->
          <label for="user_name" class="form-control-label">Tên bệnh nhân *</label>
          <input
            type="text"
            formControlName="user_name"
            class="form-control"
            [ngClass]="{ 'is-invalid': invalidUserName() }"
            id="user_name"
            name="user_name"
            placeholder="Họ và tên"
          />
          <p
            *ngIf="invalidUserName()"
            id="first_name_error"
            class="invalid-feedback"
          >
            Invalid user name.
          </p>
        </div>

        <div
          class="form-group"
          [ngClass]="{ 'has-danger': invalidTelephone() }"
        >
          <!-- Telephone -->
          <label for="telephone" class="form-control-label">Điện thoại *</label>
          <input
            type="text"
            formControlName="telephone"
            class="form-control"
            [ngClass]="{ 'is-invalid': invalidTelephone() }"
            id="telephone"
            name="telephone"
            placeholder="Số điện thoại"
          />
          <p
            *ngIf="invalidTelephone()"
            id="telephone_error"
            class="invalid-feedback"
          >
            Invalid telephone.
          </p>
        </div>

        <div
          class="form-group"
          [ngClass]="{ 'has-danger': invalidExtId() }"
        >
       
          <label for="ext_id" class="form-control-label">Mã nghiên cứu/Mã bệnh nhân:</label>
          <input
            type="text"
            formControlName="ext_id"
            class="form-control"
            [ngClass]="{ 'is-invalid': invalidExtId() }"
            id="ext_id"
            name="ext_id"
            placeholder="Mã bệnh nhân"
          />
          <p
            *ngIf="invalidExtId()"
            id="ext_id_error"
            class="invalid-feedback"
          >
            Invalid ext_id.
          </p>
        </div>

        <div class="form-group">
          <label for="date_of_birth" class="form-control-label"
            >Ngày sinh</label
          >
          <input
            type="text"
            formControlName="date_of_birth"
            class="form-control"
            id="created_date"
            name="date_of_birth"
            placeholder="Ngày sinh"
          />
        </div>

        <label id="example-radio-group-label">Giới tính :&nbsp; </label>

        <mat-radio-group aria-label="Select an option"  formControlName="gender">
          <mat-radio-button value="1"> Nam</mat-radio-button>
          <mat-radio-button value="0"> Nữ</mat-radio-button>
        </mat-radio-group>
        

        <div class="form-group">
          <label for="created_date" class="form-control-label"
            >Ngày đăng ký</label
          >
          <input
            type="text"
            formControlName="created_date"
            class="form-control"
            id="created_date"
            name="created_date"
            placeholder="Ngày đăng ký"
          />
        </div>

        <div class="form-group">
          <label for="updated_date" class="form-control-label"
            >Ngày cập nhật</label
          >
          <input
            type="text"
            formControlName="updated_date"
            class="form-control"
            id="updated_date"
            name="updated_date"
            placeholder="Ngày cập nhật"
          />
        </div>

        <div class="form-group" [ngClass]="{ 'has-danger': invalidPassword() }"> 
          <label for="passwd" class="form-control-label">Mật khẩu (Để trống nếu không cần đổi)</label>
          <input type="password" formControlName="passwd" class="form-control" [ngClass]="{ 'is-invalid': invalidPassword() }" id="passwd" name="passwd" placeholder="Mật khẩu">
          <p *ngIf="invalidPassword()" id="last_name_error" class="invalid-feedback">Mật khẩu không khớp.</p>
        </div>

        <div class="form-group" [ngClass]="{ 'has-danger': invalidPassword() }"> 
          <label for="reenter_passwd" class="form-control-label">Nhập lại mật khẩu</label>
          <input type="password" formControlName="reenter_passwd" class="form-control" [ngClass]="{ 'is-invalid': invalidPassword() }" id="reenter_passwd" name="reenter_passwd" placeholder="Nhập lại mật khẩu">
          <p *ngIf="invalidPassword()" id="last_name_error" class="invalid-feedback">Mật khẩu không khớp</p>
        </div>


      </div>
      <div class="form-group has-error">
        <button type="submit" class="btn btn-primary" (click)="onSubmit()">
          <i class="fa fa-check"></i> Cập nhật
        </button>
        <!-- &nbsp;
        <button class="btn btn-warning">
          <i class="fa fa-check"></i> Gán phác đồ
        </button> -->
        &nbsp;        
        <button class="btn btn-danger" (click)="onDeletePatient()">
          <i class="fa fa-check"></i> Xóa bệnh nhân
        </button>
        &nbsp;
      </div>
      <hr>
      <div class="col-md-12">
        <h4 class="bold">Thông tin chi tiết</h4>
        <app-patient-regimen [input_patientId]="patientId"></app-patient-regimen>
      </div>
    </div>
   
  </form>
</div>
