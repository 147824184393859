
<div class="container-md">
    <h4 class="text-primary">Register Log</h4>

  <div class="row">    
   
    <mat-checkbox
    class="margin: 0 10px;"
    [(ngModel)]="enable_delete_btn"    
    [labelPosition]="labelPosition">
  Enable Delete Buttons!
</mat-checkbox>
    <table mat-table [dataSource]="dataSource" class="table table-bordred table-striped">

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element"> 
            <i *ngIf="element.has_vector" class="fa fa-check text-success"></i> 
            <i *ngIf="!element.has_vector" class="fa fa-bolt text-danger"></i></td>
      </ng-container>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> Order No. </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'color':element.has_vector ? '' : 'red' }"> {{element.position}} </td>
      </ng-container>

      <ng-container matColumnDef="usr_id">
        <th mat-header-cell *matHeaderCellDef> User Id </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'color':element.has_vector ? '' : 'red' }"> {{element.usr_id}} </td>
      </ng-container>
  
       <!-- Name Column -->
       <ng-container matColumnDef="ext_id">
        <th mat-header-cell *matHeaderCellDef> Ext Id </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'color':element.has_vector ? '' : 'red' }"> {{element.ext_id}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef> First Name </th>
        <td mat-cell *matCellDef="let element" 
          [ngStyle]="{'color':element.has_vector ? '' : 'red' }"> 
          {{element.first_name}} </td>
      </ng-container>
  
      <!-- Weight Column -->
      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef> Last Name </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'color':element.has_vector ? '' : 'red' }"> {{element.last_name}} </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="telephone">
        <th mat-header-cell *matHeaderCellDef> Telephone </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'color':element.has_vector ? '' : 'red' }"> {{element.telephone}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'color':element.has_vector ? '' : 'red' }"> {{element.email}} </td>
      </ng-container>
      <ng-container matColumnDef="created_on">
        <th mat-header-cell *matHeaderCellDef> Time </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'color':element.has_vector ? '' : 'red' }"> {{element.created_on}} </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> Edit </th>
        <td  mat-cell *matCellDef="let element">
          <p data-placement="top" data-toggle="tooltip" title="Edit"><button class="btn btn-primary btn-xs" 
              data-title="Edit" (click)="clickEditMethod(element.usr_id)"  data-toggle="modal" data-target="#edit" ><i class="fa fa-pencil-square-o"></i></button></p>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef> Delete </th>
        <td  mat-cell *matCellDef="let element">
          <p data-placement="top" data-toggle="tooltip" title="Delete">
            <button class="btn btn-danger btn-xs" 
              data-title="Delete" (click)="clickDeleteMethod(element.usr_id)"   data-toggle="modal" data-target="#delete" 
              [disabled]="!enable_delete_btn">
              <i class="fa fa-trash"></i></button></p>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
  
</div>
</div>