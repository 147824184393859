import { Component, OnInit } from '@angular/core';
import {UserProfileService} from '../user-profile.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import {Location} from '@angular/common';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  userId = '0';
  submitted = false;
  userName = '';

  userForm: FormGroup;
  
  
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,  
    private userProfileService: UserProfileService,
    private _location: Location,
    private router: Router
  ) { }

  ngOnInit() {
    this.userId = this.route.snapshot.paramMap.get('userId');

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser['user'].id != this.userId) {
      if  (currentUser['user'].webAppRoles !='sysadmin') {
        alert("Permission denied!");
        this._location.back();
      }
    }

    this.userForm = this.formBuilder.group({
      passwd: ['', Validators.required],
      reenter_passwd: ['', Validators.required],

    });

    this.userProfileService.getUserInfo(this.userId  )
        .subscribe({next:(data) => {
          this.userName = data.username;
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});
  }

  invalidPassword()
  {
    return (this.submitted && this.userForm.controls.passwd.errors != null) ;
  }

  invalidRePassword()
  {
    return (this.submitted && this.userForm.controls.reenter_passwd.errors != null) || (
      this.userForm.controls.reenter_passwd.value != this.userForm.controls.passwd.value
    ) ;
  }

  onGoBack()
  {  
    this._location.back();
  }

  onSubmit()
  {  
    this.submitted = true;  

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser['user'].id != this.userId) {
      if  (currentUser['user'].webAppRoles !='sysadmin') {
        alert("Permission denied!");
        this._location.back();
        return;
      }
    }
    
    if(this.userForm.invalid != true )
  	{
      if (confirm("Are you sure to change password for user " + this.userName  + " ( id=" + this.userId + ") ? ")) {

        this.userProfileService.updateUserInfo(this.userId ,{password:this.userForm.controls.reenter_passwd.value} )
        .subscribe({next:(data) => {
          //this.userName = data.username;
            alert("Doi pass thanh cong!");
            this._location.back();
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});
          
      }
  	} else {
      alert("Invalid data!");
    }
  }

}
