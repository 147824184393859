import { Component, OnInit,ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {UserProfileService} from '../user-profile.service';
import {MatSort} from '@angular/material/sort';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {checkTimeOut} from '../utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class PatientListComponent implements OnInit {

  displayedColumns: string[] = ['idx','status','patient_id','telephone', 'user_name','gender','dob',   'remark','created_date','updated_date'];
  
  patientList: any;
  dataSource = new MatTableDataSource<PatientElement>(this.patientList);

  // defaultImage = '/assets/avatar.png';
  // imageToShowOnError  = '/assets/avatar.png';
  expandedElement: PatientElement | null;
  
  mySubscription: any;


  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private userProfileService: UserProfileService,
        private router: Router
  ) { }

  ngOnInit() {
    this.getPatientList();
    this.dataSource.sort = this.sort;
  }

  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
   }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // mouseEnter(data : string){
  //  // console.log("mouse enter : " + data);
  // }

  chkChangeFilterType () {
    this.getPatientList();
    
  }


  getPatientList() {
    this.patientList = [];

    var filterType = '0';   

    this.userProfileService.getPatientList(filterType).subscribe({next:(data: []) => {

      // if (!checkTimeOut(data,this.router)) {
      //     return;
      // }
      //console.log('DAT:' + JSON.stringify(data));
      //this.lastaccess = data;
      
      //console.log("Data ", JSON.stringify(data));
      var idx = 0;

      data.forEach(e => {
        idx ++;
        var obj= new PatientElement();

        obj = e;
        obj['idx'] = idx;
        
        if (obj['gender']=='0') {
          obj['gioitinh'] = 'Nữ';
        } else if (obj['gender']=='1') {
          obj['gioitinh'] = 'Nam';
        } else {
          obj['gioitinh'] = '-';
        }

        this.patientList.push(obj);
       
      });

      this.dataSource.data = this.patientList ;
      this.dataSource.sort = this.sort;
      
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
  }

}


export class PatientElement {
  idx: number;
  patient_id : number;
  telephone : string;
  gender: string;
  date_of_birth: string;
  remark: string;
  email: string;  
  created_date : string;
  updated_date : string;  
  status : string;
}
