import {Component, OnInit} from '@angular/core';
import {UserProfileService} from '../user-profile.service';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-filter-question',
  templateUrl: './filter-question.component.html',
  styleUrls: ['./filter-question.component.scss']
})
export class FilterQuestionComponent implements OnInit {


  quest_list = []; // Lay toan bo danh sach de tinh index cho de


  parentId : String = '0';
  idsIndex = '0';

  questId = '0';
  questData = [];
  userForm: FormGroup;

  createNew = false;

  parentObj = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private userProfileService:UserProfileService,
    private formBuilder: FormBuilder,        
      public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.parentId = this.route.snapshot.paramMap.get('parentId');
    this.idsIndex = this.route.snapshot.paramMap.get('idsIndex');
    this.questId = this.route.snapshot.paramMap.get('questId');

    if (this.parentId=='0' || (Object.keys(this.parentId).length === 0)) { // Chuan hoa parentId
      this.parentId = null;
    }

    this.createNew = this.questId=='0';
    
    this.userForm = this.formBuilder.group({      
      index: ['', Validators.required],
      quest: ['', Validators.required],
      gender: ['2', Validators.required],
  		created_date: ['', Validators.required],      
      updated_date: ['', Validators.required],     

    });

    this.getFilterQuestList();  

    if (! this.createNew) {      
       this.getFilterQuestionById(this.questId );
    } else {
       
    }

    

  }
 
  getFilterQuestionById(questId) {
    this.questData = [];
    this.userProfileService.getFilterQuestionById(questId).subscribe((data: []) => {
      this.questData = data;     
      
      //console.log(JSON.stringify(this.questData));
      
      this.userForm.controls.quest.setValue(  this.questData['quest']);
      this.userForm.controls.index.setValue(  this.questData['index']);
      this.userForm.controls.gender.setValue(  this.questData['gender']);
      this.userForm.controls.created_date.setValue(  this.questData['created_at']);
      this.userForm.controls.updated_date.setValue(  this.questData['updated_at']);
    });   
  }

  getFilterQuestList() {
    this.quest_list = [];

    var filterType = '0';   

    this.userProfileService.getFilterQuestionList(filterType).subscribe({next:(data: []) => {

      // if (!checkTimeOut(data,this.router)) {
      //     return;
      // }
      //console.log('DAT:' + JSON.stringify(data));
      //this.lastaccess = data;
      
     // console.log("Data ", JSON.stringify(data));
      var idx = 0;

      var maxId = 1;
      this.parentObj = null;
     
      data.forEach(e => {
        idx ++;
        var obj= new FilterQuestionElement();

        obj = e;
        obj['idx'] = idx;
       
        if (obj.parentId==null) {
          this.quest_list.push(obj);
        }

        if (maxId < obj.index) {
          maxId = obj.index + 1;
        }

        if (this.parentId == obj.id + '') {
          this.parentObj = obj;              
        }

      });


      //========================================

      if (this.createNew) {      
          if (this.parentId=='0') {
            this.userForm.controls.index.setValue(  maxId);    
          } else {
              var idx = 1;
              if ( this.parentObj) {
                this.parentObj.ids.forEach(element => {
                    if (idx < element.index) {
                      idx = element.index + 1;
                    }
                });
              }
              if (this.idsIndex=='0') {
                this.userForm.controls.index.setValue(  idx); 
              } else {
                this.userForm.controls.index.setValue(  this.idsIndex); 
              }
          }
      }
      
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
  }

  invalidQuest() { return false};
  invalidIndex() { return false};
  invalidGender() { return false};

  invalidName() { return false};
  invalidVersion() { return false};
  invalidCreated_date() { return false};
  invalidUpdated_date() { return false};

  onGoBack()
  {  
    this._location.back();
  }

  onSubmit() {

    var body =  {
      index: this.userForm.controls.index.value,
      quest: this.userForm.controls.quest.value,      
      parentId: this.parentId,
      gender: this.userForm.controls.gender.value,
    };

   // console.log("DEBUG: " + JSON.stringify(body));
    
    // if(this.userForm.invalid) {
    //   return;
    // }

    if (this.createNew) { 
      this.userProfileService.addFilterQuestion(
      body
      ).subscribe({next:(result) => {
                  
          alert('Thêm thành công câu hỏi ' + this.userForm.controls.quest.value );
          this._location.back();
      
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});
      this.userProfileService.sysLog('FilterQuestionComponent','submit','Add Quest= ' +  this.userForm.controls.quest.value);
    } else {
      this.userProfileService.updateFilterQuestion(
        this.questId,
        body
        ).subscribe({next:(result) => {
                    
            alert('Cập nhật thành công câu hỏi ' + this.userForm.controls.quest.value );
            this._location.back();
        
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});
        this.userProfileService.sysLog('FilterQuestionComponent','submit','Update Quest= ' +  this.userForm.controls.quest.value);
    }

  }
}


export class FilterQuestionElement {
  id: number;
  index : number;
  quest : string;
  created_at: Date;
  updated_at: Date;
  parentId: FilterQuestionElement;
  ids: string;  
  gender : string;
}
