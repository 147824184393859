import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { User } from "../models";
import * as myGlobal from "../globals";
import { AuthenticationService } from "../authentication.service";
import { first } from "rxjs/operators";
import {UserProfileService} from '../user-profile.service';

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  status: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private usrProfileService:UserProfileService,
    private authenticationService: AuthenticationService
  ) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(["/"]);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService
      .login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data === null) {
            this.status = "Login failed!";
            this.usrProfileService.sysLog('Login','Login Failed!',this.f.username.value+": null data" );
          } else {
            if (data["jwt"] != null) {
              this.router.navigate([this.returnUrl]);
              console.log("Login OK");
              this.status = "Login OK!";
              //console.log("DEBUG user=", JSON.stringify(data));
              localStorage.setItem("currentUser", JSON.stringify(data));

		this.usrProfileService.updateCache();

              this.usrProfileService.sysLog('Login','Login OK!',this.f.username.value );
            } else {
              this.status = "Login Failed! " + data["error"];
              this.usrProfileService.sysLog('Login','Login Failed!',this.f.username.value+": data['error']=" + data["error"]);
              localStorage.setItem("currentUser", "{}");
            }
          }
        },
        (error) => {
          //this.alertService.error(error);
          console.log(error);
          this.loading = false;
          //console.log("Login FAILED");
          this.status = "Login failed! :" + error.error.message;
          //this.f.password.setValue('');
          localStorage.setItem("currentUser", "{}");
          this.usrProfileService.sysLog('Login','Login Failed!',this.f.username.value+": Exception=" + error.error.message);
        }
      );
  }
}

//https://stackblitz.com/edit/angular-7-registration-login-example?file=app%2Fapp.routing.ts
