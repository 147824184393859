import { Component, OnInit,OnChanges ,Input } from '@angular/core';
import { UserProfileService} from '../user-profile.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import {Location} from '@angular/common';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush   // Su dung khi update conponent cha
})
export class PatientComponent implements OnInit, OnChanges {

  @Input() input_patientId: string = ''; // Dung cho patient-search -> goi truc tiep 


  patientId = '0';

  submitted = false;
  orginData =[];

  userForm: FormGroup;
  
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,  
    private userProfileService: UserProfileService,
    private _location: Location,
    private router: Router
  ) { }

  ngOnInit() {

    // Neu khong co input, se lay tham so tu route
    if (!this.input_patientId) { 
      //console.log(this.input_patientId);
      this.patientId = this.route.snapshot.paramMap.get('patientId');
    } else {
      // Lay tham so neu duoc goi truc tiep
      //console.log(this.input_patientId);
      this.patientId = this.input_patientId;
    }
    
    this.userForm = this.formBuilder.group({
      //org_id: [this.orgId, Validators.required],
      //org_type: [this.orgTypeName, Validators.required],
      user_name: ['', Validators.required],
      telephone: ['', Validators.required],
      //email : ['',null],
      created_date: ['', Validators.required],
      updated_date: [{value:'',disabled:true}, Validators.nullValidator],
      date_of_birth: ['', Validators.required],
      passwd: ['', Validators.required],
      reenter_passwd: ['', Validators.required],
      gender: ['1', Validators.nullValidator],  
     
    });

    this.getPatientById(this.patientId);
  }

  ngOnChanges() {
    // create header using child_id
    //console.log(this.input_patientId);
    //console.log("ONCHANGE!");
    this.ngOnInit();
  }


  getPatientById(patientId): void {
    this.userProfileService.getPatientById(patientId )
        .subscribe({next:(data) => {
          this.orginData = data[0];

          console.log(JSON.stringify(this.orginData));
          this.userForm.controls.user_name.setValue( this.orginData['user_name']);
          this.userForm.controls.telephone.setValue( this.orginData['telephone']);
  
          //this.userForm.controls.email.setValue(  this.orginData['email']);
          this.userForm.controls.created_date.setValue(  this.orginData['created_date']);
          this.userForm.controls.updated_date.setValue(  this.orginData['updated_date']);
          this.userForm.controls.ext_id.setValue(  this.orginData['ext_id']);
          this.userForm.controls.date_of_birth.setValue(  this.orginData['date_of_birth'].substring(0, 10));

          this.userForm.controls.gender.setValue(  this.orginData['gender'] + '');
          
          this.patientId = patientId;

          // Luu lai parentOrgId
          //this.patientId =  this.orginData['patient_id'];
          
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});
  }

  onSubmit()
  {  
    this.submitted = true;  
    
    if(this.userForm.invalid == true )
  	{
      if (confirm("Chắc chắn muốn cập nhật thông tin cho bệnh nhân patient id=" + this.patientId + 
        " (" + this.orginData['user_name'] + " / Tel:" + this.orginData['telephone'] + ")? ")) {

          var newData = this.orginData;

          newData['user_name'] = this.userForm.controls.user_name.value;
          newData['telephone'] = this.userForm.controls.telephone.value;
          newData['gender'] = this.userForm.controls.gender.value;
          newData['date_of_birth'] = this.userForm.controls.date_of_birth.value;
          newData['ext_id'] = this.userForm.controls.ext_id.value;
  
          this.userForm.controls.user_name.setValue( this.orginData['user_name']);
          this.userForm.controls.telephone.setValue( this.orginData['telephone']);

          ///console.log(JSON.stringify(newData));
          
  
          this.userProfileService.updatePatientById(this.patientId, newData )
            .subscribe({next:(data) => {

              //console.log(JSON.stringify(data));
              if (data['affectedRows']==1) {
                alert("Cập nhật thành công");
                
                this.userProfileService.sysLog('PatientComponent','submit',newData);
                if (!this.input_patientId) { 
                  this._location.back();
                } else {
                  this.router.navigate(["/patient-search"]);
                }
              } else {
                alert("Cập nhật không thành công : " + JSON.stringify(data));
              }
              
            }, error:err => {
              console.error('Observer got an error: ' + err);
              alert('Lỗi kết nối mạng! ');
            }});

        }
  	}
  }

  onDeletePatient() {
    
    if (confirm("Chắc chắn muốn XÓA bệnh nhân có mã patient id=" + this.patientId + 
        " (" + this.orginData['user_name'] + " / Tel:" + this.orginData['telephone'] + ")? \nLƯU Ý: Không khôi phục được số liệu!")) {
      console.log("Delete orgId=" + this.patientId); 

      this.userProfileService.deletePatientById(this.patientId )
        .subscribe({next:(data) => {
          alert("Xóa thành công");
          this._location.back();
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});
      
    }
  }

  invalidPassword() {
    if (!this.submitted) return false;

    return (this.userForm.controls.passwd.value != this.userForm.controls.reenter_passwd.value)
    
  }
  
  invalidUserName()
  {
    return (this.submitted && this.userForm.controls.user_name.errors != null);
  }

  invalidTelephone()
  {
  	return (this.submitted && this.userForm.controls.telephone.errors != null);
  }

  invalidExtId()
  {
  	return (this.submitted && this.userForm.controls.ext_id.errors != null);
  }
}
