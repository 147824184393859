<div class="container-md">
  <h4 class="text-primary">Quản lý link bài viết</h4>

  <div class="row">
    <div class="col-8">
    <mat-form-field>

      <mat-label>Lọc tìm kiếm</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Nhập thông tin tìm kiếm và lọc">
    </mat-form-field>
    </div>
    <div class="col-2"></div>
    <div class="col-2">
      <a routerLink="/article-item/0">
      <button type="button" class="btn btn-primary">Tạo bài viết mới</button>
      </a>
    </div>
  </div>


  <table
    mat-table
    [dataSource]="dataSource"
    class="table table-bordred table-striped"
    matSort
    multiTemplateDataRows
  >
    <!-- Position Column -->
    <ng-container matColumnDef="idx">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>STT</th>
      <td mat-cell *matCellDef="let element">{{ element.idx }}</td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề</th>
      <td mat-cell *matCellDef="let element">{{ element.title }}</td>
    </ng-container>
    <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Đường dẫn</th>
      <td mat-cell *matCellDef="let element">{{ element.url }}</td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="8">
        <div
          class="example-element-detail"
          [@detailExpand]="
            element == expandedElement ? 'expanded' : 'collapsed'
          "
        >
          <div class="container">
            <div class="row">


              <div class="col-sm-11">
                Tiêu đề : {{ element.title }} <br>
                Url: <a href="{{ element.url }}">{{ element.url }}</a>
              </div>

              <div class="col-sm-1">
                <a routerLink="/article-item/{{element.id}}">
                  <button type="submit" class="btn btn-primary">
                    <i class="fa fa-edit"></i>
                  </button>
                </a>
                <br />
              </div>

            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <!-- tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr -->
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="example-detail-row"
    ></tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[10, 20, 50]"
    showFirstLastButtons
  ></mat-paginator>
</div>
