<div class="container-fluid">
    <h4 class="text-primary">Nhật ký hệ thống</h4>

  <div class="row">    
   
    <mat-form-field>

      <mat-label>Lọc tìm kiếm</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Nhập thông tin tìm kiếm và lọc">
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" class="table table-bordred table-striped" matSort multiTemplateDataRows>

      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> System Log Id. </th>
        <td mat-cell *matCellDef="let element"> {{element.position}} </td>
      </ng-container>

     
      
       <ng-container matColumnDef="usr_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> User Id </th>
        <td mat-cell *matCellDef="let element"> {{element.usr_id}} </td>
      </ng-container>
     
      <!-- Name Column -->
      <ng-container matColumnDef="user_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
        <td mat-cell *matCellDef="let element"> {{element.user_name}} </td>
      </ng-container>

      <ng-container matColumnDef="menu">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Menu </th>
        <td mat-cell *matCellDef="let element"> {{element.menu}} </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Thao tác </th>
        <td mat-cell *matCellDef="let element"> {{element.action}} </td>
      </ng-container>

      <ng-container matColumnDef="content">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Dữ liệu </th>
        <td mat-cell *matCellDef="let element"> {{element.content}} </td>
      </ng-container>
      
      
      <ng-container matColumnDef="created_on">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Thời gian  </th>
        <td mat-cell *matCellDef="let element"> {{element.created_on}} </td>
      </ng-container>

  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <!-- tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr -->

      <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element">
      </tr>
      
    </table>
  
    <mat-paginator [pageSizeOptions]="[10,20,50]" showFirstLastButtons></mat-paginator>
  
</div>
</div>
