<div class="container">
  <h1>Chỉnh sửa bài viết</h1>
  <button type="submit" class="btn btn-primary" (click)="onSaveHtml()">
    <i class="fa fa-check"></i>Cập nhật
  </button>
  <br />

  <form [formGroup]="articalForm">
    <div class="form-group">
      <label for="title">Tiêu đề</label>
      <input class="form-control" id="title"  placeholder="{{postItem.title}}" formControlName="title">
<!--      <small id="desc" class="form-text text-muted">{{postItem.desc}}</small>-->
    </div>
    <div class="form-group">
      <label for="title">Mô tả</label>
      <input class="form-control" id="desc" placeholder="{{postItem.desc}}" formControlName="desc">
<!--      <small id="desc" class="form-text text-muted">{{postItem.desc}}</small>-->
    </div>
    <div class="form-group">
      <label for="url">Đường dẫn bài viết</label>
      <input class="form-control" id="url"  placeholder="{{postItem.url}}" formControlName="url">
    </div>
    <div class="form-group" [formGroup]="articalForm">
      <label for="tags">Danh sách tags</label>
      <input class="form-control" id="tags"  placeholder="{{postItem.tags}}" formControlName="tags">
    </div>
    <div class="form-group" [formGroup]="articalForm">
      <label for="categoryId">Category</label>
      <select id="categoryId" formControlName="categoryId" class="form-control" [(ngModel)]="categoryId" (change)='changeCategory($event)'>
        <option  *ngFor="let category of categoryList" [ngValue]="category.id">{{category.name}}</option>
      </select>
    </div>
    <div class="form-group" [formGroup]="articalForm">
      <label for="type">Loại bài viết</label>
      <select id="type" formControlName="type" class="form-control" [(ngModel)]="type" (change)='changeType($event)'>
        <option *ngFor="let item of typeList" [ngValue]="item.id">{{item.desc}}</option>
      </select>
    </div>
    <div class="form-group">
      <mat-slide-toggle [(ngModel)]="isVideo" formControlName="isVideo">Is Video</mat-slide-toggle>
    </div>





  </form>
    <label>Nội dung bài viết</label>
  <ckeditor id="htmlContent"
            (fileUploadRequest)="onFileUploadRequest($event, 'ckeditor')"
            (fileUploadResponse)="onFileUploadResponse($event, 'ckeditor')"
            [(ngModel)]="htmlContent"
            [config]="configCK"
  ></ckeditor>
  <button type="submit" class="btn btn-primary" (click)="onSaveHtml()">
    <i class="fa fa-check"></i>Cập nhật
  </button>


  <!-- <angular-editor
    id="editor"
    [(ngModel)]="htmlContent"
    [config]="config"
    (ngModelChange)="onChange($event)"
    (blur)="onBlur($event)"
  ></angular-editor> -->
</div>
