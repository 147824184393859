import { Component, OnInit } from '@angular/core';
import * as myGlobal from '../globals'; 
import {UserProfileService} from '../user-profile.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {TableUtil} from '../tableUtil';
import {checkTimeOut} from '../utils';

@Component({
  selector: 'app-personal-report',
  templateUrl: './personal-report.component.html',
  styleUrls: ['./personal-report.component.scss']
})
export class PersonalReportComponent implements OnInit {

  DAYS_OF_WEEKS = ['CN','T2','T3','T4','T5','T6','T7'];
  
  userForm: FormGroup;
  submitted = false;
  userList = [];
  userData = null;
  content_data = null;

  usrId = 0;
 

  constructor(
    private usrProfileService:UserProfileService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router
    
  ) { }

  reportData: any;
  dataArray :any;
  daysOfWeeks : String[] = [];
  
  reportingCycleList :[];

  reportingCycle = '202002';
  year = "2020";
  month = "02";
  rootOrganData = {'org_name':'','address':''};

  dateOfMonth = new DateOfMonth;

  getOrgId() {
    let data = JSON.parse(localStorage.getItem('currentUser'));
    return data.org_id;
  }

  ngOnInit() {

       //=============================
       var dateObj = new Date();
       this.month = "" + (dateObj.getUTCMonth() + 1); //months from 1-12
       if (this.month.length==1) {
         this.month = "0" +  this.month;
       } 
       this.year = ""+dateObj.getUTCFullYear();
   
       this.reportingCycle = this.year + this.month;
   
       this.dateOfMonth.numDateOfMonth = daysInMonth(this.reportingCycle);
       this.dateOfMonth.firstDayOfWeek = getFirstDayOfWeek(this.reportingCycle);
       
       for (let day = 0; day < 31; day++) {
           var s = this.DAYS_OF_WEEKS[(day + this.dateOfMonth.firstDayOfWeek) %7];
           if (day>=this.dateOfMonth.numDateOfMonth) {
               s='';
           }
           this.daysOfWeeks.push(s);
       }
       //==============================
    this.userForm = this.formBuilder.group({
      //org_id: [this.orgId, Validators.required],
      //org_type: [this.orgTypeName, Validators.required],
      userListCmb: [this.usrId, Validators.required],      
      reportingCycleListCmb: [this.reportingCycle, Validators.required],  
    });

    this.getReportingCycleList();
    this.getUserReportList(this.reportingCycle);

    //this.getUserReportById(this.reportingCycle,this.usrId);
  }

  invalidUsrId()
  {
    return (this.submitted && this.userForm.controls.org_id.errors != null);
  }

  onSubmit() {
    if(this.userForm.invalid == true )
  	{
      //console.log("Invalid?!");
      //this.debug_data = 'Invalid data!'
  		return;
    }
    this.usrId = this.userForm.controls.userListCmb.value;
    this.getUserReportById(this.reportingCycle,this.usrId);
  }

  changeUserId(e) {
    this.usrId = this.userForm.controls.userListCmb.value;
    this.getUserReportById(this.reportingCycle,this.usrId);
  }

  getUserReportList(reportingCycle): void {
    this.usrProfileService.getUserReportList(this.getOrgId(),reportingCycle)
    .subscribe(data => {
      if (!checkTimeOut(data,this.router)) {return;}
      this.userList = data;
      //console.log("DEBUG:"  + JSON.stringify(this.orgTypeList ));
    });
  }

  getUserReportById(reportingCycle,usrId): void {
    this.usrProfileService.getUserReportById(this.getOrgId(),reportingCycle,usrId)
    .subscribe(data => {
      if (!checkTimeOut(data,this.router)) {return;}
      this.userData = data;
      this.content_data = this.userData.content_data;
      //console.log("DEBUG:"  + JSON.stringify(this.orgTypeList ));
    });
  }

  exportTable(){
    TableUtil.exportToExcel("tblReport");
  }

  getReportingCycleList(): void {
    this.usrProfileService.getReportingCycle(this.getOrgId())
    .subscribe(data => {
      if (!checkTimeOut(data,this.router)) {return;}
      this.reportingCycleList = data;
      //console.log("DEBUG:"  + JSON.stringify(this.orgTypeList ));
    });
  }

  
  invalidReportingCycle() {
     return (this.submitted && this.userForm.controls.reportingCycleListCmb.errors != null);
  }

  changeReportingCycle(event) {
    this.reportingCycle = this.userForm.controls.reportingCycleListCmb.value;
    //this.getReportByCycle();
    this.getUserReportById(this.reportingCycle,this.usrId);
  }

}

function daysInMonth (reportingCycle:String) {
  var year = parseInt(reportingCycle.substr(0,4));
  var month = parseInt(reportingCycle.substr(4,2));
  return new Date(year, month, 0).getDate(); 
} 

function getFirstDayOfWeek(reportingCycle:String) {
  var year = parseInt(reportingCycle.substr(0,4));
  var month = parseInt(reportingCycle.substr(4,2)) -1;
  // console.log("DEBUG: date=" + new Date(year, month, 1));
  // console.log("DEBUG: month=" + month);
  return new Date(year, month, 1).getDay(); 
}

export class person {
  position: number;
  org_id : string;
  ext_id : string;
  first_name: string;
  last_name: string;
  telephone: string;
  email: string;  
  created_on : string;
    
}

export class DateOfMonth {  
  public numDateOfMonth:number;
  public firstDayOfWeek:number;
}

