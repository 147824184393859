import { Component, OnInit ,Inject} from '@angular/core';

import { FileUploader,FileItem,ParsedResponseHeaders } from 'ng2-file-upload';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import * as myGlobal from '../globals'; 
import {UserProfileService} from '../user-profile.service';
import { CdkStepperNext } from '@angular/cdk/stepper';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {checkTimeOut} from '../utils';
import {Router} from '@angular/router';

export interface DialogData {
  first_name: string;
  last_name: string;
}

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})


export class RegisterFormComponent implements OnInit {

  public uploader:FileUploader = new FileUploader({url:myGlobal.api_imgupload_url});
  public up4RegisterEnabled = true;
  public editEnabled = true;
  public smallsize = false;
  
  public picurl: string;

  step1_done = false;
  registered = false;
  submitted = false;
  isRegSuccess = false;
  userForm: FormGroup;

  uploadCompleted = false;
  lastError = null;
  userId  = 0;
  
  debug_data = 'OK';
  
  constructor(private formBuilder: FormBuilder,  
      private usrProfileService:UserProfileService,
      private router: Router,
      public dialog: MatDialog)
  {
    
  }

  getOrgId() {
    let data = JSON.parse(localStorage.getItem('currentUser'));
    return data.org_id;
  }

  ngOnInit()  {
    // this.uploader.onBuildItemForm = (item, form) => {
    //   form.append("myfilename", item.file.name);
    //   form.append("another_key", "value");
    // }

    this.userForm = this.formBuilder.group({
      org_id: [this.getOrgId(), Validators.required],
      ext_id: ['', Validators.required],
  		first_name: ['', Validators.required],
  		last_name: ['', Validators.required],
      //email: ['', [Validators.required, Validators.email]]
      telephone: ['', Validators.required],
      email : ['',null]
    });
    
    this.uploader.onCompleteAll = () => this.onCompleteAll();
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onCompleteItem(item, response, status, headers);
    
  };

  onCompleteAll(): any {
    console.info('onCompleteAll');
    this.uploadCompleted = true;

    
  }

  onCompleteItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    console.info('onCompleteItem');
    this.uploadCompleted = true;
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    console.info('onErrorItem');
    this.lastError  = response;
  }
  
  public clear() {
    this.picurl = '';
    this.userId = 0;
    this.userForm.controls.first_name.setValue(null);
    this.userForm.controls.last_name.setValue(null);
    this.userForm.controls.email.setValue(null);
    this.userForm.controls.telephone.setValue(null);
   
    this.step1_done = false;
    this.registered = false;
    this.submitted = false;
    this.uploader.clearQueue();
    console.log("Call Clear!");
  }


  //---------------------

  onAvatarIdChanged(avatarId: number) {
    this.userId = avatarId;
    this.doStep1ValidateData();
    
  }

  invalidAvatar()
  {
  	return (this.submitted && this.userId==0);
  }

  invalidOrgId()
  {
    return (this.submitted && this.userForm.controls.org_id.errors != null);
  }

  invalidExtId()
  {
    return (this.submitted && this.userForm.controls.ext_id.errors != null);
  }

  invalidFirstName()
  {
    return (this.submitted && this.userForm.controls.first_name.errors != null);
  }

  invalidLastName()
  {
  	return (this.submitted && this.userForm.controls.last_name.errors != null);
  }

  invalidTelephone()
  {
  	return (this.submitted && this.userForm.controls.telephone.errors != null);
  }

  invalidEmail()
  {
  	return (this.submitted && this.userForm.controls.email.errors != null);
  }

  updateUserProfile(userId,profileData): void {
    this.usrProfileService.updateUserProfile(userId,profileData)
        .subscribe({next:(result) => {
          if (!checkTimeOut(result,this.router)) {return;}
          console.log('return ' + result);
          //console.log('getUserAvatar return'+ JSON.stringify(data));
          this.step1_done = true;
          //this.updateCompleted = true;
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});
  }

  finishRegister(userId): void {
    this.usrProfileService.finishRegister(userId)
        .subscribe({next:(result) => {
          if (!checkTimeOut(result,this.router)) {return;}
          //console.log('return ' + result);
          //console.log('getUserAvatar return'+ JSON.stringify(data));
         
          this.isRegSuccess = true;
          this.openDialog();
          this.clear();
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});
  }

  doStep1ValidateData()
  {  
  	this.submitted = true;

  	if(this.userForm.invalid == true || this.userId==0)
  	{
      this.debug_data = 'Invalid data!'
  		return;
  	}
  	else
  	{

      var profileData = {}
      profileData['userId'] = this.userId;
      profileData['orgId'] = this.userForm.controls.org_id.value;
      profileData['extId'] = this.userForm.controls.ext_id.value;
      profileData['firstName'] = this.userForm.controls.first_name.value;
      profileData['lastName'] = this.userForm.controls.last_name.value;
      profileData['telephone'] = this.userForm.controls.telephone.value;
      profileData['email'] = this.userForm.controls.email.value;

      this.registered = true;
      this.debug_data = 'Submited!'
      console.log("profileData:" + JSON.stringify(profileData));
      this.updateUserProfile(this.userId,profileData);

      this.uploader.onBuildItemForm = (item, form) => {
        form.append("userID", this.userId);
        //form.append("another_key", "value");
      }
  	}
  }

  onSubmit()
  {  
    if(this.userForm.invalid == true || this.userId==0)
  	{
      this.debug_data = 'Invalid data!'
  		return;
  	}
    

    this.finishRegister(this.userId);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FinishRegisterDialog, {
      width: '350px',
      data: {first_name: this.userForm.controls.first_name.value, 
              last_name: this.userForm.controls.last_name.value }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
     
    });
  }
}


//====================

@Component({
  selector: 'finish-register-dialog',
  templateUrl: 'finish-register-dialog.html',
})

export class FinishRegisterDialog {

  constructor(
    public dialogRef: MatDialogRef<FinishRegisterDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}