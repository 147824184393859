
<div class="container-md">
    <h4 class="text-primary">Device Status</h4>

  <div class="row">    
   
    <table mat-table [dataSource]="dataSource" class="table table-bordred table-striped">

      <ng-container matColumnDef="connect_status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element"> 
            <i *ngIf="element.connect_status=='OK'" class="fa fa-check text-success"></i> 
            <i *ngIf="element.connect_status!='OK'" class="fa fa-bolt text-danger"></i></td>
      </ng-container>

      <ng-container matColumnDef="idx">
        <th mat-header-cell *matHeaderCellDef> Order No. </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'color':element.connect_status=='OK' ? '' : 'red' }"> {{element.idx}} </td>
      </ng-container>

      <ng-container matColumnDef="dev_id">
        <th mat-header-cell *matHeaderCellDef> Device Id </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'color':element.connect_status=='OK' ? '' : 'red' }"> {{element.dev_id}} </td>
      </ng-container>
  
       <!-- Name Column -->
       <ng-container matColumnDef="dev_type">
        <th mat-header-cell *matHeaderCellDef> Dev Type </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'color':element.connect_status=='OK' ? '' : 'red' }"> {{element.dev_type}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="dev_name">
        <th mat-header-cell *matHeaderCellDef> Dev Name </th>
        <td mat-cell *matCellDef="let element" 
          [ngStyle]="{'color':element.connect_status=='OK' ? '' : 'red' }"> 
          {{element.dev_name}} </td>
      </ng-container>
  
      <!-- Weight Column -->
      <ng-container matColumnDef="dev_loc">
        <th mat-header-cell *matHeaderCellDef> Dev Location </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'color':element.connect_status=='OK' ? '' : 'red' }"> {{element.dev_loc}} </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="remark">
        <th mat-header-cell *matHeaderCellDef> Remark </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'color':element.connect_status=='OK' ? '' : 'red' }"> {{element.remark}} </td>
      </ng-container>

      
      <ng-container matColumnDef="last_updated">
        <th mat-header-cell *matHeaderCellDef> Last updated </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="{'color':element.connect_status=='OK' ? '' : 'red' }"> {{element.last_updated}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
  
</div>
</div>