<div class="container-md">
    <h4 class="text-primary">Checkin Device Management</h4>
    For company: {{rootOrganData.org_name}}

    <mat-tree>
        <mat-nested-tree-node>
          parent node
          <mat-nested-tree-node> -- child node1 </mat-nested-tree-node>
          <mat-nested-tree-node> -- child node2 </mat-nested-tree-node>
        </mat-nested-tree-node>
     </mat-tree>

</div>