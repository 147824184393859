import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { UserProfileService } from "../user-profile.service";
import { MatSort } from "@angular/material/sort";
import * as moment from 'moment';
import { ResizeService } from "../resize.service";
import { Observable, interval, Subscription } from "rxjs";
import * as myGlobal from "../globals";
import { delay } from "rxjs/operators";

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { checkTimeOut } from "../utils";
import { Router } from "@angular/router";

const PATIENT_PRE_FILTER = 1;
const PATIENT_AFTER_FILTER_NOT_ALLOW_REGIMEN = 11;
const PATIENT_STATE_IDLE = 2;
const PATIENT_STATE_READY_ACTIVE = 3;
const PATIENT_STATE_ACTIVE = 4;
const PATIENT_STATE_CONFIRM = 5;
const PATIENT_STATE_ACTIVE_SUSPEND = 41;
const PATIENT_STATE_WAIT_FOR_TIME_MORNING = 42;
const PATIENT_STATE_SUSPEND_NOT_ALLOW_SELF_ACTIVE = 43;
const PATIENT_STATE_CONFIRM_SUSPEND = 51;
const PATIENT_STATE_CONFIRM_NOT_CLEAN = 52;
const PATIENT_STATE_AFTER_CONFIRM = 6;
const PATIENT_STATE_POST_SUPPORT = 7;
const PATIENT_STATE_POST_SUPPORT_NOT_ENOUGH_CONDITION = 8;

interface SelectState {
  value: string;
  strText: string;
  strDesc: string;
}

@Component({
  selector: "app-patient-monitor",
  templateUrl: "./patient-monitor.component.html",
  styleUrls: ["./patient-monitor.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class PatientMonitorComponent implements OnInit {
  constructor(
    private userProfileService: UserProfileService,
    private router: Router,
    private resizeSvc: ResizeService
  ) {


    this.displayedColumns = this.displayedColumns_default;

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    this.isSysAdmin = this.currentUser["user"].webAppRoles == "sysadmin";
    this.supporterLoc = this.currentUser.user.location;
    this.newSupporter = this.currentUser.user.username;

    this.updateSubscription = interval(10000).subscribe((val) => {
      this.getPatientListMonitor();
      //console.log("Refresh page!");
    });

    this.resizeSvc.onResize$.pipe(delay(0)).subscribe((x) => {
      this.windowsize = x;
      if (x < 3) {
        this.displayedColumns = this.displayedColumns_small;
      } else {
        this.displayedColumns = this.displayedColumns_default;
      }
    });
  }

  windowsize: myGlobal.SCREEN_SIZE;
  displayedColumns = null;
  displayedColumns_small: string[] = [
    "idx",

    "userinfo",
    "telephone",
    "status",
    "regimen_id",
    "doctor_name",
    "active",
    "pause",
    "block",
    "reset",
  ];

  displayedColumns_default: string[] = [
    "idx",

    "userinfo",
    "telephone",
    "status",

    "regimen_id",

    "doctor_name",
    "regimen_where",
    "phongkham",

    "active",
    "pause",
    //'continue',
    "block",
    "reset",

    "starttime",
    "updatedtime",
    "supporter",
  ];

  doctorList: DoctorElement[];
  regimenList: any;
  patientList: any = null;

  currentUser: any;
  supporterLoc: string;
  isSysAdmin = false;

  sum_sangloc = 0;
  sum_uongthuoc = 0;
  sum_chosoi = 0;
  sum_danhgia = 0;
  panelOpenState = false;

  filterMore = false;

  newSupporter = "";

  //--------------------
  chkToday = true;
  chkOtherDay = false;

  chkAll = true;
  chkSL = true; // Sang loc
  chkUT = true; // Uong thuoc
  chkDG = true; // Danh gia
  chkCS = true; // Cho soi

  chkCountAll = false; // Dem toan bo

  chkPK = true;
  chkNha = true;

  today = "01/01";

  dataSource = new MatTableDataSource<PatientElement>(this.patientList);

  // defaultImage = '/assets/avatar.png';
  // imageToShowOnError  = '/assets/avatar.png';
  expandedElement: PatientElement | null;

  mySubscription: any;

  private updateSubscription: Subscription;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // ===============================

  regimen_states: SelectState[] = [
    {
      value: "1",
      strText: "PATIENT_PRE_FILTER",
      strDesc: "Trước khi sàng lọc",
    },
    {
      value: "2",
      strText: "PATIENT_STATE_IDLE",
      strDesc: "Đã thực hiện sàng lọc, đủ điều kiện thực hiện phác đồ",
    },
    {
      value: "3",
      strText: "PATIENT_STATE_READY_ACTIVE",
      strDesc:
        "Trạng thái sau khi lựa chọn địa điểm uống thuốc, ngày nội soi và kích hoạt phác đồ",
    },
    {
      value: "4",
      strText: "PATIENT_STATE_ACTIVE",
      strDesc: "Trạng thái đang thực hiện phác đồ",
    },
    {
      value: "41",
      strText: "PATIENT_STATE_ACTIVE_SUSPEND",
      strDesc: "Trạng thái tạm ngưng phác đồ khi bấm SOS",
    },
    {
      value: "42",
      strText: "PATIENT_STATE_WAIT_FOR_TIME_MORNING",
      strDesc: "Thực  hiện phác đồ ở nhà, chờ chuyển sang ngày mới",
    },
    {
      value: "43",
      strText: "PATIENT_STATE_SUSPEND_NOT_ALLOW_SELF_ACTIVE",
      strDesc: "Trạng thái dừng phác đồ, không cho phép tự kích hoạt lại",
    },
    {
      value: "5",
      strText: "PATIENT_STATE_CONFIRM",
      strDesc: "Đã confirm độ sạch sau khi thực hiện phác đồ",
    },
    {
      value: "6",
      strText: "PATIENT_STATE_AFTER_CONFIRM",
      strDesc: "Thái thái sau xác nhận độ sạch thành công",
    },
    {
      value: "7",
      strText: "PATIENT_STATE_POST_SUPPORT",
      strDesc:
        "Trạng thái sau khi thực hiện phác đồ và được confirm là sạch, Đủ điều kiện nội soi",
    },
    {
      value: "8",
      strText: "PATIENT_STATE_POST_SUPPORT_NOT_ENOUGH_CONDITION",
      strDesc:
        "Trạng thái sau khi thực hiện phác đồ và được confirm là chưa sạch, chưa đủ điều kiện",
    },
    {
      value: "11",
      strText: "PATIENT_AFTER_FILTER_NOT_ALLOW_REGIMEN",
      strDesc: "Sau sàng lọc nhưng không đáp ứng được điều kiện phác đồ",
    },
  ];



  sortData(sort: MatSort) {
    const data = this.patientList.slice();
    if (!sort.active || sort.direction === '') {
      this.patientList = data;
      return;
    }

    this.patientList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'idx': return compare(a.idx, b.idx, isAsc);
        case 'user_name': return compare(a.user_name, b.user_name, isAsc);
        case 'telephone': return compare(a.telephone, b.telephone, isAsc);
        case 'patient_id': return compare(a.patient_id, b.patient_id, isAsc);
        case 'regimen_where_show': return compare(a.regimen_where_show, b.regimen_where_show, isAsc);
        case 'state': return compare(a.state, b.state, isAsc);
        case 'start_time': return compare(a.start_time, b.start_time, isAsc);
        case 'updated_date_str': return compare(a.updated_date_str, b.updated_date_str, isAsc);
        default: return 0;
      }
    });
  }

  getFormattedDate(date):string {
    var year = date.getFullYear();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return day + '/' + month;// + '/' + year;
  }

  ngOnInit() {

    this.today = this.getFormattedDate( new Date());

    this.getPatientListMonitor();
    this.getRegimenList();
    this.getDoctorList();
    this.dataSource.sort = this.sort;

    
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  mouseEnter(data: string) {
    //console.log('mouse enter : ' + data);
  }

  chkChangeFilterType() {
    this.getPatientListMonitor();
  }

  getDoctorList() {
    this.doctorList = [];
    this.userProfileService.getDoctorList().subscribe({next:(data: []) => {
      data.forEach((e) => {
        let obj = new DoctorElement();
        obj = e;

        this.doctorList.push(obj);
      });
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }
    });
  }

  getPatientListMonitor() {
    this.patientList = [];

    // let filterType = '0';

    //this.userProfileService.getPatientListMonitor().subscribe((data: []) => {
    this.userProfileService
      .getPatientLastestRegimenList("0")
      .subscribe({next:(data: []) => {
         if (!checkTimeOut(data,this.router)) {
             return;
         }
        // console.log('DAT:' + JSON.stringify(data));
        // this.lastaccess = data;

        // console.log('Data ', JSON.stringify(data));
        let idx = 0;

        this.sum_sangloc = 0;
        this.sum_uongthuoc = 0;
        this.sum_chosoi = 0;
        this.sum_danhgia = 0;
        

        data.forEach((e) => {
          idx++;
          let obj = new PatientElement();
          obj = e;
          obj.idx = idx;
          //HaBM
          obj.date_of_birth = moment(e["date_of_birth"]).format("YYYY");

          if (obj["gender"] == "0") {
            obj["gioitinh"] = "Nữ";
          } else if (obj["gender"] == "1") {
            obj["gioitinh"] = "Nam";
          } else {
            obj["gioitinh"] = "-";
          }

          if (obj["location_id"] == "2") {
            obj["phongkham"] = "PK 2";
          } else if (obj["location_id"] == "1") {
            obj["phongkham"] = "PK 1";
	    } else {
	    obj["phongkham"] = "-";
	    }

          if (obj["regimen_where"] == "clinic") {
            obj["regimen_where_show"] = "PK";
          } else if (obj["regimen_where"] == "home") {
            obj["regimen_where_show"] = "Nha";
          } else {
            obj["regimen_where_show"] = "";
          }

          //obj['starttime'] = '20/02/2020 10:20:30';
          //obj["supporter"] = "supporter";

          if (obj["other_data"]) {
            obj.otherdataObj = JSON.parse(obj["other_data"]);
          }

          if (this.stateMapping(obj)) {
            if (this.supporterLoc != "all") {
              if (
                (this.supporterLoc == "pk1" && obj["location_id"] == "1") ||
                (this.supporterLoc == "pk2" && obj["location_id"] == "2")
              ) {
                this.patientList.push(obj);
              }
            } else {
              this.patientList.push(obj);
            }
          }
        });

        this.dataSource.data = this.patientList;
        this.dataSource.sort = this.sort;
        }, error:err => {
          console.error('Observer got an error: ' + JSON.stringify(err));
          alert('Lỗi kết nối mạng! ');
        }});
  }

  getStateName(inputState) {
    let msg = "";
    this.regimen_states.forEach((item) => {
      if (item.value === inputState) {
        msg += item.strDesc + " ";
      }
    });
    return msg;
  }

  // onChangeLocation(e, event) {
  //   // this.userProfileService.updateRegimenOfPatientDoctor(
  //   //     e.patient_regimen_id,
  //   //     e.doctor_name,
  //   //     ).subscribe((data: []) => {
  //   alert("Cập nhật địa điểm khám thành công cho BN : " + e.user_name);
  //   //     this.ngOnInit();
  //   // });
  // }

  onChangeDoctor(e, event) {
    this.userProfileService
      .updateRegimenOfPatientDoctor(e.patient_regimen_id, e.doctor_name)
      .subscribe({next:(data: []) => {
        // alert('Cập nhật bác sỹ chỉ định thành công cho BN : ' +e.user_name );

        this.userProfileService.sysLog(
          "PatientMonitorComponent",
          "Change Doctor",
          "BN= " +
            e.patient_id +
            " : " +
            e.user_name +
            " -  BS:" +
            e.doctor_name
        );
        this.ngOnInit();
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }
      });
  }

  onNewSupporterFocusOutEvent(event: any){

    console.log("DEBUG:" + this.newSupporter);
    if (this.newSupporter=="")   {
        alert("Phải nhập người hỗ trợ");
        this.newSupporter = this.currentUser.user.username;
    }
  }

  onChangeRegimen(e, event, newId) {
    // console.log('Event: ' +  JSON.stringify(event));
    // console.log('e: ' +  JSON.stringify(e));
    // console.log('newId: ' +  newId);

    if (this.newSupporter=="")   {
      alert("Phải nhập người hỗ trợ");

      return;
    }

    newId = newId.split(":")[1];
    newId = newId.trim();
    if (!newId) {
      alert("Hãy chọn một phác đồ!");
      this.ngOnInit();
      return;
    }

    if (e.regimen_id && e.active_code) {
      alert(
        "Bệnh nhân " + e.user_name + " đã kích hoạt phác đồ, không đổi được!"
      );
      this.ngOnInit();
      return;
    }

    this.userProfileService
      .getPatientRegimenByPatientId(e.patient_id)
      .subscribe({next:(data) => {
        // console.log('DEBUG:'  + JSON.stringify(data));
        if (data.length > 0) {
          // this.orginData = data;
          // this.countOfRegimen = data.length;
          // this.currentRegimenIdx = 0;
          // this.currentRegimen = data[this.currentRegimenIdx];
          // this.selectedValue = this.currentRegimen["regimen_id"];

          //update regimen
          //let patient_regimen_id = this.currentRegimen["patient_regimen_id"];
          //if (
          //   confirm("Bạn có chắc chắn muốn gán phác đồ cho bệnh nhân " + e.user_name + " ? Ma IDphac do moi la " + newId)
          //) {
          // this.userProfileService
          //     .updateRegimenOfPatientRegimen(e.patient_regimen_id, newId)
          //     .subscribe((data) => {
          //         //this.userName = data.username;
          //         alert("Đổi phác đồ thành công!");
          //         //this.currentRegimen["regimen_id"] = this.selectedValue;
          //         this.ngOnInit();
          //     });

          this.userProfileService
            .updateRegimenOfPatientField2(e.patient_regimen_id, {
              regimen_id: newId,
              supporter: this.newSupporter,
            })
            .subscribe((data) => {
              //this.userName = data.username;
              //          alert("Đổi phác đồ thành công!");
              //this.currentRegimen["regimen_id"] = this.selectedValue;
              this.userProfileService.sysLog(
                "PatientMonitorComponent",
                "Change Regimen",
                "BN= " +
                  e.patient_id +
                  " : " +
                  e.user_name +
                  " -  RegimenId:" +
                  newId
              );
              this.ngOnInit();
            });
          //} else {
          //    this.ngOnInit();
          //}

          //end
        } else {
          // Not allow create, only create on app after filter
          alert(
            "Không thể cập nhật phác đồ, bệnh nhân cần thực hiện sàng lọc trước"
          );
          this.ngOnInit();
        }
      }, error:err => {
        //console.error('Observer got an error: ' + JSON.stringify(err));
        alert('Lỗi kết nối mạng! ');
      }
      });
  }

  onClickChkToday(val) {
   // console.log("DEBUG: onClickChkToday: " + this.chkToday  + " - " + this.chkOtherDay + " - " );
    // this.chkToday  = !this.chkToday;
     if (!this.chkToday)
       this.chkOtherDay = true;
       this.ngOnInit();
  }

  onClickChkOtherday(val) {
   // console.log("DEBUG: onClickChkOtherday " + this.chkToday  + " - " + this.chkOtherDay + " - " );

    // this.chkOtherDay = !this.chkOtherDay;
     if (!this.chkOtherDay)
       this.chkToday = true;
      this.ngOnInit();
  }

  onClickChkStates() {
    if (this.chkSL && this.chkUT && this.chkDG && this.chkCS)
      this.chkAll = true;
    else 
      this.chkAll = false;
    this.ngOnInit();
  }

  onClickChkLocNha() {
    if (!this.chkNha) 
      this.chkPK = true;
    this.ngOnInit();
  }
  onClickChkLocPK() {
    if (!this.chkPK) 
      this.chkNha = true;
    this.ngOnInit();
  }

  onClickChkAll() {
    //if (this.chkAll) {
       this.chkSL = this.chkAll;  this.chkUT = this.chkAll; this.chkDG = this.chkAll; this.chkCS = this.chkAll;
       this.ngOnInit();
    //}
    
  }

  onClickCountAll() {
    this.ngOnInit();
  }

  onClickActive(e) {
    if (
      e.patient_regimen_id
      //&& (e.state == PATIENT_STATE_IDLE ||
      // e.state == PATIENT_AFTER_FILTER_NOT_ALLOW_REGIMEN
    ) {

      if (!e.regimen_id) {
        alert("Phải chọn phác đồ và bác sỹ trước");
        return;
      }
      this.userProfileService
        .updateRegimenOfPatientField2(e.patient_regimen_id, {
          //HaBM: Chuyen ve trang thai 2 de benh nhan chon dia diem uong thuoc
          state: PATIENT_STATE_IDLE,
          active_code: "1234",
          supporter: this.newSupporter,
        })
        .subscribe({next:(data) => {
          //this.userName = data.username;
             alert("Kich hoat phác đồ thành công!");
          //this.currentRegimen["regimen_id"] = this.selectedValue;
          this.userProfileService.sysLog(
            "PatientMonitorComponent",
            "Active  Regimen",
            "BN= " + e.patient_id + " : " + e.user_name
          );
          this.ngOnInit();
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }
        });
    } else {
      alert("Thao tác không được phép");
      return;
    }
  }

  // => Chuyển về trạng thái PATIENT_STATE_ACTIVE = 4
  // nếu trạng thái trước là PATIENT_STATE_SUSPEND_NOT_ALLOW_SELF_ACTIVE = 43
  // hoặc PATIENT_STATE_ACTIVE_SUSPEND = 41
  onClickContinue(e) {
    if (
      e.patient_regimen_id != null &&
      e.patient_regimen_id != 0
      // && (e.state == PATIENT_STATE_SUSPEND_NOT_ALLOW_SELF_ACTIVE ||
      // e.state == PATIENT_STATE_ACTIVE_SUSPEND )
    ) {
      let newstate = PATIENT_STATE_ACTIVE;
      if (e.otherdataObj.confirmRegimen != null && e.otherdataObj.confirmRegimen != undefined) {
		newstate = PATIENT_STATE_CONFIRM;
      }
      this.userProfileService
        .updateRegimenOfPatientState(
          e.patient_regimen_id,
          newstate
        )
        .subscribe({next:(data: []) => {
          alert("Tiếp tục uống thuốc : " + e.user_name);
          this.userProfileService.sysLog(
            "PatientMonitorComponent",
            "Continue Regimen Process",
            "BN= " + e.patient_id + " : " + e.user_name
          );
          this.ngOnInit();
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }
        });
    } else {
      alert("Thao tác không được phép");
      return;
    }
  }

  // => Chuyển về trạng thái PATIENT_STATE_ACTIVE_SUSPEND = 41
  // nếu trạng thái trước là PATIENT_STATE_READY_ACTIVE = 4
  onClickPause(e) {
    if (
      e.patient_regimen_id != null &&
      e.patient_regimen_id != 0
      //&&  (e.state == PATIENT_STATE_READY_ACTIVE  )
    ) {
      this.userProfileService
        .updateRegimenOfPatientState(
          e.patient_regimen_id,
          PATIENT_STATE_ACTIVE_SUSPEND
        )
        .subscribe({next:(data: []) => {
          alert(
            "Tạm ngừng uống thuốc cho bệnh nhân : " +
              e.user_name +
              " thành công!"
          );
          this.userProfileService.sysLog(
            "PatientMonitorComponent",
            "Pause Regimen process",
            "BN= " + e.patient_id + " : " + e.user_name
          );
          this.ngOnInit();
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }
        });
    } else {
      alert("Thao tác không được phép");
      return;
    }
  }

  onClickReset(e) {
    if (e.patient_regimen_id != null && e.patient_regimen_id != 0) {
      this.userProfileService
        .reseteRegimenOfPatient(e.patient_id, e.patient_regimen_id)
        .subscribe({next:(data: []) => {
          // alert('Reset qua trinh uống thuốc cho bệnh nhân : ' +e.user_name + ' thành công!');
          this.userProfileService.sysLog(
            "PatientMonitorComponent",
            "Reset Regimen",
            "BN= " + e.patient_id + " : " + e.user_name
          );
          this.ngOnInit();
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }
        });
    } else {
      alert("Thao tác không được phép");
      return;
    }
  }

  getRegimenList() {
    this.userProfileService.getRegimenList("").subscribe({next:(data) => {
      this.regimenList = data;
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }
    });
  }

  /*
    Nghiệp vụ khóa như sau:
	- Chuyển về trạng thái PATIENT_AFTER_FILTER_NOT_ALLOW_REGIMEN = 11 nếu trạng thái trước là PATIENT_STATE_READY_ACTIVE = 3
	- Chuyển về trạng thái PATIENT_state_SUSPEND_NOT_ALLOW_SELF_ACTIVE = 43 nếu trạng thái trước là PATIENT_STATE_READY_ACTIVE = 4
	- Chuyển về trạng thái PATIENT_STATE_CONFIRM_SUSPEND = 51 nếu trạng thái trước là PATIENT_STATE_CONFIRM = 5
    */
  onClickBlock(e) {
    if (e.patient_regimen_id != null && e.patient_regimen_id != 0) {
      var newstate = e.state;

      if (e.state == PATIENT_STATE_READY_ACTIVE)
        newstate = PATIENT_AFTER_FILTER_NOT_ALLOW_REGIMEN;
      if (e.state == PATIENT_STATE_ACTIVE)
        newstate = PATIENT_STATE_SUSPEND_NOT_ALLOW_SELF_ACTIVE;
      if (e.state == PATIENT_STATE_CONFIRM)
        newstate = PATIENT_STATE_CONFIRM_SUSPEND;

      this.userProfileService
        .updateRegimenOfPatientState(e.patient_regimen_id, newstate)
        .subscribe({next:(data: []) => {
          //alert('Khóa trạng thái uống thuốc của bệnh nhân: ' +e.user_name + ' thành công!');
          this.userProfileService.sysLog(
            "PatientMonitorComponent",
            "Block Regimen",
            "BN= " + e.patient_id + " : " + e.user_name
          );
          this.ngOnInit();
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }
        });
    } else {
      alert("Thao tác không được phép");
      return;
    }
  }

  /*
    1-chưa đủ điều kiện: 
Là trường hợp profile bệnh nhân ko thỏa mãn 1 trong các điều kiện sau:
+ Chưa làm hoặc không đạt câu hỏi sàng lọc hoặc kết quả sàng lọc đã hết hạn
+ Chưa có phác đồ (phác đồ id <= 0)
+ Chưa có mã kích hoạt
=> Mapping: 
	PATIENT_PRE_FILTER = 1
	PATIENT_AFTER_FILTER_NOT_ALLOW_REGIMEN = 11

2-chưa kích hoạt
=> Mapping: PATIENT_STATE_IDLE = 2

3-đã kích hoạt
Là trường hợp profile bệnh nhân đã xác nhận địa điểm uống thuốc
=> Mapping: PATIENT_STATE_READY_ACTIVE = 3

4-đang uống thuốc
Là trường hợp profile bệnh nhân đã xác nhận bắt đầu uống thuốc
=> Mapping: PATIENT_STATE_ACTIVE = 4

5-chờ đánh giá độ sạch
Là trường hợp profile bệnh nhân đã hoàn thành uống thuốc và đang trong giai đoạn chờ đánh giá độ sạch
=> Mapping: PATIENT_STATE_CONFIRM = 5

6-dừng phác đồ
Là trường hợp bệnh nhân ấn "SOS" - "Dừng" (đang uống thuốc) và "Dừng toàn bộ phác đồ" (đang chờ đánh giá độ sạch)
=> Mapping:
	PATIENT_STATE_SUSPEND_NOT_ALLOW_SELF_ACTIVE = 43 - lý do: dừng hẳn quá trình uống thuốc
	PATIENT_STATE_ACTIVE_SUSPEND = 41 - lý do: SOS trong quá trình uống thuốc
	PATIENT_STATE_CONFIRM_SUSPEND = 51 - lý do: dừng trong quá trình chờ đánh giá độ sạch

7-chưa đủ điều kiện nội soi
=> Mapping:
	PATIENT_STATE_CONFIRM_NOT_CLEAN = 52 - lý do: không đạt độ sạch
	PATIENT_STATE_POST_SUPPORT_NOT_ENOUGH_CONDITION = 8 - lý do: số lần đi chưa đủ

8-chuẩn bị soi
Là trường hợp profile bệnh nhân 
=> Mapping:
	PATIENT_STATE_POST_SUPPORT = 7
    */

  stateMapping(e):boolean {

    var ret = true;
    e.color = "black";
    e.name_color = "black";
    e.otherfield_color = "black";
    e.b1 = false;
    e.b2 = false;
    e.b3 = false;
    e.b4 = false;
    e.b5 = false;

    e.tooltip = e.user_name;
    e.tooltip = e.tooltip + "\n  Chưa có số liệu đánh giá";

    if (e.otherdataObj) {
      if (e.otherdataObj['confirmRegimen']) {
      	 var time = new Date(e.otherdataObj.confirmRegimen['endConfirmTime']). toLocaleString()
         if (!e.otherdataObj.confirmRegimen['defecation']) {
                e.tooltip = e.user_name + "\n Chưa có số liệu đánh giá";
	 } else {
        	e.tooltip = e.user_name + 
            "\n - Số lần đánh giá " + e.otherdataObj.confirmRegimen['confirmNumber'] +
	    "\n - Thơi gian đánh giá " + time;
	    if (e.otherdataObj.confirmRegimen['answerValue']) {
	    let a = e.otherdataObj.confirmRegimen['answerValue'];
	    if (a[a.length-1]=="0") {
	       e.tooltip = e.tooltip +  "\n - Kết quả đánh giá cuối : ĐÃ SẠCH";
	    }else {
		e.tooltip = e.tooltip + "\n - Kết quả đánh giá cuối : CHƯA SẠCH";
		
		}
		} else {
		e.toooltip = e.tooltip + "\n - Kết quả đánh giá cuối : CHƯA ĐÁNH GIA";
		}

	 }
      }
    }

    var isUpdateToday = this.isUpdateTodayData(e);

    if (e.patient_regimen_id == null || e.patient_regimen_id == 0) {
      e.color = "magenta";
      e.phase_str = "Sàng lọc";
      e.state_str = "Chưa sàng lọc";

      if (this.chkCountAll || isUpdateToday) {
        this.sum_sangloc++;
      }
      ret = this.chkSL;

      e.b1 = true;
      e.b4 = true;
    } else if (e.state == PATIENT_PRE_FILTER) {
      e.color = "magenta";
      e.phase_str = "Sàng lọc";
      e.state_str = "Chưa sàng lọc";

      if (this.chkCountAll || isUpdateToday) {
        this.sum_sangloc++;
      }
      ret = this.chkSL;

      e.b1 = true;
      e.b4 = true;
    } else if (e.state == PATIENT_AFTER_FILTER_NOT_ALLOW_REGIMEN) {
      //11
      e.color = "green";
      e.phase_str = "Sàng lọc";
      e.state_str = "Đã SL - chưa đủ ĐK";

      if (this.chkCountAll || isUpdateToday) {
        this.sum_sangloc++;
      }
      ret = this.chkSL;

      e.b1 = true;
      e.b4 = true;
    } else if (e.state == PATIENT_STATE_IDLE) {
      // 2
      if (e.active_code) {
        // Not null
        e.color = "magenta";
        e.phase_str = "Uống thuốc";
        e.state_str = "Chưa bắt đầu UT";
        if (this.chkCountAll || isUpdateToday) {
          this.sum_uongthuoc++;
        }
        ret = this.chkUT;

        e.b4 = true;
        e.b5 = true;
      } else {
        e.color = "blue";
        e.phase_str = "Sàng lọc";
        e.state_str = "Đã SL - đủ ĐK";

        if (this.chkCountAll || isUpdateToday) {
          this.sum_sangloc++;
        }
        ret = this.chkSL;

        e.b1 = true;
        e.b4 = true;
      }
    } else if (e.state == PATIENT_STATE_READY_ACTIVE) {
      e.color = "magenta";
      e.phase_str = "Uống thuốc";
      e.state_str = "Chưa bắt đầu UT";

      if (this.chkCountAll || isUpdateToday) {
        this.sum_uongthuoc++;
      }
      ret = this.chkUT;

      e.b4 = true;
      e.b5 = true;
    } else if (
      e.state == PATIENT_STATE_ACTIVE ||
      e.state == PATIENT_STATE_WAIT_FOR_TIME_MORNING
    ) {
      e.color = "green";
      e.phase_str = "Uống thuốc";
      e.state_str = "Đang uống thuốc";

      if (this.chkCountAll || isUpdateToday) {
        this.sum_uongthuoc++;
      }
      ret = this.chkUT;

      e.b2 = true;
      e.b4 = true;
      e.b5 = true;
    } else if (e.state == PATIENT_STATE_ACTIVE_SUSPEND) {
      e.color = "red";
      e.name_color = "red";
      e.phase_str = "Uống thuốc";
      e.state_str = "Dừng UT tạm thời";

      if (this.chkCountAll || isUpdateToday) {
        this.sum_uongthuoc++;
      }
      ret = this.chkUT;

      e.b3 = true; 
      e.b4 = true;
      e.b5 = true;
    } else if (e.state == PATIENT_STATE_SUSPEND_NOT_ALLOW_SELF_ACTIVE) {
      // 43
      e.color = "gray";
      e.otherfield_color = "gray";
      e.name_color = "gray";
      e.phase_str = "Uống thuốc";
      e.state_str = "Dừng UT hẳn";

      if (this.chkCountAll || isUpdateToday) {
        this.sum_uongthuoc++;
      }
      ret = this.chkUT;

      e.b1 = true;
      e.b4 = true;
    } else if (e.state == PATIENT_STATE_CONFIRM) {
      e.color = "green";
      e.phase_str = "Đánh giá sạch";
      e.state_str = "Chưa đủ 2h";

      if (!e.otherdataObj.confirmRegimen['defecation']) {
        e.tooltip = e.user_name + "\n Chưa có số liệu đánh giá";
      }

      if (this.chkCountAll || isUpdateToday) {
        this.sum_danhgia++;
      }
      ret = this.chkDG;

      e.b2 = true;
      e.b4 = true;
      e.b5 = true;
    } else if (
      e.state == PATIENT_STATE_CONFIRM_SUSPEND ||
      e.state == PATIENT_STATE_CONFIRM_NOT_CLEAN ||
      e.state == PATIENT_STATE_AFTER_CONFIRM
    ) {
      e.color = "red";
      e.phase_str = "Đánh giá sạch";
      e.state_str = "Chưa đủ ĐK soi";

      if (this.chkCountAll || isUpdateToday) {
        this.sum_danhgia++;
      }
      ret = this.chkDG;

      if (e.state == PATIENT_STATE_CONFIRM_SUSPEND) {
        e.b3 = true;
      }
      e.b4 = true;
      e.b5 = true;
    } else if (e.state == PATIENT_STATE_POST_SUPPORT_NOT_ENOUGH_CONDITION) {
      e.color = "gray";
      e.otherfield_color = "gray";
      e.name_color = "gray";
      e.phase_str = "Đánh giá sạch";
      e.state_str = "Dừng hẳn";

      if (this.chkCountAll || isUpdateToday) {
        this.sum_danhgia++;
      }
      ret = this.chkDG;

      e.b1 = true;
      e.b4 = true;
    } else if (e.state == PATIENT_STATE_POST_SUPPORT) {
      e.color = "gray";
      e.phase_str = "Chờ soi";
      e.state_str = "";

      if (this.chkCountAll || isUpdateToday) {
        this.sum_chosoi++;
      }
      ret = this.chkCS;

      e.b5 = true;
    }
    //HaBM
    if(e['step_timing']){
      let stepTime = JSON.parse(e['step_timing']);
      //console.log(stepTime);
      if(stepTime[0]){
        //console.log(stepTime[0]);
        e['start_time'] = moment(stepTime[0].start_time).format("DD/MM HH:mm");
      }
    }else{
      e['start_time'] = "";
    }

    // Check ngay
    if (e['updated_date_str']) { //start_time
      //HaBM
      e['updated_date_str'] = moment(e['updated_date']).format("DD/MM HH:mm");

      if (e['updated_date_str'].substring(0, 5) == this.today) {
        if (!this.chkToday)
          ret = false;
      } else {
        if (!this.chkOtherDay)
          ret = false;
      }

      // // Check today
      // if (c) {
      //   ret = (e['start_time'].substring(1, 5) == this.today) && ret;
      // } else {
      //   ret = (e['start_time'].substring(1, 5) != this.today) && ret;
      // }

      // if (this.chkOtherDay) {
      //   ret = (e['start_time'].substring(1, 5) != this.today) && ret;
      // } else {
      //   ret = (e['start_time'].substring(1, 5) == this.today) && ret;
      // }

      
    }  else { // Null ?
      //ret = true;
      //e['start_time'] = 'NULL?' +e['start_time'];
    }

    // Regimen where 

    if (e['regimen_where']) {
      if (e['regimen_where']=='home') {
        if (!this.chkNha)
          ret = false;
      } else {
        if (!this.chkPK)
          ret = false;
      }
    }

    return ret;
  }

  // Check neu duoc cap nhat ngay hom nay -> dung cho loc dem ngay
  isUpdateTodayData(e) {

    // if (e['start_time']) {
    //   return e['start_time'].substring(1, 5) == this.today;            
    // }  else { 
    //   return false;
    // }

    if (e['updated_date_str']) {
      //e['updatetoday'] = e['updated_date_str'].substring(0, 5);
      return e['updated_date_str'].substring(0, 5) == this.today;            
    }  else { 
      //e['updatetoday'] = 'F';
      //if ()
      return true;
    }
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


export class PatientElement {
  idx: number;
  patient_id: number;
  telephone: string;
  gender: string;
  date_of_birth: string;
  remark: string;
  email: string;
  created_date: string;
  updated_date: string;
  status: string;
  user_name: string;
  lastest_patient_regimen_id: number;
  patient_regimen_id: number;
  regimen_id: string;
  active_code: string;
  state: number;
  step_timing: string;
  start_time: string;
  regimen_where: string;
  regimen_where_show: string;
  doctor_name: string;
  state_str: string;
  color: string;
  updated_date_str: string;
  tooltip: string;
  other_data:string;
  otherdataObj:any;
}

export class DoctorElement {
  id: number;
  name: string;
  location: string;
  telephone: string;
  state: number;
  order: number;
}
