import { Component, OnInit,ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {UserProfileService} from '../user-profile.service';
import {MatSort} from '@angular/material/sort';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {checkTimeOut} from '../utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-message-management',
  templateUrl: './message-management.component.html',
  styleUrls: ['./message-management.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MessageManagementComponent implements OnInit {

  displayedColumns: string[] = ['position','title','tag','desc', 'content', 'created_at','updated_at', 'edit','delete'];
  
  listData: any;
  dataSource = new MatTableDataSource<PeriodicElement>(this.listData);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
        private userProfileService: UserProfileService,
        private router: Router
          ) {

  }
           
  ngOnInit() {
    this.getNotifyGlobals();
    this.dataSource.sort = this.sort;
  }

 
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
   }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  getNotifyGlobals() {
    this.listData = [];
    this.userProfileService.getNotifyGlobals().subscribe({next:(data: []) => {

      if (!checkTimeOut(data,this.router)) {
          return;
      }
      
      var idx = 1;
      data.forEach(e => {
        var obj= new PeriodicElement();
        obj = e;
        obj.position = idx;
        idx = idx +1 ;
        this.listData.push(obj);
      });

      this.dataSource.data = this.listData ;//= new MatTableDataSource<PeriodicElement>(this.lastaccess); 
      this.dataSource.sort = this.sort;
      //this.dataSource.paginator = this.paginator;   
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
  }

  clickEditMethod(msgId: string) {
    this.router.navigate(["message-edit/" , msgId]);
  }
  
  clickDeleteMethod(msgId: string) {
    if (confirm("Có chắc chắn muốn xoá bản tin id=" + msgId + " ? \nLưu ý: Không khôi phục được số liệu!")) {
      this.userProfileService.delNotifyGlobalsById(msgId )
        .subscribe({next:data => {
          alert("Xoa thanh cong");
          this.ngOnInit();
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});
    }
  }
}

export class PeriodicElement {
  position: number;
  id : number;
  title : string;
  tag: string;
  desc: string;
  content: string;
  created_at: string;  
  updated_at : string;
  userType: string;
    
}
