import { Component, ViewChild, ElementRef, AfterViewInit, HostListener,OnInit } from '@angular/core';
import { ResizeService } from '../resize.service';
import * as myGlobal from '../globals';


@Component({
  selector: 'app-size-detector-component',
  templateUrl: './size-detector-component.component.html',
  styleUrls: ['./size-detector-component.component.scss']
})
export class SizeDetectorComponentComponent implements OnInit,AfterViewInit{

  prefix = 'is-';
  sizes = [
    {
      id: myGlobal.SCREEN_SIZE.XS, name: 'xs',
      css: `d-block d-sm-none`
    },
    {
      id: myGlobal.SCREEN_SIZE.SM, name: 'sm',
      css: `d-none d-sm-block d-md-none`
    },
    {
      id: myGlobal.SCREEN_SIZE.MD, name: 'md',
      css: `d-none d-md-block d-lg-none`
    },
    {
      id: myGlobal.SCREEN_SIZE.LG, name: 'lg',
      css: `d-none d-lg-block d-xl-none`
    },
    {
      id: myGlobal.SCREEN_SIZE.XL, name: 'xl',
      css: `d-none d-xl-block`
    },
  ];

  constructor(
    private elementRef: ElementRef, 
    private resizeSvc: ResizeService
  ) { }

  ngOnInit(): void {
  }

  @HostListener("window:resize", [])
  private onResize() {
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    const currentSize = this.sizes.find(x => {
      const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${x.id}`);
      const isVisible = window.getComputedStyle(el).display != 'none';

      return isVisible;
    });

    this.resizeSvc.onResize(currentSize.id);
  }

}
