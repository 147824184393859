
<div class="container-md">
    <h4 class="text-primary">Quản lý phác đồ điều trị</h4>
    

  <div class="row">    

    <mat-form-field>

      <mat-label>Lọc tìm kiếm</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Nhập thông tin tìm kiếm và lọc">
    </mat-form-field>
   
    <a routerLink="/regimen-info/addNew" ><button type="submit" class="btn btn-primary" ><i class="fa fa-check"></i> Tạo mới</button> </a><br>
    <table mat-table [dataSource]="dataSource" class="table table-bordred table-striped table-hover" matSort multiTemplateDataRows>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> Order No. </th>
        <td mat-cell *matCellDef="let element" > {{element.position}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Trạng thái </th>
        <td mat-cell *matCellDef="let element"> 
            <i class="fa fa-check text-success"></i> 
            
      </ng-container>

     
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Mã phác đồ ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
  
       <!-- Name Column -->
       <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Tên phác đồ </th>
        <td mat-cell *matCellDef="let element" > {{element.name}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef> Version </th>
        <td mat-cell *matCellDef="let element"> {{element.version}} </td>
      </ng-container>
  
      <!-- Weight Column -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef> Ngày tạo </th>
        <td mat-cell *matCellDef="let element" > {{element.created_at}} </td>
      </ng-container>

      <ng-container matColumnDef="updated_at">
        <th mat-header-cell *matHeaderCellDef> Ngày cập nhật </th>
        <td mat-cell *matCellDef="let element" > {{element.updated_at}} </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> Edit </th>
        <td  mat-cell *matCellDef="let element">
          <p data-placement="top" data-toggle="tooltip" title="Edit">
            <button class="btn btn-primary btn-xs" 
              data-title="Edit" (click)="onClickEdit(element.id)"  data-toggle="modal" data-target="#edit" ><i class="fa fa-pencil-square-o"></i></button></p>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef> Delete </th>
        <td  mat-cell *matCellDef="let element">
          <p data-placement="top" data-toggle="tooltip" title="Delete">
            <button class="btn btn-danger btn-xs" 
              data-title="Delete" (click)="clickDeleteMethod(element.id)"   data-toggle="modal" data-target="#delete" 
              [disabled]="!enable_delete_btn">
              <i class="fa fa-trash"></i></button></p>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>

  
</div>
</div>
