import {Component, OnInit} from '@angular/core';
import {UserProfileService} from '../user-profile.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Location} from '@angular/common';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {

  userId = '0';
  submitted = false;
  userName = '';
  buttonSubmitTitle = 'Cập nhật';

  isAddNew = false;

  adminUser = false;

  userForm: FormGroup;


  constructor(
      private route: ActivatedRoute,
      private formBuilder: FormBuilder,
      private userProfileService: UserProfileService,
      private _location: Location,
      private router: Router
  ) { }

  ngOnInit() {

    this.userForm = this.formBuilder.group({
      //username: ['', Validators.required ],
      username: [{value:'' , disabled: this.adminUser}, [Validators.required]],
      msisdn: ['', Validators.required],
      email: ['', Validators.required],
      webAppRoles: ['', Validators.required],
      passwd: ['', null],
      reenter_passwd: ['', null],
      blocked: ['', null],
      location: ['',Validators.required],
    });



    this.userId = this.route.snapshot.paramMap.get('userId');

    if (this.userId == 'addNew') {
      this.isAddNew = true;
      this.userId = '0';
      this.buttonSubmitTitle = 'Tạo mới';
    } else {
      this.isAddNew = false;
      this.buttonSubmitTitle = 'Cập nhật';
      this.userProfileService.getUserInfo(this.userId  )
          .subscribe({next:(data) => {
            this.userName = data['username'];

            this.userForm.controls.username.setValue( data['username']);
            this.userForm.controls.msisdn.setValue( data['msisdn']);
            this.userForm.controls.email.setValue( data['email']);
            this.userForm.controls.webAppRoles.setValue( data['webAppRoles']);
            this.userForm.controls.blocked.setValue( data['blocked']);
            this.userForm.controls.location.setValue( data['location']);
            

            if (this.userName=='admin') {
              this.adminUser = true;
              this.userForm.controls.username.disable();
              this.userForm.controls.webAppRoles.disable();
            }

          }, error:err => {
            console.error('Observer got an error: ' + err);
            alert('Lỗi kết nối mạng! ');
          }});
    }
  }

  changeRole(event) {

  }

  invalidUsername()
  {
    return (this.submitted && this.userForm.controls.username.errors != null) ;
  }
  invalidTelephone()
  {
    return (this.submitted && this.userForm.controls.msisdn.errors != null) ;
  }
  invalidEmail()
  {
    return (this.submitted && this.userForm.controls.email.errors != null) ;
  }

  invalidRoles()
  {
    return (this.submitted && this.userForm.controls.webAppRoles.errors != null) ;
  }
  invalidLoc()
  {
    return (this.submitted && this.userForm.controls.location.errors != null) ;
  }

  invalidPassword()
  {
    return (this.submitted && this.userForm.controls.passwd.errors != null) && (this.isAddNew) ;
  }

  invalidRePassword()
  {
    return ((this.submitted && this.userForm.controls.reenter_passwd.errors != null)  && (this.isAddNew) ) || (
      this.userForm.controls.reenter_passwd.value != this.userForm.controls.passwd.value
    ) ;
  }

  onGoBack() {
    this._location.back();
  }

  onSubmit() {
    this.submitted = true;

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser['user'].id != this.userId) {
      if  (currentUser['user'].webAppRoles !='sysadmin') {
        alert("Permission denied!");
        this._location.back();
        return;
      }
    }

    if(this.userForm.invalid != true )
  	{

      if (!this.isAddNew) {
        if (confirm("Are you sure to update user info " + this.userName  + " ( id=" + this.userId + ") ? ")) {
            this.userProfileService.updateUserInfo(this.userId ,{
              username:this.userForm.controls.username.value ,
              msisdn: this.userForm.controls.msisdn.value ,
              email: this.userForm.controls.email.value ,
              webAppRoles: this.userForm.controls.webAppRoles.value ,
              location: this.userForm.controls.location.value ,
              blocked: this.userForm.controls.blocked.value,
            } )
            .subscribe({next:data => {
              //this.userName = data.username;
                this.userProfileService.sysLog('UserInfoComponent','Update User',
                    this.userForm.controls.username.value   + " ( id=" + this.userId + "):" + 
                    this.userForm.controls.msisdn.value + "/" +
                    this.userForm.controls.webAppRoles.value  );
                alert("Cập nhật thành công!");
                this._location.back();
            }, error:err => {
              console.error('Observer got an error: ' + err);
              alert('Lỗi kết nối mạng! ');
            }});
        }
      } else {
        this.userProfileService.createUser({
            username:this.userForm.controls.username.value ,
            msisdn: this.userForm.controls.msisdn.value ,
            email: this.userForm.controls.email.value ,
            webAppRoles: this.userForm.controls.webAppRoles.value ,
            location: this.userForm.controls.location.value ,
            password:this.userForm.controls.reenter_passwd.value,
            blocked: this.userForm.controls.blocked.value,
          } )
        .subscribe({next:data => {
          //this.userName = data.username;

            this.userProfileService.sysLog('UserInfoComponent','Create User',
                this.userForm.controls.username.value   + " ( id=" + this.userId + "):" + 
                this.userForm.controls.msisdn.value + "/" +
                this.userForm.controls.webAppRoles.value  );

             alert("Tao moi thành công!");


            this._location.back();
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});
      }
  	} else {


      alert("Thieu thong tin" );
    }
  }


  changeOrgId($event: Event) {

  }
  changeLoc($event: Event) {

  }
}
