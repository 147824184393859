<div class="container-lg">
    <h4 class="text-primary">Cập nhật thông báo</h4>
  
    <form [formGroup]="userForm">
      <div class="row">
        <div class="col-md-12">
          <h4 class="bold">Nhập thông tin</h4>
          <div class="form-group" [ngClass]="{ 'has-danger': invalidTitle() }"> 
            <label for="title" class="form-control-label">Tiêu đề *</label>
            <input type="text" formControlName="title" class="form-control" [ngClass]="{ 'is-invalid': invalidTitle() }" id="title" name="title" placeholder="Tiêu đề">
            <p *ngIf="invalidTitle()" id="title_error" class="invalid-feedback">Nhập thông tin tiêu đề.</p>
          </div>

          <div class="form-group" [ngClass]="{ 'has-danger': invalidDesc() }"> 
            <label for="desc" class="form-control-label">Mô tả *</label>
            <input type="text" formControlName="desc" class="form-control" [ngClass]="{ 'is-invalid': invalidDesc() }" id="desc" name="desc" placeholder="Mô tả">
            <p *ngIf="invalidDesc()" id="title_error" class="invalid-feedback">Nhập thông tin mô tả.</p>
          </div>

          <div class="form-group" [ngClass]="{ 'has-danger': invalidTag() }"> 
            <label for="tag" class="form-control-label">Tag</label>
            <input type="text" formControlName="tag" class="form-control" [ngClass]="{ 'is-invalid': invalidTag() }" id="tag" name="tag" placeholder="Thẻ tag">
            <p *ngIf="invalidTag()" id="title_error" class="invalid-feedback">Nhập thông thẻ tag</p>
          </div>

          <div class="form-group" [ngClass]="{ 'has-danger': invalidContent() }"> 
            <label for="content" class="form-control-label">Nội dung</label>
            <input type="text" formControlName="content" class="form-control" [ngClass]="{ 'is-invalid': invalidContent() }" id="content" name="content" placeholder="Nội dung">
            <p *ngIf="invalidContent()" id="title_error" class="invalid-feedback">Nhập nội dung thông tin</p>
          </div>

          <div class="form-group" [ngClass]="{ 'has-danger': invalidCreatedAt() }"> 
            <label for="created_at" class="form-control-label">Ngày tạo</label>
            <input type="text" formControlName="created_at" class="form-control" [ngClass]="{ 'is-invalid': invalidCreatedAt() }" id="created_at" name="created_at" placeholder="Ngày tạo">
            <p *ngIf="invalidCreatedAt()" id="title_error" class="invalid-feedback">Nhập nội dung thông tin</p>
          </div>

          <div class="form-group" [ngClass]="{ 'has-danger': invalidUpdatedAt() }"> 
            <label for="updated_at" class="form-control-label">Ngày cập nhật</label>
            <input type="text" formControlName="updated_at" class="form-control" [ngClass]="{ 'is-invalid': invalidUpdatedAt() }" id="updated_at" name="updated_at" placeholder="Ngày cập nhật">
            <p *ngIf="invalidUpdatedAt()" id="title_error" class="invalid-feedback">Nhập nội dung thông tin</p>
          </div>

         </div>
      </div>
      <div class="form-group has-error">
        <button type="submit" class="btn btn-primary" (click)="onUpdate()">
          <i class="fa fa-check"></i> Cập nhật
        </button>
        <button type="submit" class="btn btn-success" (click)="onAddNew()">
            <i class="fa fa-check"></i> Tạo mới
          </button>

      </div>
    </form>
    <hr>
    <app-message-management></app-message-management>
  </div>
  