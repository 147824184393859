import { Component, OnInit,ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {UserProfileService} from '../user-profile.service';
import {MatSort} from '@angular/material/sort';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {checkTimeOut} from '../utils';
import {Router} from '@angular/router';
import {ConfirmationDialogComponent} from '../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { jsonpFactory } from '@angular/http/src/http_module';

@Component({
  selector: 'app-filter-question-man',
  templateUrl: './filter-question-man.component.html',
  styleUrls: ['./filter-question-man.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class FilterQuestionManComponent implements OnInit {

  quest_list = [];
  loadData  :any;
  dataSource = new MatTableDataSource<FilterQuestionElement>(this.quest_list);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private userProfileService: UserProfileService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getFilterQuestList();
  }


  getFilterQuestList() {
    this.quest_list = [];

    var filterType = '0';

    this.userProfileService.getFilterQuestionList(filterType).subscribe({next:(data: []) => {

      // if (!checkTimeOut(data,this.router)) {
      //     return;
      // }
      //console.log('DAT:' + JSON.stringify(data));
      //this.lastaccess = data;

      //console.log("Data ", JSON.stringify(data));
      var idx = 0;

      this.loadData = data;

      data.forEach(e => {
        idx ++;
        var obj= new FilterQuestionElement();

        obj = e;
        obj['idx'] = idx;
        obj['pos'] = 0; // Xac dinh vi tri de an nut up/down

        if (!obj.parentId || (Object.keys(obj.parentId).length === 0)) {
          obj.ids.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));

          if (obj.ids && obj.ids.length>1) {
            obj.ids[0]['pos'] = 1;
            obj.ids[obj.ids.length-1]['pos'] = 2;
          }
          this.quest_list.push(obj);
        }
      });

      this.quest_list.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))

      if (this.quest_list && this.quest_list.length>1)  {
        this.quest_list[0]['pos'] = 1;
        this.quest_list[this.quest_list.length-1]['pos'] = 2;
      }

      this.dataSource.data = this.quest_list ;
      this.dataSource.sort = this.sort;

    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
  }

  onSubQuestEdit(quest, parent) {
    if (parent==null) {
      this.editQuest(quest.id, 0,0);  // Edit Question
    } else {
      this.editQuest(quest.id, parent.id,0); // Edit SubQuestion
    }
  }



  onSubQuestDelete(quest,subquest) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data:"Có chắc chắn muốn xoá câu hỏi này không ?"
    });
    dialogRef.afterClosed().subscribe({next:(result) => {
      if(result) {

        var idx =  quest.ids.indexOf(subquest);
        if (idx > -1 ) {
          quest.ids.splice(idx,1);

          this.userProfileService.deleteFilterQuestion(subquest.id)
            .subscribe({next:(data) => {
               alert("Xoá thành công!");
            }, error:err => {
              console.error('Observer got an error: ' + err);
              alert('Lỗi kết nối mạng! ');
            }});
        }

        //alert('Xoa thanh cong');
      }
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});
  }

  onSubQuestUp(quest,subquest) {
    var idx =  quest.ids.indexOf(subquest);
    if ( idx ==0 ) return;

    var tmp = quest.ids[idx - 1];
    var pos = tmp['pos'];
    quest.ids[idx - 1] = subquest;
    quest.ids[idx] = tmp;
    tmp['pos'] = subquest['pos'];
    subquest['pos'] = pos;

    this.reOrderQuests( quest.ids);
  }


  onSubQuestDown(quest,subquest) {
    var idx =  quest.ids.indexOf(subquest);
    if ( idx >= quest.ids.length -1 ) return;

    var tmp =  quest.ids[idx + 1];
    var pos = tmp['pos'];
    quest.ids[idx + 1] = subquest;
    quest.ids[idx] = tmp;

    tmp['pos'] = subquest['pos'];
    subquest['pos'] = pos;

    this.reOrderQuests( quest.ids);
  }

  onSubQuestAdd(quest,subquest) {

    if (subquest) {
      this.editQuest(0, quest.id,subquest.index); // Edit SubQuestion
    } else {
      this.editQuest(0, quest.id,0);
    }

  }

  onRemoveSession(quest) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Có chắc chắn muốn các câu hỏi này không ?"
    });
    dialogRef.afterClosed().subscribe({next:(result) => {
      if(result) {
        var idx =  this.quest_list.indexOf(quest);
        if (idx > -1 ) {
          this.quest_list.splice(idx,1);

          this.userProfileService.deleteFilterQuestion(quest.id)
            .subscribe({next:(data) => {
               alert("Xoá thành công!");
            }, error:err => {
              console.error('Observer got an error: ' + err);
              alert('Lỗi kết nối mạng! ');
            }});

        }


      }
    }, error:err => {
      console.error('Observer got an error: ' + err);
      alert('Lỗi kết nối mạng! ');
    }});

  }

  onInsertSession(quest) {
    alert("onInsertSession " +  JSON.stringify(quest));
  }

  onMoveUpSession(quest) {
    var idx =  this.quest_list.indexOf(quest);
    if ( idx ==0 ) return;
    var tmp = this.quest_list[idx - 1];
    var  pos = tmp['pos'];
    this.quest_list[idx - 1] = quest;
    this.quest_list[idx] = tmp;

    tmp['pos'] = quest['pos'];
    quest['pos'] = pos;

    this.reOrderQuests(this.quest_list);
    //alert("onMoveUpSession " +  idx);
  }

  onMoveDownSession(quest) {
    var idx =  this.quest_list.indexOf(quest);
    if ( idx >= this.quest_list.length -1 ) return;

    var tmp = this.quest_list[idx + 1];
    var  pos = tmp['pos'];
    this.quest_list[idx + 1] = quest;
    this.quest_list[idx] = tmp;

    tmp['pos'] = quest['pos'];
    quest['pos'] = pos;

    this.reOrderQuests(this.quest_list);
    //alert("onMoveDownSession " +  idx);

  }

  onAddNewSession() {
    this.editQuest(0, 0,0); // Add new Session
  }

  reOrderQuests(list) {
    let idx = 1;
    list.forEach(oriElement => {
      oriElement.index = idx;
      //clone element
      let element = Object.assign({}, oriElement);
      //remove attribute not needed
      delete element.idx;
      delete element.pos;
      //end
      //console.log("DEBUG: " + JSON.stringify(element));
      this.userProfileService.updateFilterQuestion(element.id, element)
        .subscribe({next:(data) => {
          //console.log("DEBUG: Update OK " + element.id);
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }});

      idx = idx + 1;
    });
  }

  editQuest(id,parent_id,idsIndex) {
    this.router.navigateByUrl('/filterquest/' + id + '/' + parent_id + '/' + idsIndex);
  }
}

export class FilterQuestionElement {
  id: number;
  index : number;
  quest : string;
  created_at: Date;
  updated_at: Date;
  parentId: FilterQuestionElement;
  ids: FilterQuestionElement[];
  gender : string;
}
