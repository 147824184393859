import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
//import {MatDialog, MatDialogConfig} from "@angular/material";
//import { RegisterFormComponent } from './register-form/register-form.component';
import { AuthenticationService } from './authentication.service';
import { User } from './models';
import {UserProfileService} from './user-profile.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  currentUser:User = null;//User = new User();
  sysadmin = false;
  supporter = false;
  editor = false;

  currentURL =  window.location.href.replace(/(^\w+:|^)\/\//, '');
  orgName = '';

  telephone : string = null;

  userForm: FormGroup;
  
  
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private usrProfileService:UserProfileService,
    private formBuilder: FormBuilder,  
  )//private dialog: MatDialog)
  {
    // this.authenticationService.currentUser.subscribe(x => {
    //   if (x!==null) {
    //     this.currentUser = x; 
    //     this.sysadmin = this.currentUser.webAppRoles=='sysadmin';
    //   }
    //   console.log('init: sysadmin=',this.sysadmin);
    // });
    
    // this.currentURL =  window.location.href.replace(/(^\w+:|^)\/\//, '');
    // this.orgName = this.currentURL.split(".")[0];
    // this.orgName = this.orgName.split(":")[0];
  }

  ngOnInit()  {
   // localStorage.setItem('currentUser', null);
   
    this.authenticationService.currentUser.subscribe(x => {
      if ((x!==null) && (x.hasOwnProperty('user'))) {
        this.currentUser = x; 
        
        this.sysadmin = this.currentUser.user.webAppRoles=='sysadmin';
        this.supporter = this.currentUser.user.webAppRoles=='supporter';
        this.editor = this.currentUser.user.webAppRoles=='editor';

        // Neu la sysadmin se co toan bo cac quyen
        if (this.sysadmin) {
          this.supporter  = true;
          this.editor  = true;
        }
      } else {
        this.currentUser = null;
        this.sysadmin = false;
        this.supporter = false;
        this.editor =  false;
      }
      // console.log('Current:',x);
      // console.log('init: sysadmin=',this.sysadmin);
    });

    this.userForm = this.formBuilder.group({      
      searchData: ['', Validators.required],      
    });

  };

  logout() {
    this.usrProfileService.sysLog('Logout','Logout!!!',this.currentUser.user.username );
    this.authenticationService.logout();
    this.currentUser = null;
    this.router.navigate(['/login']);
  }
  
  login() {    
    this.authenticationService.logout();
    this.currentUser = null;
    this.router.navigate(['/login']);
  }

//   openDialog() {
//     const dialogConfig = new MatDialogConfig();

//     dialogConfig.disableClose = true;
//     dialogConfig.autoFocus = true;

//     this.dialog.open(RegisterFormComponent, dialogConfig);
// }

  doSearch() {
    console.log('Search ' + this.userForm.controls.searchData.value);
    this.router.navigate(['/patient-search/' + this.userForm.controls.searchData.value]);

  }


  
}
