import {Component, OnInit} from '@angular/core';
import {AngularEditorConfig} from '@kolkov/angular-editor';

import {CKEditor4,} from 'ckeditor4-angular';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UserProfileService} from '../user-profile.service';
import * as myGlobal from '../globals';

// @NgModule({
//   imports: [HttpClientModule, AngularEditorModule, CKEditorModule],
// })
@Component({
    selector: 'app-article-item',
    templateUrl: './article-item.component.html',
    styleUrls: ['./article-item.component.scss'],
})
export class ArticleItemComponent implements OnInit {
    postId = '0';
    title = 'app';
    postItem = new PostItem();

    articalForm: FormGroup;

    htmlContent = '';
    oriArticle = [];

    onFileUploadRequest(event: CKEditor4.EventInfo, editorName: string): void {
        console.log(`File upload requested in editor.`);
        var fileLoader = event.data.fileLoader,
            formData = new FormData(),
            xhr = fileLoader.xhr;

        xhr.open('POST', fileLoader.uploadUrl, true);
        formData.append('files', fileLoader.file, fileLoader.fileName);
        console.log(`File name:` + fileLoader.fileName);
        //set header
        xhr.setRequestHeader(
            'Authorization',
            this.userProfileService.getHttpAuthorization()
        );
        xhr.send(formData);
        // fileLoader.xhr.send(formData);
        // Prevented the default behavior.
        event.stop();
    }

    onFileUploadResponse(event: CKEditor4.EventInfo, editorName: string): void {
        console.log(`File upload responded in ${editorName} editor.`);
        event.stop();

        // Get XHR and response.
        var data = event.data,
            xhr = data.fileLoader.xhr,
            response = xhr.responseText.split('|');

        if (response[1]) {
            // An error occurred during upload.
            data.message = response[1];
            event.cancel();
        } else {
            let tmp = JSON.parse(response[0]);
            data.url = myGlobal.api_endpoint_1_full_path + tmp[0].url;
            console.log('Return url' + response[0]);
        }
    }

    configCK: any = {
        imageUploadUrl: myGlobal.api_imgupload_url,
        uploadUrl: myGlobal.api_imgupload_url,
        filebrowserUploadUrl: myGlobal.api_imgupload_url,
        filebrowserBrowseUrl: 'https://mobileapp.hoanglongclinic.vn:1337/admin/plugins/upload',//http://35.241.94.181:1337/admin/plugins/upload',
        // fileUploadRequest:this.fileUploadRequest()
    };

    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        minHeight: '5rem',
        maxHeight: '30rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        sanitize: false,
        // toolbarPosition: 'top',
        outline: true,
        defaultFontName: 'Comic Sans MS',
        defaultFontSize: '5',
        // showToolbar: false,
        defaultParagraphSeparator: 'p',
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText',
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
        uploadUrl: myGlobal.api_imgupload_url,
        // uploadUrl:'http://35.241.94.181:1337/upload'
    };
    isVideo: boolean;
    typeList: any = [{ id: 1, desc: 'Bài viết và chăm sóc sau nội soi' }, { id: 2, desc: 'Các gói nội soi' }];
    type: any;
    categoryId: any;
    oriCategoryList: any;
    categoryList: any;

    constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private userProfileService: UserProfileService,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.articalForm = this.formBuilder.group({
            signature: ['', Validators.required],
            //fixme using formControlName to mapping value to form
            tags: [''],
            title: [''],
            type: [''],
            url: [''],
            isVideo: [''],
            categoryId: [''],
            desc: [''],
        });
        this.getCategoryChild();



        console.log(this.htmlContent);
    }

    getPostById(postId: string) {
        this.userProfileService.getPostById(postId).subscribe((data) => {
            this.postItem = data[0];
            this.oriArticle = data[0];
            this.htmlContent = this.postItem.contentHtml;
            this.articalForm.patchValue({
                tags: this.postItem.tags,
                title: this.postItem.title,
                type: this.postItem.type,
                url: this.postItem.url,
                isVideo: this.postItem.isVideo,
                categoryId: this.postItem.categoryId,
                desc: this.postItem.desc,
            });
            this.type = this.postItem.type;
            this.categoryId = this.postItem.categoryId;
            if(this.type == 1){
                this.categoryList = Object.assign([],this.oriCategoryList);
            } else {
                this.categoryList = [];
            }
            });
    }

    private getCategoryChild() {
        //query categoryList
        this.userProfileService.getCategoryChild().subscribe((data) => {
            // console.log(JSON.stringify(data))
            this.oriCategoryList = data;
            if(this.type == 1){
                this.categoryList = Object.assign([],this.oriCategoryList);
            } else {
                this.categoryList = [];
            }
            //get PostId
            this.postId = this.route.snapshot.paramMap.get('postId');
            if (this.postId == undefined || this.postId == '0') {
                return;
            }
            this.getPostById(this.postId);

        });
    }

    onChange(event) {
        console.log('changed');
    }

    onBlur(event) {
        console.log('blur ' + event);
    }

    onChange2(event) {
        console.warn(this.articalForm.value);
    }

    onSaveHtml() {
        this.oriArticle['contentHtml'] = this.htmlContent;
        /**
         * tags: this.postItem.tags,
         title:this.postItem.title,
         type:this.postItem.type,
         url:this.postItem.url,
         isVideo:this.postItem.isVideo,
         categoryId:this.postItem.categoryId,
         desc:this.postItem.desc,
         */
        this.oriArticle['tags'] = this.articalForm.value.tags;
        this.oriArticle['title'] = this.articalForm.value.title;
        this.oriArticle['type'] = this.articalForm.value.type;
        this.oriArticle['url'] = this.articalForm.value.url;
        this.oriArticle['isVideo'] = this.articalForm.value.isVideo;
        this.oriArticle['categoryId'] = this.articalForm.value.categoryId;
        this.oriArticle['desc'] = this.articalForm.value.desc;


        console.log('html new:' + JSON.stringify(this.oriArticle));
        //
        // console.log("item new:" + JSON.stringify(this.articalForm.value));


        if (this.oriArticle['id'] == undefined || this.oriArticle['id'] == '0') {
            //create
            this.userProfileService
                .createPost(this.oriArticle)
                .subscribe({next:(data) => {
                    // this.userName = data.username;
                    alert('Tạo bài viết thành công!');
                }, error:err => {
                    console.error('Observer got an error: ' + err);
                    alert('Lỗi kết nối mạng! ');
                  }});
        } else {
            this.userProfileService
                .updatePostById(this.oriArticle['id'], this.oriArticle)
                .subscribe({next:(data) => {
                    // this.userName = data.username;
                    alert('Cập nhật bài viết thành công!');
                }, error:err => {
                    console.error('Observer got an error: ' + err);
                    alert('Lỗi kết nối mạng! ');
                  }});
        }

    }

    changeType($event: Event) {
        console.log("set type to "+this.type);
        console.log('onchangeType event:' + this.type);
        this.articalForm.patchValue({
            type: this.type,
        })
        if(this.type == 1){
            this.categoryList = Object.assign([],this.oriCategoryList);
        } else {
            this.categoryList = [];
        }
    }

    changeCategory($event: Event) {
        console.log("set category to "+this.categoryId);
        this.articalForm.patchValue({
            categoryId: this.categoryId
        })
    }


}

export class PostItem {
    id: number;
    title: string;
    desc: string;
    type: number;
    url: string;
    tags: string;
    categoryId: number;
    isVideo: boolean;
    contentHtml: string;
}
