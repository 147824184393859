'use strict';

//export const api_endpoint = 'http://35.241.94.181:1337/';

// LOCAL -
// export const api_endpoint_1 = 'https://mobileapp.hoanglongclinic.vn/api1/'; // strapi
// export const api_endpoint_2 = 'https://mobileapp.hoanglongclinic.vn/api2/';   //backend

/// Chi dung khi debug tai local
// export const api_endpoint_1 = 'http://localhost:8085/api1/'; // strapi
// export const api_endpoint_2 = 'http://localhost:8085/api2/';   //backend

// // To PRODUCT -- Su dung khi dua len server that - Co Nginx lam proxy
 export const api_endpoint_1 = '/api1/';     // strapi
 export const api_endpoint_2 = '/api2/';     //backend

export const api_endpoint_1_full_path =  "https://mobileapp.hoanglongclinic.vn/api1";
export const api_endpoint_2_full_path =  "https://mobileapp.hoanglongclinic.vn/api2";

//web socket
export const  api_mobile_app_ws = 'https://mobileapp.hoanglongclinic.vn/websocket';

//end

export const call_api_key = "mk7sadjoi234s238a9fd";  //auth-key

//--------------------------------
export const api_imgupload_url: string = api_endpoint_1 + 'upload';
export const api_avatarupload_url: string =  api_endpoint_1 + 'avatar_upload/';
export const api_avatarget_url: string = api_endpoint_1 + 'avatar_get/';
export const api_avatarmod_url: string = api_endpoint_1 + 'avatar_mod/';

export const api_imgupload_url_new: string = api_endpoint_1 + 'admin/plugins/upload';

export enum patient_events {
    patient_change_state = 'patient change state', 
}

export enum SCREEN_SIZE {
    XS,
    SM,
    MD,
    LG,
    XL
  }

