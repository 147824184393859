import { Component, OnInit } from '@angular/core';
import * as myGlobal from '../globals'; 
import {UserProfileService} from '../user-profile.service';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
import {checkTimeOut} from '../utils';


@Component({
  selector: 'app-organization-manager',
  templateUrl: './organization-manager.component.html',
  styleUrls: ['./organization-manager.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OrganizationManagerComponent implements OnInit {

  public rootOrganData = {'org_name':'-'};

  orgList: any;
  dataSource = new MatTableDataSource<PeriodicElement>(this.orgList);

  columnsToDisplay = ['org_id','org_parent_id','org_name','short_name', 'org_desc','edit','delete'];
  expandedElement: PeriodicElement | null;

  constructor(private userProfileService: UserProfileService,
            private router: Router) {
   
   }

   getOrgId() {
    let data = JSON.parse(localStorage.getItem('currentUser'));
    return data.org_id;
  }

  ngOnInit() {
    this.getOrgData(this.getOrgId(),0);
    this.getOrgListFromParentOrgId(this.getOrgId());
  }

  getOrgData(orgId, rootOrgId): void {
    this.userProfileService.getOrgData(orgId,rootOrgId)
        .subscribe(data => {

          if (!checkTimeOut(data,this.router)) {
            return;
        }

        
          this.rootOrganData = data[0];
          console.log("Call getOrgData!");
          console.log(data);
         
        });
  }

  
  clickNewSubOrg(parentOrgId) {
    this.router.navigate(["org-data/new/" , parentOrgId]);
    
  }

  getOrgListFromParentOrgId(orgId) {
    this.orgList = [];
    this.userProfileService.getOrgListFromParentOrgId(orgId).subscribe((data: []) => {
      //console.log('DAT:' + JSON.stringify(data));
      //this.lastaccess = data;
      
      if (!checkTimeOut(data,this.router)) {
        return;
    }

      data.forEach(e => {
        var obj= new PeriodicElement();


        obj.org_id = e['org_id'];
        obj.org_name = e['org_name'];
        obj.org_desc = e['org_desc'];
        obj.short_name = e['short_name'];
        obj.org_parent_id = e['org_parent_id'];
        obj.root_org_id = e['root_org_id'];
        obj.org_type = e['org_type'];
        obj.address  = e['address'];
        obj.contact_point  = e['contact_point'];
        obj.telephone = e['telephone'];
        obj.email = e['email'];
        obj.pos_idx = e['pos_idx'];
        obj.created_on = e['created_on'];

        
        this.orgList.push(obj);
       
      }); 

      this.dataSource.data = this.orgList; 
     // this.dataSource.paginator = this.paginator;  
      //setTimeout(() => this.dataSource.paginator = this.paginator); 
    });
  }

  clickEditMethod(orgId: string) {
    this.router.navigate(["org-data/edit/" , orgId]);
  }
  
  clickDeleteMethod(orgId: string) {
    if(confirm("Are you sure to delete organization id="+orgId)) {
      console.log("Delete orgId=" + orgId);
      // this.userProfileService.deleteUserProfile(orgId).subscribe((data: []) => {
      //   console.log('Delete result:' + JSON.stringify(data));
      //   //location.reload();
      //   this.ngOnInit();
      // });
      
    }
  }

}

// ===================
export class PeriodicElement {
  org_id: string;
  org_name: string;
  org_desc: string;
  short_name: string;
  org_parent_id: string;
  root_org_id: string;
  org_type: string;
  address : string;
  contact_point : string;
  telephone: string;
  email: string;
  pos_idx: string;
  created_on: string;
}

