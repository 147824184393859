import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { UserProfileService } from "../user-profile.service";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../shared/confirmation-dialog/confirmation-dialog.component';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Router } from "@angular/router";

@Component({
  selector: "app-after-process-man",
  templateUrl: "./after-process-man.component.html",
  styleUrls: ["./after-process-man.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class AfterProcessManComponent implements OnInit {
  displayedColumns: string[] = [
    "idx",
    "name",
    "tag",
    "re_examination",
    "biopsy",
    "action",    
  ];

  procedureList: any;
  dataSource = new MatTableDataSource<ProcedureElement>(this.procedureList);

  // defaultImage = '/assets/avatar.png';
  // imageToShowOnError  = '/assets/avatar.png';
  expandedElement: ProcedureElement | null;

  mySubscription: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private userProfileService: UserProfileService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.getProcedureListList();
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  mouseEnter(data: string) {
    console.log("mouse enter : " + data);
  }

  chkChangeFilterType() {
    this.getProcedureListList();
  }

  getProcedureListList() {
    this.procedureList = [];
    var filterType = "0";
    this.userProfileService
      .getEndoscopicProcedureList(filterType)
      .subscribe({next:(data: []) => {
        console.log("Data ", JSON.stringify(data));
        var idx = 0;

        data.sort((a,b) => (a['index'] > b['index']) ? 1 : ((b['index'] > a['index']) ? -1 : 0));

        var needReorder = false;

        data.forEach((e) => {
          idx++;
          var obj = new ProcedureElement();
          obj = e;
          obj["idx"] = idx;

          obj['pos'] = 0; // Xac dinh vi tri de an nut up/down

          if (idx!= obj.index) {
            needReorder = true;
          }
          this.procedureList.push(obj);
        });

        if (this.procedureList && this.procedureList.length>1)  {
          this.procedureList[0]['pos'] = 1;
          this.procedureList[this.procedureList.length-1]['pos'] = 2;
        }

        if (needReorder) {
          this.reOrderProcedure(this.procedureList);
        }

        this.dataSource.data = this.procedureList;
        this.dataSource.sort = this.sort;
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }
      });
  }

  hasUp(item) {
    return item.index > 1;
  }
  hasDown(item) {
    return item.index < this.procedureList.length;
  }
  

  clickAddProduce(item) {
    this.router.navigateByUrl('/afterprocess/addNew/'+ item['index']);
  };

  clickMoveUpProduce(item) {
    var idx =  this.procedureList.indexOf(item);
    if ( idx ==0 ) return;
    var tmp = this.procedureList[idx - 1];
    var  pos = tmp['pos'];
    this.procedureList[idx - 1] = item;
    this.procedureList[idx] = tmp;

    tmp['pos'] = item['pos'];
    item['pos'] = pos;

    this.reOrderProcedure(this.procedureList);
    //alert("onMoveUpSession " +  idx);
    this.ngOnInit();
  }
  
  clickMoveDownProduce(item) {
    
      var idx =  this.procedureList.indexOf(item);
      if ( idx >= this.procedureList.length -1 ) return;

      var tmp = this.procedureList[idx + 1];
      var  pos = tmp['pos'];
      this.procedureList[idx + 1] = item;
      this.procedureList[idx] = tmp;

      tmp['pos'] = item['pos'];
      item['pos'] = pos;

      this.reOrderProcedure(this.procedureList);
      this.ngOnInit();
    
  }

  clickDeleteProduce(item) {


    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Có chắc chắn muốn xoa thu thuat này không ?"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        
        this.userProfileService.delEndoscopicProcedureById(
          item['id']
         )
        .subscribe({next:(data) => {
          //this.userName = data.username;
    
            this.userProfileService.sysLog('AfterProcessInfoComponent','Delete',
                item['id']
                 );
    
             alert("Xóa thành công!");
             this.ngOnInit();
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }
        });
      } // if (result)
      
    });

  }    

  reOrderProcedure(list) {
    let idx = 1;
    list.forEach(oriElement => {
      oriElement.index = idx;
      //clone element
      let element = Object.assign({}, oriElement);
      //remove attribute not needed
      delete element.idx;
      delete element.pos;
      //end
      //console.log("DEBUG: " + JSON.stringify(element));
      this.userProfileService.updateEndoscopicProcedureById(element.id, element)
        .subscribe({next:(data) => {
          //console.log("DEBUG: Update OK " + element.id);
        }, error:err => {
          console.error('Observer got an error: ' + err);
          alert('Lỗi kết nối mạng! ');
        }
        });

      idx = idx + 1;
    });
  }
  
}

export class ProcedureElement {
  id : number;
  idx: number;
  pos:number;
  index: number;
  name: string;
  tag: string;
  gender: string;
  date_of_birth: string;
  remark: string;
  email: string;
  created_date: string;
  updated_date: string;
  status: string;
  re_examination : string;
  biopsy : string;
}
