import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
//import { Observable } from 'rxjs';
//import { of } from 'rxjs/observable/of';
import { map, catchError, tap } from 'rxjs/operators';
import * as myGlobal from './globals';
// import { setTimeout } from 'timers';

//const endpoint = 'http://localhost:3001/';
//const endpoint = 'https://demo.vdsense.com:3001/';

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/json'
//   })
// };

@Injectable({
  providedIn: 'root',
})


export class UserProfileService {
  rootOrganData = [];
  //orgId = 0;

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'auth-key': myGlobal.call_api_key ,
    'org-id' :  'a',
    'token'   : 'a'
  });

  options = { headers: this.headers };



  constructor(private http: HttpClient) {

  }

  getOrgId() {
    let data = JSON.parse(localStorage.getItem('currentUser'));
    return data.org_id;
  }

  getHttpOptions() {
    let data = JSON.parse(localStorage.getItem('currentUser'));
    //console.log('DEBUG:getHttpOptions()->',JSON.stringify(data));
   if (!data.hasOwnProperty('jwt')) {
	data['jwt']='.';
   }
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'auth-key': myGlobal.call_api_key ,
      // 'org-id' :  data.org_id,
       'Authorization'   : 'Bearer ' + data['jwt'],
    });

    // console.log('Debug data:',JSON.stringify(headers));
   // this.orgId = data.org_id;

    return {headers}
    // let options = { headers: headers };

    // return  this.options;
  }

  getHttpAuthorization() {
    let data = JSON.parse(localStorage.getItem('currentUser'));
    return 'Bearer ' + data['jwt'];
  }


  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
       // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
        // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
        // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  //================= CALL TO BACK-END API =============
  getPatientList(filterType): Observable<any> {
   // console.log('Call backend: ' +myGlobal.api_endpoint_2 + 'patients' ) ;
    return this.http.get(myGlobal.api_endpoint_2 + 'patients', this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getPatientList`)),
      map(this.extractData));
  }

  getPatientListMonitor(): Observable<any> {
  //  console.log('Call backend: ' +myGlobal.api_endpoint_2 + 'patients monitor' ) ;
    return this.http.get(myGlobal.api_endpoint_2 + 'patients_require_monitor', this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getPatientListMonitor`)),
      map(this.extractData));
  }

  getPatientById(patientId): Observable<any> {
  //  console.log('Call backend: ' +myGlobal.api_endpoint_2 + 'patient:' + patientId ) ;
    return this.http.get(myGlobal.api_endpoint_2 + 'patient/' + patientId, this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getPatient`)),
      map(this.extractData));
  }

  updatePatientById(patientId,newData): Observable<any> {
  //  console.log('Call updatePatientById: ' +myGlobal.api_endpoint_2 + 'patient:' + patientId ) ;
    return this.http.put(myGlobal.api_endpoint_2 + 'patient/' + patientId, newData, this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call updatePatientById`)),
      map(this.extractData));
  }

  getPatientByTelephone(telephone): Observable<any> {
   // console.log('Call backend: ' +myGlobal.api_endpoint_2 + 'patient_byphone:' + telephone ) ;
    return this.http.get(myGlobal.api_endpoint_2 + 'patient_byphone/' + telephone, this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call patient-byphone`)),
      map(this.extractData));
  }

  deletePatientById(patientId): Observable<any> {
  //  console.log('Call backend: DEL ' +myGlobal.api_endpoint_2 + 'patient:' + patientId ) ;
    return this.http.delete(myGlobal.api_endpoint_2 + 'patient/' + patientId, this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call deletePatientById`)),
      map(this.extractData));
  }

  createPatientRegimenByPatientId(patientId,newData): Observable<any> {
   // console.log('Call backend: ' +myGlobal.api_endpoint_2 + 'regimen_of_patient:' + patientId ) ;
    return this.http.post(myGlobal.api_endpoint_2 + 'regimen_of_patient/' + patientId, newData, this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call createPatientRegimenByPatientId`)),
      map(this.extractData));
  }

  getPatientRegimenByPatientId(patientId): Observable<any> {
   // console.log('Call backend: ' +myGlobal.api_endpoint_2 + 'regimen_of_patient:' + patientId ) ;
    return this.http.get(myGlobal.api_endpoint_2 + 'regimen_of_patient/' + patientId, this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getPatientRegimenByPatientId`)),
      map(this.extractData));
  }

  getPatientLastestRegimenList(filterData): Observable<any> {
  //  console.log('Call getPatientLastestRegimenList: ' +myGlobal.api_endpoint_2 + 'patient-lastest-regimen' ) ;
    return this.http.get(myGlobal.api_endpoint_2 + 'patient-lastest-regimen/' + filterData , this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getPatientLastestRegimenList`)),
      map(this.extractData));
  }

  updateRegimenOfPatientActiveCode(pat_reg_id,active_code): Observable<any> {
   // console.log('Call backend: ' +myGlobal.api_endpoint_2 + 'regimen_of_patient_active_code/' + pat_reg_id) ;
    return this.http.put(myGlobal.api_endpoint_2 + 'regimen_of_patient_active_code/' + pat_reg_id+'/'+active_code, {},
          this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call updateRegimenOfPatientActiveCode`)),
      map(this.extractData));
  }

  updateRegimenOfPatientRegimen(pat_reg_id,regimen_id): Observable<any> {
   // console.log('Call backend: ' +myGlobal.api_endpoint_2 + 'regimen_of_patient_regimen/' + pat_reg_id) ;
    return this.http.put(myGlobal.api_endpoint_2 + 'regimen_of_patient_regimen/' + pat_reg_id+'/'+regimen_id, {},
          this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call updateRegimenOfPatientRegimen`)),
      map(this.extractData));
  }

  updateRegimenOfPatientState(pat_reg_id,state): Observable<any> {
  //  console.log('Call backend: ' +myGlobal.api_endpoint_2 + 'regimen_of_patient_state:' + pat_reg_id + '/' + state) ;
    return this.http.put(myGlobal.api_endpoint_2 + 'regimen_of_patient_state/' + pat_reg_id + '/' + state, {},
          this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call updateRegimenOfPatientState`)),
      map(this.extractData));
  }

  reseteRegimenOfPatient(patient_id,pat_reg_id): Observable<any> {
   // console.log('Call backend: ' +myGlobal.api_endpoint_2 + 'regimen_of_patient_reset:' + patient_id + '/' + pat_reg_id) ;
    return this.http.put(myGlobal.api_endpoint_2 + 'regimen_of_patient_reset/' + patient_id + '/' + pat_reg_id, {},
          this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call reseteRegimenOfPatient`)),
      map(this.extractData));
  }

  updateRegimenOfPatientDoctor(pat_reg_id,doctor): Observable<any> {
  //  console.log('Call backend: ' +myGlobal.api_endpoint_2 + 'regimen_of_patient_doctor:' + pat_reg_id + '/' + doctor) ;
    return this.http.put(myGlobal.api_endpoint_2 + 'regimen_of_patient_doctor/' + pat_reg_id + '/' + doctor, {},
          this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call updateRegimenOfPatientDoctor`)),
      map(this.extractData));
  }

  updateRegimenOfPatientField2(pat_reg_id,body): Observable<any> {
  //  console.log('Call backend: ' +myGlobal.api_endpoint_2 + 'regimen_of_patient/fields_2/' + pat_reg_id ) ;
    return this.http.put(myGlobal.api_endpoint_2 + 'regimen_of_patient/fields_2/' + pat_reg_id , body,
          this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call updateRegimenOfPatientField2`)),
      map(this.extractData));
  }


  getSystemLog(filterType): Observable<any> {
    return this.http.get(myGlobal.api_endpoint_2 + 'systemLog', this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getSystemLog`)),
      map(this.extractData));
  }



  //================== CALL TO STR API =================

  getUserList(): Observable<any> {
  //  console.log('Call getUserList: ' +myGlobal.api_endpoint_1 + 'users' ) ;
    return this.http.get(myGlobal.api_endpoint_1 + 'users?_sort=username', this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getUserList`)),
      map(this.extractData));
  }


  getUserInfo(userId): Observable<any> {
  //  console.log('Call getUserInfo: ' +myGlobal.api_endpoint_1 + 'users' ) ;
    return this.http.get(myGlobal.api_endpoint_1 + 'users/' + userId, this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call updateUserInfo`)),
      map(this.extractData));
  }

  deleteUserById(userId): Observable<any> {
  //  console.log('Call deleteUserById: ' +myGlobal.api_endpoint_1 + 'users' ) ;
    return this.http.delete(myGlobal.api_endpoint_1 + 'users/' + userId, this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call deleteUserById`)),
      map(this.extractData));
  }

  updateUserInfo(userId, data): Observable<any> {
  //  console.log('Call updateUserInfo: ' +myGlobal.api_endpoint_1 + 'users' ) ;
    return this.http.put(myGlobal.api_endpoint_1 + 'users/' + userId, data , this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call updateUserInfo`)),
      map(this.extractData));
  }

  createUser( data): Observable<any> {
  //  console.log('Call createUser: ' +myGlobal.api_endpoint_1 + 'users' ) ;
    return this.http.post(myGlobal.api_endpoint_1 + 'users', data , this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call createUser`)),
      map(this.extractData));
  }

  getFilterQuestionList(filterType): Observable<any> {
  //  console.log('Call getFilterQuestionList: ' +myGlobal.api_endpoint_1 + 'filterforregimen' ) ;
    return this.http.get(myGlobal.api_endpoint_1 + 'filterforregimen' , this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getFilterQuestionList`)),
      map(this.extractData));
  }

  getFilterQuestionById(questId): Observable<any> {
   // console.log('Call getFilterQuestionById: ' +myGlobal.api_endpoint_1 + 'filterforregimen' ) ;
    return this.http.get(myGlobal.api_endpoint_1 + 'filterforregimen/' + questId, this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getFilterQuestionById`)),
      map(this.extractData));
  }

  addFilterQuestion(body): Observable<any> {
  //  console.log('Call addFilterQuestion: ' +myGlobal.api_endpoint_1 + 'filterforregimen' ) ;
    return this.http.post(myGlobal.api_endpoint_1 + 'filterforregimen' , body,this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call addFilterQuestion`)),
      map(this.extractData));
  }

  updateFilterQuestion(questId,body): Observable<any> {
  //  console.log('Call updateFilterQuestion: ' +myGlobal.api_endpoint_1 + 'filterforregimen/' + questId) ;
    return this.http.put(myGlobal.api_endpoint_1 + 'filterforregimen/' + questId , body,this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call updateFilterQuestion`)),
      map(this.extractData));
  }

  deleteFilterQuestion(questId): Observable<any> {
    console.log('Call deleteFilterQuestion: ' +myGlobal.api_endpoint_1 + 'filterforregimen/' + questId) ;
    return this.http.delete(myGlobal.api_endpoint_1 + 'filterforregimen/' + questId ,this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call deleteFilterQuestion`)),
      map(this.extractData));
  }

  getDoctorList(): Observable<any> {
   // console.log('Call getDoctorList: ' +myGlobal.api_endpoint_1 + 'doctors' ) ;
    return this.http.get(myGlobal.api_endpoint_1 + 'doctors' , this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getDoctorList`)),
      map(this.extractData));
  }

  getDoctorById(id): Observable<any> {
   // console.log('Call getDoctorList: ' +myGlobal.api_endpoint_1 + 'doctors' ) ;
    return this.http.get(myGlobal.api_endpoint_1 + 'doctors/' + id , this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getDoctorList`)),
      map(this.extractData));
  }

  getRegimenList(filterType): Observable<any> {
  //  console.log('Call getRegimenList: ' +myGlobal.api_endpoint_1 + 'regimen' ) ;
    return this.http.get(myGlobal.api_endpoint_1 + 'regimen' , this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getRegimenList`)),
      map(this.extractData));
  }



  getRegimenById(regimenId): Observable<any> {
   // console.log('Call getRegimenById: ' +myGlobal.api_endpoint_1 + 'regimen' ) ;
    return this.http.get(myGlobal.api_endpoint_1 + 'regimen/'+regimenId , this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getRegimenById`)),
      map(this.extractData));
  }

  // tiemnk
  updateRegimenById(regimentId,body): Observable<any> {
  //  console.log('Call updateRegimenById: ' +myGlobal.api_endpoint_1 + 'regimen/' + regimentId) ;
    return this.http.put(myGlobal.api_endpoint_1 + 'regimen/' + regimentId , body,this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call updateRegimenById`)),
      map(this.extractData));
  }

  addNewRegimen(body): Observable<any> {
  //  console.log('Call addRegimenBy: ' +myGlobal.api_endpoint_1 + 'regimen' ) ;
    return this.http.post(myGlobal.api_endpoint_1 + 'regimen' , body,this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call addRegimenBy`)),
      map(this.extractData));
  }

  getStepRegimenById(regimenStepId): Observable<any> {
   // console.log('Call getStepRegimenById: ' +myGlobal.api_endpoint_1 + 'regimensteps' ) ;
    return this.http.get(myGlobal.api_endpoint_1 + 'regimensteps/'+regimenStepId , this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getStepRegimenById`)),
      map(this.extractData));
  }

  //duongnv21
  getPostList(): Observable<any> {
   // console.log('Call getPost: ' +myGlobal.api_endpoint_1 + 'posts' ) ;
    return this.http.get(myGlobal.api_endpoint_1 + 'posts' , this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getPostList`)),
      map(this.extractData));
  }
  getPostById(postId): Observable<any> {
  //  console.log('Call getPost: ' +myGlobal.api_endpoint_1 + 'posts' ) ;
    return this.http.get(myGlobal.api_endpoint_1 + 'posts?id='+postId , this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getPostById`)),
      map(this.extractData));
  }

  updatePostById(id, postItem): Observable<any> {
    return this.http.put(myGlobal.api_endpoint_1 + 'posts/' + id, JSON.stringify(postItem), this.getHttpOptions()).pipe(
      tap(_ => console.log(`updatePostById id=${id}`)),
      catchError(this.handleError<any>('updatePostById'))
    );
  }

  createPost(postItem): Observable<any> {
    return this.http.post(myGlobal.api_endpoint_1 + 'posts/', JSON.stringify(postItem), this.getHttpOptions()).pipe(
        tap(_ => console.log(`createPost`)),
        catchError(this.handleError<any>('createPost'))
    );
  }


  getNotifyGlobals(): Observable<any> {
  //  console.log('Call getNotifyGlobals: ' +myGlobal.api_endpoint_1 + 'notifyglobals' ) ;
    return this.http.get(myGlobal.api_endpoint_1 + 'notifyglobals' , this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getNotifyGlobals`)),
      map(this.extractData));
  }

  getNotifyGlobalsById(msgId): Observable<any> {
   // console.log('Call getNotifyGlobalsById: ' +myGlobal.api_endpoint_1 + 'notifyglobals' ) ;
    return this.http.get(myGlobal.api_endpoint_1 + 'notifyglobals/' + msgId, this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getNotifyGlobalsById`)),
      map(this.extractData));
  }

  updateNotifyGlobalsById(msgId, msgData): Observable<any> {
    return this.http.put(myGlobal.api_endpoint_1 + 'notifyglobals/' + msgId, JSON.stringify(msgData), this.getHttpOptions()).pipe(
      tap(_ => console.log(`updateNotifyGlobalsById id=${msgId}`)),
      catchError(this.handleError<any>('updateNotifyGlobalsById'))
    );
  }

  addNotifyGlobals(msgData): Observable<any> {
    return this.http.post(myGlobal.api_endpoint_1 + 'notifyglobals' , JSON.stringify(msgData), this.getHttpOptions()).pipe(
      tap(_ => console.log(`addNotifyGlobals `)),
      catchError(this.handleError<any>('addNotifyGlobals'))
    );
  }

  delNotifyGlobalsById(msgId): Observable<any> {
   // console.log('Call delNotifyGlobalsById: ' +myGlobal.api_endpoint_1 + 'notifyglobals' ) ;
    return this.http.delete(myGlobal.api_endpoint_1 + 'notifyglobals/' + msgId, this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call delNotifyGlobalsById`)),
      map(this.extractData));
  }

  //habm
  getEndoscopicProcedureList(filterType): Observable<any> {
   // console.log("Call getEndoscopicProcedureList: " + myGlobal.api_endpoint_1 + "endoscopicprocedures");
    return this.http
      .get(myGlobal.api_endpoint_1 + "endoscopicprocedures", this.getHttpOptions())
      .pipe(
        tap((_) => console.log(`Call getProduceList`)),
        map(this.extractData)
      );
  }

  // tiemnk
  getEndoscopicProcedureById(apId): Observable<any> {
   // console.log("Call getEndoscopicProcedureById: " + myGlobal.api_endpoint_1 + "endoscopicprocedures");
    return this.http
      .get(myGlobal.api_endpoint_1 + "endoscopicprocedures/" + apId, this.getHttpOptions())
      .pipe(
        tap((_) => console.log(`Call getEndoscopicProcedureById`)),
        map(this.extractData)
      );
  }
  updateEndoscopicProcedureById(apId, newData): Observable<any> {
    //  console.log("Call updateEndoscopicProcedureById: " + myGlobal.api_endpoint_1 + "endoscopicprocedures");
      return this.http
        .put(myGlobal.api_endpoint_1 + "endoscopicprocedures/" + apId,newData, this.getHttpOptions())
        .pipe(
          tap((_) => console.log(`Call updateEndoscopicProcedureById`)),
          map(this.extractData)
        );
    }
  addNewEndoscopicProcedureById(newData): Observable<any> {
   // console.log("Call addNewEndoscopicProcedureById: " + myGlobal.api_endpoint_1 + "endoscopicprocedures");
    return this.http
      .post(myGlobal.api_endpoint_1 + "endoscopicprocedures" , newData, this.getHttpOptions())
      .pipe(
        tap((_) => console.log(`Call addNewEndoscopicProcedureById`)),
        map(this.extractData)
      );
  }
  delEndoscopicProcedureById(apId): Observable<any> {
   // console.log("Call delEndoscopicProcedureById: " + myGlobal.api_endpoint_1 + "endoscopicprocedures/" + apId);
    return this.http
      .delete(myGlobal.api_endpoint_1 + "endoscopicprocedures/" + apId, this.getHttpOptions())
      .pipe(
        tap((_) => console.log(`Call delEndoscopicProcedureById`)),
        map(this.extractData)
      );
  }







  //====================================================

  //-------------------- OLD CODE ----------------------

  getRegisterLog(): Observable<any> {
    return this.http.get(myGlobal.api_endpoint_1 + 'reg_log/' + this.getOrgId(), this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getRegisterLog`)),
      map(this.extractData));
  }

  getOrgList(orgId): Observable<any> {
    return this.http.get(myGlobal.api_endpoint_1 + 'org_data_list/' + orgId, this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getOrgList`)),
      map(this.extractData));
  }

 getCheckinLog(filterType): Observable<any> {
    return this.http.get(myGlobal.api_endpoint_1 + 'checkin-log/' + this.getOrgId() + '/'+filterType, this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getCheckinLog`)),
      map(this.extractData));
  }



  getCheckinLogReview(orgId, filterType): Observable<any> {
    return this.http.get(myGlobal.api_endpoint_1 + 'checkin-log-review/'+orgId + '/' + filterType , this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getCheckinLogReview`)),
      map(this.extractData));
  }

  modifyCheckinLog (chklogId, data): Observable<any> {
    return this.http.put(myGlobal.api_endpoint_1 + 'checkin-log-review/' + chklogId, JSON.stringify(data), this.getHttpOptions()).pipe(
      tap(_ => console.log(`modify checkin log id=${chklogId}`)),
      catchError(this.handleError<any>('modifyCheckinLog'))
    );
  }

  getReportByCycle(cycleId): Observable<any> {
    return this.http.get(myGlobal.api_endpoint_1 + 'report_by_cycle/' + this.getOrgId()+ '/' + cycleId, this.getHttpOptions()).pipe(
      tap(_ => console.log(`Call getReportByCycle`)),
      map(this.extractData));
  }

  updateUserProfile (id, profileData): Observable<any> {
    return this.http.put(myGlobal.api_endpoint_1 + 'update_profile/' + id, JSON.stringify(profileData), this.getHttpOptions()).pipe(
      tap(_ => console.log(`updated user profile id=${id}`)),
      catchError(this.handleError<any>('updateUserProfile'))
    );
  }

  modifyUserProfile (id, profileData): Observable<any> {
    return this.http.put(myGlobal.api_endpoint_1 + 'modify_profile/' + id, JSON.stringify(profileData), this.getHttpOptions()).pipe(
      tap(_ => console.log(`modify user profile id=${id}`)),
      catchError(this.handleError<any>('updateUserProfile'))
    );
  }

  finishRegister(id): Observable<any> {
    return this.http.put(myGlobal.api_endpoint_1 + 'reg_finish/' + id,'{"command":"finish"}', this.getHttpOptions()).pipe(
      tap(_ => console.log(`finishRegister user profile id=${id}`)),
      catchError(this.handleError<any>('finishRegister'))
    );
  }

  // getUserAvatar(id): Observable<any> {
  //   return this.http.get(myGlobal.api_endpoint + 'avatar_get/' + id).pipe(
  //     map(this.extractData));
  // }

  getUserProfile(id): Observable<any> {
    return this.http.get(myGlobal.api_endpoint_1 + 'user_profile/' + id, this.getHttpOptions()).pipe(
      map(this.extractData));
  }

  deleteUserProfile(id): Observable<any> {
    return this.http.delete(myGlobal.api_endpoint_1 + 'user_profile/' + id, this.getHttpOptions()).pipe(
      tap(_ => console.log(`deleteUserProfile user profile id=${id}`)),
      catchError(this.handleError<any>('deleteUserProfile'))
    );
  }

  deleteUserData(id): Observable<any> {
    return this.http.delete(myGlobal.api_endpoint_1 + 'user_data/' + this.getOrgId() + '/' + id, this.getHttpOptions())
    .pipe(
      tap(_ => console.log(`deleteUserData user  id=${id}`)),
      catchError(this.handleError<any>('deleteUserData'))
    );
  }


  getAllDevStatus(): Observable<any> {
    return this.http.get(myGlobal.api_endpoint_1 + 'alldev-status/'  + '10',
      this.getHttpOptions())
      .pipe(
        map(this.extractData));
  }

  getOrgData(orgid , rootOrgId): Observable<any> {
    return this.http.get(myGlobal.api_endpoint_1 + 'org_data/'  + rootOrgId  + '/' + orgid, this.getHttpOptions()).pipe(
      map(this.extractData));
  }

  getOrgTypeList() : Observable<any> {
    return this.http.get(myGlobal.api_endpoint_1 + 'orgtypes' ).pipe(
      map(this.extractData));
  }


  modifyOrgData (orgId, orgData,rootOrgId): Observable<any> {
    return this.http.put(myGlobal.api_endpoint_1 + 'org_data/' + rootOrgId + "/" + orgId,
      JSON.stringify(orgData),  this.getHttpOptions()).pipe(
        tap(_ => console.log(`modify organization data id=${orgId}`)),
        catchError(this.handleError<any>('modifyOrgData'))
      );
  }

  newOrgData (parentOrgId, orgData,rootOrgId): Observable<any> {
    return this.http.post(myGlobal.api_endpoint_1 + 'org_data/' + rootOrgId + "/" + parentOrgId,
      JSON.stringify(orgData),  this.getHttpOptions()).pipe(
        tap(_ => console.log(`new organization parent id=${parentOrgId}`)),
        catchError(this.handleError<any>('newOrgData'))
      );
  }


  getOrgListFromParentOrgId(orgid): Observable<any> {
    return this.http.get(myGlobal.api_endpoint_1 + 'org_list_by_parentid/' + orgid, this.getHttpOptions()).pipe(
      map(this.extractData));
  }

  getUserReportList(orgid , reportingCycle): Observable<any> {
    return this.http.get(myGlobal.api_endpoint_1 + 'usr-report-list/'  + orgid  + '/' + reportingCycle, this.getHttpOptions()).pipe(
      map(this.extractData));
  }

  getUserReportById(orgid , reportingCycle,usrId): Observable<any> {
    return this.http.get(myGlobal.api_endpoint_1 + 'usr-report/'  + orgid  + '/' + reportingCycle + '/' + usrId, this.getHttpOptions()).pipe(
      map(this.extractData));
  }

  getReportingCycle(orgid ): Observable<any> {
    return this.http.get(myGlobal.api_endpoint_1 + 'reporting-cycle/'  + orgid  , this.getHttpOptions()).pipe(
      map(this.extractData));
  }

  getDataFromApi1Url(url): Observable<any> {
    //  console.log('Call getPatientLastestRegimenList: ' +myGlobal.api_endpoint_2 + 'patient-lastest-regimen' ) ;
    // return this.http.get(myGlobal.api_endpoint_2 + 'patient-lastest-regimen/' + filterData , this.getHttpOptions()).pipe(
      return this.http.get(myGlobal.api_endpoint_1 + url , this.getHttpOptions()).pipe(
        tap(_ => console.log(`Call getDataFromApi2Url`)),
        map(this.extractData));
    }
  
  getDataFromApi2Url(url): Observable<any> {
      //  console.log('Call getPatientLastestRegimenList: ' +myGlobal.api_endpoint_2 + 'patient-lastest-regimen' ) ;
      // return this.http.get(myGlobal.api_endpoint_2 + 'patient-lastest-regimen/' + filterData , this.getHttpOptions()).pipe(
        return this.http.get(myGlobal.api_endpoint_2 + url , this.getHttpOptions()).pipe(
          tap(_ => console.log(`Call getDataFromApi2Url`)),
          map(this.extractData));
      }

  //-------------------------------------------------------------

   sysLog(menu,action,content) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    //console.log('Update Log ' + action + '/' + menu);
   // console.log(currentUser);
    console.log({
      usr_id: currentUser.user.id,
      user_name : currentUser.user.username,
      action: action,
      menu: menu
    });
    this.http.post(myGlobal.api_endpoint_2 + 'systemlog',
      {
        usr_id: currentUser.user.id,
        user_name : currentUser.user.username,
        action: action,
        menu: menu,
        content:content,
      }
      ,  this.getHttpOptions()).pipe(
        tap(_ => console.log(`updateSystemLog`)),
        catchError(this.handleError<any>('newOrgData'))
      ).subscribe({next:(data: []) => {
        console.log(JSON.stringify(data));
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});


   }

  updateCache() {
     let currentUser = JSON.parse(localStorage.getItem('currentUser'));
     this.http.post(myGlobal.api_endpoint_2 + 'updateCache',
      {
        usr_id: currentUser.user.id,
        user_name : currentUser.user.username,
      }
      ,  this.getHttpOptions()).pipe(
        tap(_ => console.log(`updateCache`)),
        catchError(this.handleError<any>('newOrgData'))
      ).subscribe({next:(data: []) => {
        console.log(JSON.stringify(data));
      }, error:err => {
        console.error('Observer got an error: ' + err);
        alert('Lỗi kết nối mạng! ');
      }});	
  }

  getCategoryChild(): Observable<any> {
  //  console.log('Call getCategory: ' +myGlobal.api_endpoint_1 + 'categories?parentCategory_gt=0' ) ;
    return this.http.get(myGlobal.api_endpoint_1 + 'categories?parentCategory_gt=0', this.getHttpOptions()).pipe(
        tap(_ => console.log(`Call getCategory`)),
        map(this.extractData));
  }
}

